<template>
  <span v-if="state && session && decision">
    <v-alert
      border="left"
      colored-border
      color="error"
      elevation="2"
      v-if="errorMessages.length > 0"
    >
      <h3 class="ml-5 mb-3 error--text">
        Cette page comporte des données manquantes ou éronées
      </h3>
      <ul class="ml-5">
        <li
          class="py-2 error--text"
          v-for="message in errorMessages"
          v-text="message"
        ></li>
      </ul>
    </v-alert>
    <v-card class="mb-12" outlined>
      <v-expansion-panels v-model="salesTypePanels" multiple>
        <v-expansion-panel
          v-for="sales in salesZones"
          v-if="
            sales.products &&
            sales.services &&
            errorRules.communication[sales.zone.uuid] != undefined &&
            errorRules.quantity[sales.zone.uuid] &&
            errorRules.price[sales.zone.uuid] &&
            errorRules.marketing[sales.zone.uuid]
          "
        >
          <v-expansion-panel-header>
            <h3 class="d-flex justify-start align-center">
              <v-icon class="pr-2">mdi-earth</v-icon>
              <span v-text="sales.zone.name"></span>
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-5 px-4">
            <v-row>
              <v-col
                cols="12"
                v-show="
                  profile.role != 'User' ||
                  session.decisionParameters.budget.communication
                "
              >
                <!-- <v-text-field
                  :disabled="disabled"
                  label="Budget communication"
                  suffix="€"
                  v-model="sales.communication"
                  :error="errorRules.communication[sales.zone.uuid]"
                ></v-text-field> -->
                <v-text-field
                  :disabled="disabled"
                  label="Budget communication"
                  suffix="€"
                  :value="formatNumber(sales.communication)"
                  :error="errorRules.communication[sales.zone.uuid]"
                  @input="
                    (val) => {
                      sales.communication = parseNumber(val);
                      onInputChange();
                    }
                  "
                  @keypress="onlyWholeNumber"
                  @paste="onPasteWholeNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="buildTransportList(sales.zone)"
                  return-object
                  v-model="sales.transport"
                  :error="errorRules.transport[sales.zone.uuid]"
                  :disabled="disabled"
                >
                  <template v-slot:selection="{ item }">
                    <div class="d-flex flex-row align-center justify-start">
                      <div>
                        <v-img
                          contain
                          :src="
                            require(`@/assets/images/icons/transports/${item.type}.svg`)
                          "
                          height="70"
                        ></v-img>
                      </div>
                      <div>
                        <h3 class="pb-2" v-text="item.name"></h3>
                        <div v-text="'Coût : ' + item.cost + ' %'"></div>
                        <div
                          v-text="
                            'Emission de CO2 : ' +
                            item.carbonEmission +
                            'g/colis'
                          "
                        ></div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <div class="d-flex flex-row align-center justify-start">
                        <div>
                          <v-img
                            contain
                            :src="
                              require(`@/assets/images/icons/transports/${item.type}.svg`)
                            "
                            height="70"
                          ></v-img>
                        </div>
                        <div>
                          <h3 class="pb-2" v-text="item.name"></h3>
                          <div v-text="'Coût : ' + item.cost + ' %'"></div>
                          <div
                            v-text="
                              'Emission de CO2 : ' +
                              item.carbonEmission +
                              'g/colis'
                            "
                          ></div>
                        </div>
                      </div>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="productsOrdered.length > 0">
              <v-col cols="12">
                <h2>Produits :</h2>
              </v-col>
              <v-col cols="12" class="mt-0 pt-0">
                <span class="d-flex justify-start align-start flex-wrap">
                  <v-card
                    v-for="product in productsOrdered"
                    style="min-width: 240px"
                    class="pa-2 ma-2"
                    elevation="1"
                    v-if="
                      product &&
                      errorRules.quantity[sales.zone.uuid][product.uuid] !=
                        undefined &&
                      errorRules.price[sales.zone.uuid][product.uuid] !=
                        undefined &&
                      errorRules.marketing[sales.zone.uuid][product.uuid] !=
                        undefined
                    "
                  >
                    <h3 class="pb-3 d-flex justify-start align-center">
                      <v-avatar
                        size="50"
                        color="white"
                        rounded="0"
                        class="mr-3"
                      >
                        <v-img
                          contain
                          height="50"
                          width="50"
                          :src="
                            require(`@/assets/images/icons/products/Product${product.icon}.svg`)
                          "
                        ></v-img>
                      </v-avatar>
                      <span v-text="product.name"></span>
                    </h3>
                    <!-- <v-text-field
                      :disabled="disabled"
                      label="Quantité proposée à la vente"
                      suffix="Unité(s)"
                      value="50000"
                      v-model="sales.products[product.uuid].amount"
                      :error="
                        errorRules.quantity[sales.zone.uuid][product.uuid]
                      "
                      @keypress="onlyWholeNumber"
                      @paste="onPasteWholeNumber"
                    ></v-text-field> -->
                    <v-text-field
                      :disabled="disabled"
                      label="Quantité proposée à la vente"
                      suffix="Unité(s)"
                      :value="formatNumber(sales.products[product.uuid].amount)"
                      :error="
                        errorRules.quantity[sales.zone.uuid][product.uuid]
                      "
                      @input="
                        (val) => {
                          sales.products[product.uuid].amount =
                            parseNumber(val);
                          onInputChange();
                        }
                      "
                      @keypress="onlyWholeNumber"
                      @paste="onPasteWholeNumber"
                    ></v-text-field>
                    <!-- <v-text-field
                      :disabled="disabled"
                      label="Prix"
                      suffix="€"
                      value="50000"
                      v-model="sales.products[product.uuid].price"
                      :error="errorRules.price[sales.zone.uuid][product.uuid]"
                      v-show="
                        profile.role != 'User' ||
                        session.decisionParameters.production.productPrice
                      "
                    ></v-text-field> -->
                    <v-text-field
                      :disabled="disabled"
                      label="Prix"
                      suffix="€"
                      :value="formatDecimal(sales.products[product.uuid].price)"
                      :error="errorRules.price[sales.zone.uuid][product.uuid]"
                      v-show="
                        profile.role != 'User' ||
                        session.decisionParameters.production.productPrice
                      "
                      @input="
                        (val) => {
                          sales.products[product.uuid].price =
                            parseDecimal(val);
                          onInputChange();
                        }
                      "
                      @blur="
                        onBlurField(
                          $event,
                          sales.products[product.uuid],
                          'price'
                        )
                      "
                      @keypress="onlyNumber"
                      @paste="onPasteNumber"
                    ></v-text-field>
                    <!-- <v-text-field
                      :disabled="disabled"
                      label="Budget marketing"
                      suffix="€"
                      value="50000"
                      v-model="sales.products[product.uuid].marketing"
                      :error="
                        errorRules.marketing[sales.zone.uuid][product.uuid]
                      "
                      @change="onDecisionUpdate"
                      v-show="
                        profile.role != 'User' ||
                        session.decisionParameters.budget.marketing
                      "
                    ></v-text-field> -->
                    <v-text-field
                      :disabled="disabled"
                      label="Budget marketing"
                      suffix="€"
                      :value="
                        formatNumber(sales.products[product.uuid].marketing)
                      "
                      :error="
                        errorRules.marketing[sales.zone.uuid][product.uuid]
                      "
                      v-show="
                        profile.role != 'User' ||
                        session.decisionParameters.budget.marketing
                      "
                      @input="
                        (val) => {
                          sales.products[product.uuid].marketing =
                            parseNumber(val);
                          onInputChange();
                        }
                      "
                      @keypress="onlyWholeNumber"
                      @paste="onPasteWholeNumber"
                    ></v-text-field>
                  </v-card>
                </span>
              </v-col>
            </v-row>
            <v-row v-if="servicesOrdered.length > 0">
              <v-col cols="12">
                <h2>Services :</h2>
              </v-col>
              <v-col cols="12" class="mt-0 pt-0">
                <span class="d-flex justify-start align-start flex-wrap">
                  <v-card
                    v-for="service in servicesOrdered"
                    style="min-width: 240px"
                    class="pa-2 ma-2"
                    elevation="1"
                    v-if="
                      service &&
                      errorRules.quantity[sales.zone.uuid][service.uuid] !=
                        undefined &&
                      errorRules.price[sales.zone.uuid][service.uuid] !=
                        undefined &&
                      errorRules.marketing[sales.zone.uuid][service.uuid] !=
                        undefined
                    "
                  >
                    <h3 class="pb-3 d-flex justify-start align-center">
                      <v-avatar
                        size="50"
                        color="white"
                        rounded="0"
                        class="mr-3"
                      >
                        <v-img
                          contain
                          height="50"
                          width="50"
                          :src="
                            require(`@/assets/images/icons/services/Service${service.icon}.svg`)
                          "
                        ></v-img>
                      </v-avatar>
                      <span v-text="service.name"></span>
                    </h3>
                    <!-- <v-text-field
                      :disabled="disabled"
                      label="Quantité proposée à la vente"
                      suffix="Unité(s)"
                      value="50000"
                      v-model="sales.services[service.uuid].amount"
                      :error="
                        errorRules.quantity[sales.zone.uuid][service.uuid]
                      "
                      @keypress="onlyWholeNumber"
                      @paste="onPasteWholeNumber"
                    ></v-text-field> -->
                    <v-text-field
                      :disabled="disabled"
                      label="Quantité proposée à la vente"
                      suffix="Unité(s)"
                      :value="formatNumber(sales.services[service.uuid].amount)"
                      :error="
                        errorRules.quantity[sales.zone.uuid][service.uuid]
                      "
                      @input="
                        (val) => {
                          sales.services[service.uuid].amount =
                            parseNumber(val);
                          onInputChange();
                        }
                      "
                      @keypress="onlyWholeNumber"
                      @paste="onPasteWholeNumber"
                    ></v-text-field>

                    <!-- <v-text-field
                      :disabled="disabled"
                      label="Prix"
                      suffix="€"
                      value="50000"
                      v-model="sales.services[service.uuid].price"
                      :error="errorRules.price[sales.zone.uuid][service.uuid]"
                      v-show="
                        profile.role != 'User' ||
                        session.decisionParameters.production.servicePrice
                      "
                    ></v-text-field> -->
                    <v-text-field
                      :disabled="disabled"
                      label="Prix"
                      suffix="€"
                      :value="formatDecimal(sales.services[service.uuid].price)"
                      :error="errorRules.price[sales.zone.uuid][service.uuid]"
                      v-show="
                        profile.role != 'User' ||
                        session.decisionParameters.production.servicePrice
                      "
                      @input="
                        (val) => {
                          sales.services[service.uuid].price =
                            parseDecimal(val);
                          onInputChange();
                        }
                      "
                      @blur="
                        onBlurField(
                          $event,
                          sales.services[service.uuid],
                          'price'
                        )
                      "
                      @keypress="onlyNumber"
                      @paste="onPasteNumber"
                    ></v-text-field>
                    <!-- <v-text-field
                      :disabled="disabled"
                      label="Budget marketing"
                      suffix="€"
                      value="50000"
                      v-model="sales.services[service.uuid].marketing"
                      :error="
                        errorRules.marketing[sales.zone.uuid][service.uuid]
                      "
                      @change="onDecisionUpdate"
                      v-show="
                        profile.role != 'User' ||
                        session.decisionParameters.budget.marketing
                      "
                    ></v-text-field> -->
                    <v-text-field
                      :disabled="disabled"
                      label="Budget marketing"
                      suffix="€"
                      :value="
                        formatNumber(sales.services[service.uuid].marketing)
                      "
                      :error="
                        errorRules.marketing[sales.zone.uuid][service.uuid]
                      "
                      @change="onDecisionUpdate"
                      v-show="
                        profile.role != 'User' ||
                        session.decisionParameters.budget.marketing
                      "
                      @input="
                        (val) => {
                          sales.services[service.uuid].marketing =
                            parseNumber(val);
                          onInputChange();
                        }
                      "
                      @keypress="onlyWholeNumber"
                      @paste="onPasteWholeNumber"
                    ></v-text-field>
                  </v-card>
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </span>
</template>
<script>
export default {
  name: "DecisionSales",
  props: [
    "disabled",
    "profile",
    "user",
    "session",
    "state",
    "decision",
    "periods",
  ],
  data() {
    return {
      salesTypePanels: [0, 1],
      errorMessages: [],
      errorRules: {
        quantity: {},
        price: {},
        marketing: {},
        communication: {},
      },
      productsOrdered: [],
      servicesOrdered: [],
    };
  },
  mounted() {
    this.preLoad();
  },
  computed: {
    salesZones() {
      let salesZones = Object.values(this.decision.sales);
      salesZones.sort((a, b) => a.zone.name.localeCompare(b.zone.name));
      return salesZones;
    },
  },
  methods: {
    preLoad() {
      this.productsOrdered = new Set();
      this.servicesOrdered = new Set();

      let tmp_sales = { ...this.decision.sales };
      for (let zoneId in this.session.zonesConfig) {
        if (tmp_sales[zoneId] == undefined) {
          let tmp_zone = {
            zone: this.session.zonesConfig[zoneId],
            products: {},
            services: {},
            communication: 0,
            transport: {},
          };
          tmp_sales[zoneId] = tmp_zone;
        } else {
          tmp_sales[zoneId].zone = this.session.zonesConfig[zoneId];
        }
        for (let productId in this.session.productsConfig) {
          this.productsOrdered.add(this.session.productsConfig[productId]);
          if (tmp_sales[zoneId].products[productId] == undefined) {
            let tmp_product = {
              product: this.session.productsConfig[productId],
              amount: 0,
              price: this.session.productsConfig[productId].minimalPrice,
              marketing: 0,
            };
            tmp_sales[zoneId].products[productId] = tmp_product;
          } else {
            tmp_sales[zoneId].products[productId].product =
              this.session.productsConfig[productId];
          }
        }
        for (let serviceId in this.session.servicesConfig) {
          this.servicesOrdered.add(this.session.servicesConfig[serviceId]);
          if (tmp_sales[zoneId].services[serviceId] == undefined) {
            let tmp_service = {
              service: this.session.servicesConfig[serviceId],
              amount: 0,
              price: this.session.servicesConfig[serviceId].minimalPrice,
              marketing: 0,
            };
            tmp_sales[zoneId].services[serviceId] = tmp_service;
          } else {
            tmp_sales[zoneId].services[serviceId].service =
              this.session.servicesConfig[serviceId];
          }
        }
      }

      this.productsOrdered = Array.from(this.productsOrdered);
      this.servicesOrdered = Array.from(this.servicesOrdered);

      this.productsOrdered.sort((a, b) => a.name.localeCompare(b.name));
      this.servicesOrdered.sort((a, b) => a.name.localeCompare(b.name));

      this.decision.sales = tmp_sales;
      this.onDecisionUpdate();

      this.checkErrors();
    },
    buildTransportList(zone) {
      let transportList = [];
      let transportsNames = {
        car: "Camion",
        train: "Train",
        boat: "Bateau",
        plane: "Avion",
      };
      for (let transport in zone.transports) {
        if (zone.transports[transport].available) {
          let tmp_transport = JSON.parse(
            JSON.stringify(zone.transports[transport])
          );
          tmp_transport.type = transport;
          tmp_transport.name = transportsNames[transport];
          transportList.push(tmp_transport);
        }
      }
      transportList.sort((a, b) => a.cost - b.cost);
      return transportList;
    },
    onDecisionUpdate() {
      this.$emit("decisionUpdate");
    },
    onInputChange() {
      this.checkErrors();
    },

    formatNumber(value) {
      if (value !== null && value !== undefined && value !== "") {
        let numberValue = parseInt(value, 10);
        if (isNaN(numberValue)) {
          numberValue = 0;
        }
        // Format number to French format
        return numberValue.toLocaleString("fr-FR");
      } else {
        return "";
      }
    },
    parseNumber(val) {
      // Remove spaces and non-digit characters
      let cleanedVal = val.replace(/\s/g, "").replace(/\D/g, "");
      let numberValue = parseInt(cleanedVal, 10);
      if (isNaN(numberValue)) {
        numberValue = 0;
      }
      return numberValue;
    },
    onlyWholeNumber(event) {
      const char = event.key;
      // Allow only whole numbers
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    },
    onPasteWholeNumber(event) {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      let [integerPart] = paste.split(",");
      let cleanedVal = integerPart.replace(/\D/g, "");

      event.preventDefault();

      let input = event.target;

      // Get cursor position
      let start = input.selectionStart;
      let end = input.selectionEnd;

      // Get the current value
      let value = input.value;

      input.value =
        value.substring(0, start) + cleanedVal + value.substring(end);

      let newPos = start + cleanedVal.length;
      input.setSelectionRange(newPos, newPos);

      input.dispatchEvent(new Event("input"));
    },

    onlyNumber(event) {
      const char = event.key;
      const allowedChars = "0123456789,.";
      // Disallow any character not in allowedChars
      if (!allowedChars.includes(char)) {
        event.preventDefault();
        return;
      }
      // Get the current value and cursor position
      let value = event.target.value;
      let cursorPosition = event.target.selectionStart;

      // Replace dot (.) with comma (,)
      if (char === ".") {
        event.preventDefault();
        // Insert the comma at the cursor position
        event.target.value =
          value.substring(0, cursorPosition) +
          "," +
          value.substring(cursorPosition);
        // Set the cursor position after the comma
        event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        return;
      }

      // If comma is already present, prevent additional commas
      if (char === "," && value.includes(",")) {
        event.preventDefault();
        return;
      }
      // If already two digits after comma, prevent more input
      if (value.includes(",")) {
        let commaIndex = value.indexOf(",");
        let decimals = value.substring(commaIndex + 1);
        if (decimals.length >= 2 && cursorPosition > commaIndex) {
          event.preventDefault();
          return;
        }
      }
    },
    onPasteNumber(event) {
      // Get pasted data via clipboard API
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      // Clean the pasted data
      let cleanedVal = paste.replace(/[^\d,\.]/g, ""); // Keep digits, comma, dot
      // Replace dot with comma
      cleanedVal = cleanedVal.replace(".", ",");
      // Ensure only one comma
      let parts = cleanedVal.split(",");
      if (parts.length > 2) {
        cleanedVal = parts[0] + "," + parts.slice(1).join("");
      }
      // Limit decimals to two digits
      if (parts.length === 2) {
        parts[1] = parts[1].substring(0, 2);
        cleanedVal = parts[0] + "," + parts[1];
      }
      // Prevent the default paste behavior
      event.preventDefault();
      // Insert the cleaned value into the input
      let input = event.target;
      // Get cursor position
      let start = input.selectionStart;
      let end = input.selectionEnd;
      // Get the current value
      let value = input.value;
      // Insert the cleaned value at the cursor position
      input.value =
        value.substring(0, start) + cleanedVal + value.substring(end);
      // Move the cursor to the end of the inserted text
      let newPos = start + cleanedVal.length;
      input.setSelectionRange(newPos, newPos);
      // Trigger input event to update the data binding
      input.dispatchEvent(new Event("input"));
    },

    onBlurField(event, obj, prop) {
      let val = event.target.value;
      let parsedValue = this.parseDecimal(val);

      let hasDecimal = val.includes(",") || val.includes(".");

      let formattedValue = this.formatDecimal(parsedValue, hasDecimal);

      this.$set(obj, prop, parsedValue);

      event.target.value = formattedValue;
    },

    formatDecimal(value, forceTwoDecimals = false) {
      if (value !== null && value !== undefined && value !== "") {
        let numberValue = parseFloat(value);
        if (isNaN(numberValue)) {
          numberValue = 0;
        }

        let options = {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        };

        if (forceTwoDecimals) {
          // If the number is not an integer, enforce two decimal places
          if (!Number.isInteger(numberValue)) {
            options.minimumFractionDigits = 2;
          } else {
            // For whole numbers, no decimal places
            options.minimumFractionDigits = 0;
            options.maximumFractionDigits = 0;
          }
        } else {
          let decimalPart = numberValue.toString().split(".")[1];
          if (decimalPart) {
            options.minimumFractionDigits = decimalPart.length;
            options.maximumFractionDigits = decimalPart.length;
          }
        }

        // Format the number using French locale
        return numberValue.toLocaleString("fr-FR", options);
      } else {
        return "";
      }
    },
    parseDecimal(val) {
      // Remove spaces and non-digit characters except comma and dot
      let cleanedVal = val.replace(/\s/g, "").replace(/[^0-9,.-]/g, "");
      // Replace comma with dot for decimal
      cleanedVal = cleanedVal.replace(",", ".");
      let numberValue = parseFloat(cleanedVal);
      if (isNaN(numberValue)) {
        numberValue = 0;
      }
      return numberValue;
    },

    checkErrors() {
      let tmp_errorMessages = [];
      let tmp_rules = {
        quantity: {},
        price: {},
        marketing: {},
        communication: {},
        transport: {},
      };
      let errorStatus = false;

      for (let sales of Object.values(this.decision.sales)) {
        if (isNaN(parseFloat(sales.communication))) {
          tmp_errorMessages.push(
            "La communication de " + sales.zone.name + " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.communication[sales.zone.uuid] = true;
        } else if (parseFloat(sales.communication) < 0) {
          tmp_errorMessages.push(
            "La communication de " +
              sales.zone.name +
              " doit être supérieur ou egal à 0"
          );
          errorStatus = true;
          tmp_rules.communication[sales.zone.uuid] = true;
        } else {
          tmp_rules.communication[sales.zone.uuid] = false;
        }

        if (
          sales.transport == undefined ||
          sales.transport == null ||
          sales.transport.type == undefined ||
          sales.transport.type == null
        ) {
          tmp_errorMessages.push(
            "Le type de transport pour " + sales.zone.name + " n'est pas défini"
          );
          errorStatus = true;
          tmp_rules.transport[sales.zone.uuid] = true;
        } else if (
          sales.transport.cost == undefined ||
          sales.transport.cost == null ||
          sales.transport.carbonEmission == undefined ||
          sales.transport.carbonEmission == null
        ) {
          tmp_errorMessages.push(
            "Le type de transport pour " + sales.zone.name + " est incorrect"
          );
          errorStatus = true;
          tmp_rules.transport[sales.zone.uuid] = true;
        } else {
          tmp_rules.transport[sales.zone.uuid] = false;
        }

        tmp_rules.quantity[sales.zone.uuid] = {};
        tmp_rules.price[sales.zone.uuid] = {};
        tmp_rules.marketing[sales.zone.uuid] = {};

        for (let product of Object.values(sales.products)) {
          if (isNaN(parseFloat(product.amount))) {
            tmp_errorMessages.push(
              "La quantité de " +
                product.product.name +
                " dans la zone " +
                sales.zone.name +
                " doit être un nombre"
            );
            errorStatus = true;
            tmp_rules.quantity[sales.zone.uuid][product.product.uuid] = true;
          } else if (parseFloat(product.amount) < 0) {
            tmp_errorMessages.push(
              "La quantité de " +
                product.product.name +
                " dans la zone " +
                sales.zone.name +
                " doit être supérieur ou egal à 0"
            );
            errorStatus = true;
            tmp_rules.quantity[sales.zone.uuid][product.product.uuid] = true;
          } else {
            tmp_rules.quantity[sales.zone.uuid][product.product.uuid] = false;
          }

          if (isNaN(parseFloat(product.marketing))) {
            tmp_errorMessages.push(
              "Le budget marketing de " +
                product.product.name +
                " dans la zone " +
                sales.zone.name +
                " doit être un nombre"
            );
            errorStatus = true;
            tmp_rules.marketing[sales.zone.uuid][product.product.uuid] = true;
          } else if (parseFloat(product.marketing) < 0) {
            tmp_errorMessages.push(
              "Le budget marketing de " +
                product.product.name +
                " dans la zone " +
                sales.zone.name +
                " doit être supérieur ou egal à 0"
            );
            errorStatus = true;
            tmp_rules.marketing[sales.zone.uuid][product.product.uuid] = true;
          } else {
            tmp_rules.marketing[sales.zone.uuid][product.product.uuid] = false;
          }

          if (isNaN(parseFloat(product.price))) {
            tmp_errorMessages.push(
              "Le prix de " +
                product.product.name +
                " dans la zone " +
                sales.zone.name +
                " doit être un nombre"
            );
            errorStatus = true;
            tmp_rules.price[sales.zone.uuid][product.product.uuid] = true;
          } else if (parseFloat(product.price) < 0) {
            tmp_errorMessages.push(
              "Le prix de " +
                product.product.name +
                " dans la zone " +
                sales.zone.name +
                " doit être supérieur ou egal à 0"
            );
            errorStatus = true;
            tmp_rules.price[sales.zone.uuid][product.product.uuid] = true;
          } else if (parseFloat(product.price) < product.product.minimalPrice) {
            tmp_errorMessages.push(
              "Le prix de " +
                product.product.name +
                " dans la zone " +
                sales.zone.name +
                " doit être supérieur ou egal à " +
                product.product.minimalPrice
            );
            errorStatus = true;
            tmp_rules.price[sales.zone.uuid][product.product.uuid] = true;
          } else if (parseFloat(product.price) > product.product.maximalPrice) {
            tmp_errorMessages.push(
              "Le prix de " +
                product.product.name +
                " dans la zone " +
                sales.zone.name +
                " doit être inférieur ou egal à " +
                product.product.maximalPrice
            );
            errorStatus = true;
            tmp_rules.price[sales.zone.uuid][product.product.uuid] = true;
          } else {
            tmp_rules.price[sales.zone.uuid][product.product.uuid] = false;
          }
        }

        for (let service of Object.values(sales.services)) {
          if (isNaN(parseFloat(service.amount))) {
            tmp_errorMessages.push(
              "La quantité de " +
                service.service.name +
                " dans la zone " +
                sales.zone.name +
                " doit être un nombre"
            );
            errorStatus = true;
            tmp_rules.quantity[sales.zone.uuid][service.service.uuid] = true;
          } else if (parseFloat(service.amount) < 0) {
            tmp_errorMessages.push(
              "La quantité de " +
                service.service.name +
                " dans la zone " +
                sales.zone.name +
                " doit être supérieur ou egal à 0"
            );
            errorStatus = true;
            tmp_rules.quantity[sales.zone.uuid][service.service.uuid] = true;
          } else {
            tmp_rules.quantity[sales.zone.uuid][service.service.uuid] = false;
          }

          if (isNaN(parseFloat(service.marketing))) {
            tmp_errorMessages.push(
              "Le budget marketing de " +
                service.service.name +
                " dans la zone " +
                sales.zone.name +
                " doit être un nombre"
            );
            errorStatus = true;
            tmp_rules.marketing[sales.zone.uuid][service.service.uuid] = true;
          } else if (parseFloat(service.marketing) < 0) {
            tmp_errorMessages.push(
              "Le budget marketing de " +
                service.service.name +
                " dans la zone " +
                sales.zone.name +
                " doit être supérieur ou egal à 0"
            );
            errorStatus = true;
            tmp_rules.marketing[sales.zone.uuid][service.service.uuid] = true;
          } else {
            tmp_rules.marketing[sales.zone.uuid][service.service.uuid] = false;
          }

          if (isNaN(parseFloat(service.price))) {
            tmp_errorMessages.push(
              "Le prix de " +
                service.service.name +
                " dans la zone " +
                sales.zone.name +
                " doit être un nombre"
            );
            errorStatus = true;
            tmp_rules.price[sales.zone.uuid][service.service.uuid] = true;
          } else if (parseFloat(service.price) < 0) {
            tmp_errorMessages.push(
              "Le prix de " +
                service.service.name +
                " dans la zone " +
                sales.zone.name +
                " doit être supérieur ou egal à 0"
            );
            errorStatus = true;
            tmp_rules.price[sales.zone.uuid][service.service.uuid] = true;
          } else if (parseFloat(service.price) < service.service.minimalPrice) {
            tmp_errorMessages.push(
              "Le prix de " +
                service.service.name +
                " dans la zone " +
                sales.zone.name +
                " doit être supérieur ou egal à " +
                service.service.minimalPrice
            );
            errorStatus = true;
            tmp_rules.price[sales.zone.uuid][service.service.uuid] = true;
          } else if (parseFloat(service.price) > service.service.maximalPrice) {
            tmp_errorMessages.push(
              "Le prix de " +
                service.service.name +
                " dans la zone " +
                sales.zone.name +
                " doit être inférieur ou egal à " +
                service.service.maximalPrice
            );
            errorStatus = true;
            tmp_rules.price[sales.zone.uuid][service.service.uuid] = true;
          } else {
            tmp_rules.price[sales.zone.uuid][service.service.uuid] = false;
          }
        }
      }

      this.errorMessages = tmp_errorMessages;
      this.errorRules = tmp_rules;
      this.$forceUpdate();
      return errorStatus;
    },
    getErrorMessages() {
      return this.errorMessages;
    },
  },
};
</script>
