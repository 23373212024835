<template>
  <span v-if="state && session && decision">
    <v-alert
      border="left"
      colored-border
      color="error"
      elevation="2"
      v-if="errorMessages.length > 0"
    >
      <h3 class="ml-5 mb-3 error--text">
        Cette page comporte des données manquantes ou éronées
      </h3>
      <ul class="ml-5">
        <li
          class="py-2 error--text"
          v-for="message in errorMessages"
          v-text="message"
        ></li>
      </ul>
    </v-alert>
    <v-card class="mb-12" outlined>
      <v-card-title primary-title> Récapitulatif : </v-card-title>
      <v-card-text>
        <span class="d-flex flex-row flex-wrap align-center justify-start">
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Frais d'acquisition :</b>
            <span
              v-text="dataFormater.formatMoney(parseFloat(totalBuyingCost))"
            ></span>
          </span>
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Frais de Recherche &amp; Développement :</b>
            <span
              v-text="
                dataFormater.formatMoney(parseFloat(totalResearchingCost))
              "
            ></span>
          </span>
          <!-- <span class="pa-2 d-flex flex-row flex-wrap align-center justify-start">
                        <b class="pr-1">Bénéficies (ventes) :</b>
                        <span>WIP</span>
                    </span> -->
        </span>
        <div
          class="ma-0 pa-2"
          v-show="
            profile.role != 'User' ||
            session.decisionParameters.budget.maintenance
          "
        >
          <!-- <v-text-field
            :disabled="disabled"
            label="Budget maintenance"
            suffix="€"
            v-model="decision.machines.maintenanceBudget"
            :error="errorRules.maintenanceBudget"
          ></v-text-field> -->
          <v-text-field
            :disabled="disabled"
            label="Budget maintenance"
            suffix="€"
            :value="formatNumber(decision.machines.maintenanceBudget)"
            :error="errorRules.maintenanceBudget"
            @input="
              (val) => {
                decision.machines.maintenanceBudget = parseNumber(val);
                onInputChange();
              }
            "
            @keypress="onlyWholeNumber"
            @paste="onPasteWholeNumber"
          ></v-text-field>
        </div>
      </v-card-text>
    </v-card>
    <div class="mb-12 pa-0">
      <v-card-title primary-title>
        <span class="pr-3">Mes machines :</span>
        <v-btn
          :disabled="disabled"
          color="primary"
          outlined
          @click="infraDialog = true"
          v-show="
            profile.role != 'User' ||
            session.decisionParameters.machine.purchase
          "
          >Voir le catalogue</v-btn
        >
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-for="machine in decision.machines.buying"
        >
          <v-card class="pa-3" outlined>
            <v-card-title primary-title class="justify-center">
              <span class="pb-3" v-text="machine.name"></span>
            </v-card-title>
            <v-card-text>
              <v-img
                contain
                height="auto"
                max-height="150px"
                :src="
                  require(`@/assets/images/icons/machines/Machine${machine.icon}.svg`)
                "
              ></v-img>
              <v-row class="py-5">
                <v-col cols="12" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-center"
                  >
                    <b>En cours d'acquisition</b>
                  </span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-center"
                  >
                    <b>Livraison :&nbsp;</b>
                    <span
                      v-if="machine.delivery > 0"
                      v-text="'dans ' + machine.delivery + ' periode(s)'"
                    ></span>
                    <span v-else v-text="'instantanée'"></span>
                  </span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <span class="mt-5 d-flex flex-row align-center justify-center">
                <v-btn
                  :disabled="disabled"
                  color="accent"
                  outlined
                  @click="cancelBuying(machine)"
                  >Annuler</v-btn
                >
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-for="machine in state.machines.currentMachines"
        >
          <v-card class="pa-3" outlined>
            <v-card-title primary-title class="justify-center">
              <span
                class="pb-3"
                v-text="
                  machine.machine.name + ' ( ' + machine.uuid.slice(-8) + ' )'
                "
              ></span>
            </v-card-title>
            <v-card-text>
              <v-img
                contain
                height="auto"
                max-height="150px"
                :src="
                  require(`@/assets/images/icons/machines/Machine${machine.machine.icon}.svg`)
                "
              ></v-img>
              <v-row class="py-5">
                <v-col cols="12" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-center"
                  >
                    <b>Période(s) d'utilisation :&nbsp;</b>
                    <span v-text="machine.age"></span>
                  </span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-center"
                  >
                    <b>Valeur à la revente :&nbsp;</b>
                    <span
                      v-text="
                        dataFormater.formatMoney(
                          machine.value *
                            (1 -
                              parseFloat(
                                session.machinesConfig[machine.machine.uuid]
                                  .saleLoss
                              ) /
                                100)
                        )
                      "
                    ></span>
                  </span>
                </v-col>
              </v-row>
              <v-divider
                v-show="
                  profile.role != 'User' ||
                  session.decisionParameters.machine.sell
                "
              ></v-divider>
              <span class="mt-5 d-flex flex-row align-center justify-center">
                <v-btn
                  :disabled="disabled"
                  color="accent"
                  outlined
                  @click="sellMachine(machine)"
                  v-if="!decision.machines.selling.includes(machine.uuid)"
                  v-show="
                    profile.role != 'User' ||
                    session.decisionParameters.machine.sell
                  "
                  >Vendre</v-btn
                >
                <v-btn
                  :disabled="disabled"
                  color="secondary"
                  outlined
                  @click="cancelSelling(machine)"
                  v-else
                  v-show="
                    profile.role != 'User' ||
                    session.decisionParameters.machine.sell
                  "
                  >Annuler la vente</v-btn
                >
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="infraDialog" persistent max-width="1200px">
        <v-card>
          <v-card-title primary-title class="justify-space-between">
            <span>Acheter des machines</span>
            <v-btn icon @click="infraDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="4"
                v-for="(machine, index) in Object.values(
                  session.machinesConfig
                ).sort((a, b) =>
                  parseFloat(a.grossValue) + parseFloat(a.rndCost) >
                  parseFloat(b.grossValue) + parseFloat(b.rndCost)
                    ? 1
                    : -1
                )"
              >
                <v-card class="pa-3" outlined>
                  <v-card-title
                    primary-title
                    class="flex-column justify-center"
                  >
                    <h4 v-text="machine.name"></h4>
                    <h5 v-if="!machine.available">(En rupture de stock)</h5>
                    <h5
                      v-else-if="
                        state.machines.unlockedMachines.includes(
                          machine.uuid
                        ) || machine.rndCost <= 0
                      "
                    >
                      (Disponible)
                    </h5>
                    <h5
                      v-else-if="
                        decision.machines.researching.includes(machine.uuid)
                      "
                    >
                      (Recherche en cours)
                    </h5>
                    <h5 v-else>(Recherche nécessaire)</h5>
                  </v-card-title>
                  <v-card-text>
                    <v-img
                      contain
                      height="auto"
                      max-height="150px"
                      :src="
                        require(`@/assets/images/icons/machines/Machine${machine.icon}.svg`)
                      "
                    ></v-img>
                    <div class="pb-5">
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Temps de livraison :&nbsp;</b>
                        <span v-text="machine.delivery + ' periode(s)'"></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Nombre de postes de travail :&nbsp;</b>
                        <span v-text="machine.workplaceAmount"></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Modificateur de productivité :&nbsp;</b>
                        <span
                          v-text="
                            (machine.productionBoost >= 0 ? '+' : '') +
                            machine.productionBoost +
                            ' %'
                          "
                        ></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Frais de structures :&nbsp;</b>
                        <span
                          v-text="
                            dataFormater.formatMoney(
                              parseFloat(machine.structuralCost)
                            )
                          "
                        ></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Budget maintenance optimal :&nbsp;</b>
                        <span
                          v-text="
                            dataFormater.formatMoney(
                              parseFloat(machine.optimalMaintenanceBudget)
                            )
                          "
                        ></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Durée d'amortissement :&nbsp;</b>
                        <span
                          v-text="machine.deprecationPeriods + ' periode(s)'"
                        ></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Consomation éléctrique :&nbsp;</b>
                        <span v-text="machine.conso + ' W/Production'"></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Emissions de CO2 :&nbsp;</b>
                        <span
                          v-text="machine.carbonEmission + ' g/production'"
                        ></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Perte à la revente :&nbsp;</b>
                        <span v-text="machine.saleLoss + ' %'"></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Coût R&D :&nbsp;</b>
                        <span
                          v-text="
                            dataFormater.formatMoney(
                              parseFloat(machine.rndCost)
                            )
                          "
                        ></span>
                      </span>
                      <span
                        class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                      >
                        <b>Peut produire :&nbsp;</b>
                        <span
                          class="pr-2"
                          v-for="productId in Object.keys(
                            session.productionParameters[machine.uuid].products
                          )"
                          v-if="
                            session.productionParameters[machine.uuid].products[
                              productId
                            ]
                          "
                          v-text="session.productsConfig[productId].name"
                        ></span>
                        <span
                          class="pr-2"
                          v-for="serviceId in Object.keys(
                            session.productionParameters[machine.uuid].services
                          )"
                          v-if="
                            session.productionParameters[machine.uuid].services[
                              serviceId
                            ]
                          "
                          v-text="session.servicesConfig[serviceId].name"
                        ></span>
                      </span>
                    </div>
                    <v-divider></v-divider>
                    <span
                      class="mt-5 d-flex flex-row align-center justify-center"
                    >
                      <span
                        v-if="
                          (state.machines.unlockedMachines.includes(
                            machine.uuid
                          ) ||
                            machine.rndCost <= 0) &&
                          machine.available
                        "
                      >
                        <v-btn
                          :disabled="disabled"
                          color="primary"
                          outlined
                          v-text="
                            'Acheter (' +
                            dataFormater.formatMoney(
                              parseFloat(machine.grossValue)
                            ) +
                            ')'
                          "
                          @click="buy(machine)"
                        ></v-btn>
                      </span>
                      <span
                        v-else-if="
                          state.machines.unlockedMachines.includes(
                            machine.uuid
                          ) || machine.rndCost <= 0
                        "
                      >
                        <v-btn
                          :disabled="disabled"
                          color="secondary"
                          disabled
                          outlined
                          v-text="'En rupture de stock'"
                        ></v-btn>
                      </span>
                      <span
                        v-else-if="
                          decision.machines.researching.includes(machine.uuid)
                        "
                      >
                        <v-btn
                          :disabled="disabled"
                          color="secondary"
                          outlined
                          v-text="'Annuler la recherche'"
                          @click="cancelResearching(machine)"
                        ></v-btn>
                      </span>
                      <span v-else>
                        <v-btn
                          :disabled="disabled"
                          color="accent"
                          outlined
                          v-text="
                            'Rechercher (' +
                            dataFormater.formatMoney(
                              parseFloat(machine.rndCost)
                            ) +
                            ')'
                          "
                          @click="research(machine)"
                        ></v-btn>
                      </span>
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "Decisionmachines",
  props: [
    "disabled",
    "profile",
    "user",
    "session",
    "state",
    "decision",
    "periods",
  ],
  setup() {
    return {
      dataFormater,
    };
  },
  data() {
    return {
      infraDialog: false,
      errorMessages: [],
      errorRules: {
        maintenanceBudget: false,
      },
    };
  },
  mounted() {
    this.checkErrors();
  },
  computed: {
    totalBuyingCost() {
      let total = 0;
      this.decision.machines.buying.forEach((machine) => {
        total += parseFloat(machine.grossValue);
      });
      return total;
    },
    totalResearchingCost() {
      let total = 0;
      this.decision.machines.researching.forEach((machineId) => {
        total += parseFloat(this.session.machinesConfig[machineId].rndCost);
      });
      return total;
    },
  },
  methods: {
    onDecisionUpdate() {
      this.$forceUpdate();
    },
    onInputChange() {
      this.checkErrors();
    },
    onlyWholeNumber(event) {
      const char = event.key;
      // Allow only whole numbers
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    },
    onPasteWholeNumber(event) {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      let [integerPart] = paste.split(",");
      let cleanedVal = integerPart.replace(/\D/g, "");

      event.preventDefault();

      let input = event.target;

      // Get cursor position
      let start = input.selectionStart;
      let end = input.selectionEnd;

      // Get the current value
      let value = input.value;

      input.value =
        value.substring(0, start) + cleanedVal + value.substring(end);

      let newPos = start + cleanedVal.length;
      input.setSelectionRange(newPos, newPos);

      input.dispatchEvent(new Event("input"));
    },
    // onlyNumber(event) {
    //   const char = event.key;
    //   const allowedChars = "0123456789,.";
    //   // Disallow any character not in allowedChars
    //   if (!allowedChars.includes(char)) {
    //     event.preventDefault();
    //     return;
    //   }
    //   // Get the current value and cursor position
    //   let value = event.target.value;
    //   let cursorPosition = event.target.selectionStart;

    //   // Replace dot (.) with comma (,)
    //   if (char === ".") {
    //     event.preventDefault();
    //     // Insert the comma at the cursor position
    //     event.target.value =
    //       value.substring(0, cursorPosition) +
    //       "," +
    //       value.substring(cursorPosition);
    //     // Set the cursor position after the comma
    //     event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
    //     return;
    //   }

    //   // If comma is already present, prevent additional commas
    //   if (char === "," && value.includes(",")) {
    //     event.preventDefault();
    //     return;
    //   }
    //   // If already two digits after comma, prevent more input
    //   if (value.includes(",")) {
    //     let commaIndex = value.indexOf(",");
    //     let decimals = value.substring(commaIndex + 1);
    //     if (decimals.length >= 2 && cursorPosition > commaIndex) {
    //       event.preventDefault();
    //       return;
    //     }
    //   }
    // },
    // onPasteNumber(event) {
    //   // Get pasted data via clipboard API
    //   let paste = (event.clipboardData || window.clipboardData).getData("text");
    //   // Clean the pasted data
    //   let cleanedVal = paste.replace(/[^\d,\.]/g, ""); // Keep digits, comma, dot
    //   // Replace dot with comma
    //   cleanedVal = cleanedVal.replace(".", ",");
    //   // Ensure only one comma
    //   let parts = cleanedVal.split(",");
    //   if (parts.length > 2) {
    //     cleanedVal = parts[0] + "," + parts.slice(1).join("");
    //   }
    //   // Limit decimals to two digits
    //   if (parts.length === 2) {
    //     parts[1] = parts[1].substring(0, 2);
    //     cleanedVal = parts[0] + "," + parts[1];
    //   }
    //   // Prevent the default paste behavior
    //   event.preventDefault();
    //   // Insert the cleaned value into the input
    //   let input = event.target;
    //   // Get cursor position
    //   let start = input.selectionStart;
    //   let end = input.selectionEnd;
    //   // Get the current value
    //   let value = input.value;
    //   // Insert the cleaned value at the cursor position
    //   input.value =
    //     value.substring(0, start) + cleanedVal + value.substring(end);
    //   // Move the cursor to the end of the inserted text
    //   let newPos = start + cleanedVal.length;
    //   input.setSelectionRange(newPos, newPos);
    //   // Trigger input event to update the data binding
    //   input.dispatchEvent(new Event("input"));
    // },
    formatNumber(value) {
      if (value !== null && value !== undefined && value !== "") {
        let numberValue = parseInt(value, 10);
        if (isNaN(numberValue)) {
          numberValue = 0;
        }
        // Format number to French format
        return numberValue.toLocaleString("fr-FR");
      } else {
        return "";
      }
    },
    parseNumber(val) {
      // Remove spaces and non-digit characters
      let cleanedVal = val.replace(/\s/g, "").replace(/\D/g, "");
      let numberValue = parseInt(cleanedVal, 10);
      if (isNaN(numberValue)) {
        numberValue = 0;
      }
      return numberValue;
    },
    // formatCurrency(value) {
    //   if (value !== null && value !== undefined && value !== "") {
    //     let numberValue = parseFloat(value);
    //     if (isNaN(numberValue)) {
    //       numberValue = 0;
    //     }

    //     // Round the number to two decimal places
    //     numberValue = Math.round(numberValue * 100) / 100;

    //     let decimalPart = numberValue.toString().split(".")[1];

    //     let options = {
    //       minimumFractionDigits: 0,
    //       maximumFractionDigits: 2,
    //     };

    //     if (decimalPart) {
    //       if (decimalPart.length === 1) {
    //         options.minimumFractionDigits = 2;
    //         options.maximumFractionDigits = 2;
    //       } else {
    //         options.minimumFractionDigits = decimalPart.length;
    //         options.maximumFractionDigits = decimalPart.length;
    //       }
    //     }

    //     // Format the number using French locale
    //     return numberValue.toLocaleString("fr-FR", options);
    //   } else {
    //     return "";
    //   }
    // },
    // parseCurrency(val) {
    //   // Remove spaces and non-digit characters except comma and dot
    //   let cleanedVal = val.replace(/\s/g, "").replace(/[^0-9,.-]/g, "");
    //   // Replace comma with dot for decimal
    //   cleanedVal = cleanedVal.replace(",", ".");
    //   let numberValue = parseFloat(cleanedVal);
    //   if (isNaN(numberValue)) {
    //     numberValue = 0;
    //   }
    //   return numberValue;
    // },
    buy(machine) {
      this.decision.machines.buying.push(machine);
      this.infraDialog = false;
      this.onDecisionUpdate();
    },
    cancelBuying(machine) {
      this.decision.machines.buying.splice(
        this.decision.machines.buying.indexOf(machine),
        1
      );
      this.onDecisionUpdate();
    },
    sellMachine(machine) {
      this.decision.machines.selling.push(machine.uuid);
      this.onDecisionUpdate();
    },
    cancelSelling(machine) {
      this.decision.machines.selling.splice(
        this.decision.machines.selling.indexOf(machine.uuid),
        1
      );
      this.onDecisionUpdate();
    },
    research(machine) {
      this.decision.machines.researching.push(machine.uuid);
      this.onDecisionUpdate();
    },
    cancelResearching(machine) {
      this.decision.machines.researching.splice(
        this.decision.machines.researching.indexOf(machine.uuid),
        1
      );
      this.onDecisionUpdate();
    },
    checkErrors() {
      let tmp_errorMessages = [];
      let tmp_rules = {
        maintenanceBudget: false,
      };
      let errorStatus = false;

      if (isNaN(parseFloat(this.decision.machines.maintenanceBudget))) {
        tmp_errorMessages.push("Le budget maintenance doit être un nombre");
        errorStatus = true;
        tmp_rules.maintenanceBudget = true;
      } else if (parseFloat(this.decision.machines.maintenanceBudget) < 0) {
        tmp_errorMessages.push(
          "Le budget maintenance doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.maintenanceBudget = true;
      } else if (
        parseFloat(this.state.machines.currentMachines.length) +
          parseFloat(this.decision.machines.buying.length) -
          parseFloat(this.decision.machines.selling.length) ===
          0 &&
        parseFloat(this.decision.machines.maintenanceBudget) > 0
      ) {
        tmp_errorMessages.push(
          "Aucun budget de maintenance n'est requis s'il n'y a pas de machines"
        );
        errorStatus = true;
        tmp_rules.maintenanceBudget = true;
      } else if (
        parseFloat(this.state.machines.currentMachines.length) +
          parseFloat(this.decision.machines.buying.length) -
          parseFloat(this.decision.machines.selling.length) >
          0 &&
        parseFloat(this.decision.machines.maintenanceBudget) === 0
      ) {
        tmp_errorMessages.push(
          "Un budget de maintenance est requis lorsque des machines sont présentes"
        );
        errorStatus = true;
        tmp_rules.maintenanceBudget = true;
      }

      this.errorMessages = tmp_errorMessages;
      this.errorRules = tmp_rules;
      this.$forceUpdate();
      return errorStatus;
    },
    getErrorMessages() {
      return this.errorMessages;
    },
  },
};
</script>
