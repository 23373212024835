<template>
  <div style="position: relative">
    <slot></slot>
    <div
      v-if="isOverlayVisible"
      class="overlay"
      @click="showEditingDisabledAlert"
    ></div>
  </div>
</template>

<script>
export default {
  name: "StepContent",
  props: {
    isOverlayVisible: {
      type: Boolean,
      default: false,
    },
    showEditingDisabledAlert: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  cursor: not-allowed;
}
</style>
