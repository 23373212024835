<template>
  <span v-if="state && session && decision">
    <v-alert
      border="left"
      colored-border
      color="error"
      elevation="2"
      v-if="errorMessages.length > 0"
    >
      <h3 class="ml-5 mb-3 error--text">
        Cette page comporte des données manquantes ou éronées
      </h3>
      <ul class="ml-5">
        <li
          class="py-2 error--text"
          v-for="message in errorMessages"
          v-text="message"
        ></li>
      </ul>
    </v-alert>
    <v-card class="mb-12" outlined>
      <v-card-title primary-title> Prévisions financières : </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.forecast.result
            "
          >
            <!-- <v-text-field
              :disabled="disabled"
              label="Résultat net"
              suffix="€"
              v-model="decision.forecast.results"
              :error="errorRules.results"
              @change="onDecisionUpdate"
            ></v-text-field> -->
            <v-text-field
              :disabled="disabled"
              label="Résultat net"
              suffix="€"
              :value="formatDecimal(decision.forecast.results)"
              :error="errorRules.results"
              @change="onDecisionUpdate"
              @input="
                (val) => {
                  decision.forecast.results = parseDecimal(val);
                  onInputChange();
                }
              "
              @blur="onBlurField($event, decision.forecast, 'results')"
              @keypress="onlyPositiveAndNegativeNumber"
              @paste="onPastePositiveAndNegativeNumber"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.forecast.treasury
            "
          >
            <!-- <v-text-field
              :disabled="disabled"
              label="Trésorerie"
              suffix="€"
              v-model="decision.forecast.treasury"
              :error="errorRules.treasury"
              @change="onDecisionUpdate"
            ></v-text-field> -->
            <v-text-field
              :disabled="disabled"
              label="Trésorerie"
              suffix="€"
              :value="formatDecimal(decision.forecast.treasury)"
              :error="errorRules.treasury"
              @change="onDecisionUpdate"
              @input="
                (val) => {
                  decision.forecast.treasury = parseDecimal(val);
                  onInputChange();
                }
              "
              @blur="onBlurField($event, decision.forecast, 'treasury')"
              @keypress="onlyPositiveAndNegativeNumber"
              @paste="onPastePositiveAndNegativeNumber"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.forecast.turnover
            "
          >
            <!-- <v-text-field
              :disabled="disabled"
              label="Chiffre d'affaires"
              suffix="€"
              v-model="decision.forecast.turnover"
              :error="errorRules.turnover"
              @change="onDecisionUpdate"
            ></v-text-field> -->
            <v-text-field
              :disabled="disabled"
              label="Chiffre d'affaires"
              suffix="€"
              :value="formatDecimal(decision.forecast.turnover)"
              :error="errorRules.turnover"
              @change="onDecisionUpdate"
              @input="
                (val) => {
                  decision.forecast.turnover = parseDecimal(val);
                  onInputChange();
                }
              "
              @blur="onBlurField($event, decision.forecast, 'turnover')"
              @keypress="onlyPositiveNumber"
              @paste="onPastePositiveNumber"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
export default {
  name: "DecisionForecast",
  props: [
    "disabled",
    "profile",
    "user",
    "session",
    "state",
    "decision",
    "periods",
  ],
  data() {
    return {
      errorMessages: [],
      errorRules: {
        results: false,
        treasury: false,
        turnover: false,
      },
    };
  },
  mounted() {
    this.checkErrors();
  },
  methods: {
    onDecisionUpdate() {
      this.$emit("decisionUpdate");
    },
    onInputChange() {
      this.checkErrors();
    },
    onlyPositiveAndNegativeNumber(event) {
      const char = event.key;
      const allowedChars = "0123456789.,-";
      // Disallow any character not in allowedChars
      if (!allowedChars.includes(char)) {
        event.preventDefault();
        return;
      }
      // Get the current value and cursor position
      let value = event.target.value;
      let cursorPosition = event.target.selectionStart;

      // Prevent multiple minus signs
      if (char === "-" && value.includes("-")) {
        event.preventDefault();
        return;
      }

      // Ensure minus sign is at the start
      if (char === "-" && cursorPosition !== 0) {
        event.preventDefault();
        return;
      }

      // Replace dot (.) with comma (,)
      if (char === ".") {
        event.preventDefault();
        // Insert the comma at the cursor position
        event.target.value =
          value.substring(0, cursorPosition) +
          "," +
          value.substring(cursorPosition);
        // Set the cursor position after the comma
        event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        return;
      }

      // If comma is already present, prevent additional commas
      if (char === "," && value.includes(",")) {
        event.preventDefault();
        return;
      }

      // If already two digits after comma, prevent more input
      if (value.includes(",")) {
        let commaIndex = value.indexOf(",");
        let decimals = value.substring(commaIndex + 1);
        if (decimals.length >= 2 && cursorPosition > commaIndex) {
          event.preventDefault();
          return;
        }
      }
    },
    onPastePositiveAndNegativeNumber(event) {
      let paste = (event.clipboardData || window.clipboardData).getData("text");

      const allowedChars = "0123456789,-";
      let cleanedVal = paste
        .split("")
        .filter((char) => allowedChars.includes(char))
        .join("");

      let minusIndex = cleanedVal.indexOf("-");
      if (minusIndex > 0) {
        cleanedVal = cleanedVal.replace(/-/g, "");
        cleanedVal = "-" + cleanedVal;
      } else if (minusIndex === -1) {
      } else if (minusIndex === 0) {
        let rest = cleanedVal.slice(1).replace(/-/g, "");
        cleanedVal = "-" + rest;
      }

      let parts = cleanedVal.split(",");
      if (parts.length > 2) {
        cleanedVal = parts[0] + "," + parts.slice(1).join("");
      }

      if (parts.length === 2) {
        parts[1] = parts[1].substring(0, 2);
        cleanedVal = parts[0] + "," + parts[1];
      }

      event.preventDefault();

      let input = event.target;

      let start = input.selectionStart;
      let end = input.selectionEnd;

      let value = input.value;

      input.value =
        value.substring(0, start) + cleanedVal + value.substring(end);

      let newPos = start + cleanedVal.length;
      input.setSelectionRange(newPos, newPos);

      input.dispatchEvent(new Event("input"));
    },
    onlyPositiveNumber(event) {
      const char = event.key;
      const allowedChars = "0123456789,.";
      // Disallow any character not in allowedChars
      if (!allowedChars.includes(char)) {
        event.preventDefault();
        return;
      }
      // Get the current value and cursor position
      let value = event.target.value;
      let cursorPosition = event.target.selectionStart;

      // Replace dot (.) with comma (,)
      if (char === ".") {
        event.preventDefault();
        // Insert the comma at the cursor position
        event.target.value =
          value.substring(0, cursorPosition) +
          "," +
          value.substring(cursorPosition);
        // Set the cursor position after the comma
        event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        return;
      }

      // If comma is already present, prevent additional commas
      if (char === "," && value.includes(",")) {
        event.preventDefault();
        return;
      }
      // If already two digits after comma, prevent more input
      // if (value.includes(",")) {
      //   let commaIndex = value.indexOf(",");
      //   let decimals = value.substring(commaIndex + 1);
      //   if (decimals.length >= 2 && cursorPosition > commaIndex) {
      //     event.preventDefault();
      //     return;
      //   }
      // }
    },
    onPastePositiveNumber(event) {
      let paste = (event.clipboardData || window.clipboardData).getData("text");

      const allowedChars = "0123456789,";

      let cleanedVal = paste
        .split("")
        .filter((char) => allowedChars.includes(char))
        .join("");

      let parts = cleanedVal.split(",");
      if (parts.length > 2) {
        cleanedVal = parts[0] + "," + parts.slice(1).join("");
      }

      if (parts.length === 2) {
        parts[1] = parts[1].substring(0, 2);
        cleanedVal = parts[0] + "," + parts[1];
      }

      event.preventDefault();

      let input = event.target;

      let start = input.selectionStart;
      let end = input.selectionEnd;
      let value = input.value;

      input.value =
        value.substring(0, start) + cleanedVal + value.substring(end);

      let newPos = start + cleanedVal.length;
      input.setSelectionRange(newPos, newPos);
      input.dispatchEvent(new Event("input"));
    },

    onBlurField(event, obj, prop) {
      let val = event.target.value;
      let parsedValue = this.parseDecimal(val);

      let hasDecimal = val.includes(",") || val.includes(".");

      let formattedValue = this.formatDecimal(parsedValue, hasDecimal);

      this.$set(obj, prop, parsedValue);

      event.target.value = formattedValue;
    },
    formatDecimal(value, forceTwoDecimals = false) {
      if (value !== null && value !== undefined && value !== "") {
        let numberValue = parseFloat(value);
        if (isNaN(numberValue)) {
          numberValue = 0;
        }

        let options = {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        };

        if (forceTwoDecimals) {
          // If the number is not an integer, enforce two decimal places
          if (!Number.isInteger(numberValue)) {
            options.minimumFractionDigits = 2;
          } else {
            // For whole numbers, no decimal places
            options.minimumFractionDigits = 0;
            options.maximumFractionDigits = 0;
          }
        } else {
          let decimalPart = numberValue.toString().split(".")[1];
          if (decimalPart) {
            options.minimumFractionDigits = decimalPart.length;
            options.maximumFractionDigits = decimalPart.length;
          }
        }

        // Format the number using French locale
        return numberValue.toLocaleString("fr-FR", options);
      } else {
        return "";
      }
    },
    parseDecimal(val) {
      // Remove spaces and non-digit characters except comma and dot
      let cleanedVal = val.replace(/\s/g, "").replace(/[^0-9,.-]/g, "");
      // Replace comma with dot for decimal
      cleanedVal = cleanedVal.replace(",", ".");
      let numberValue = parseFloat(cleanedVal);
      if (isNaN(numberValue)) {
        numberValue = 0;
      }
      return numberValue;
    },
    checkErrors() {
      let tmp_errorMessages = [];
      let tmp_rules = {
        results: false,
        treasury: false,
        turnover: false,
      };
      let errorStatus = false;

      if (isNaN(parseFloat(this.decision.forecast.results))) {
        tmp_errorMessages.push("La prévision du résultat doit être un nombre");
        errorStatus = true;
        tmp_rules.results = true;
      }

      if (isNaN(parseFloat(this.decision.forecast.treasury))) {
        tmp_errorMessages.push(
          "La prévision de la trésorerie doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.treasury = true;
      }

      if (isNaN(parseFloat(this.decision.forecast.turnover))) {
        tmp_errorMessages.push(
          "La prévision du chiffre d'affaires doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.turnover = true;
      }

      this.errorMessages = tmp_errorMessages;
      this.errorRules = tmp_rules;
      this.$forceUpdate();
      return errorStatus;
    },
    getErrorMessages() {
      return this.errorMessages;
    },
  },
};
</script>
