<template>
  <span
    v-if="
      profile.role != 'User' ||
      (userSession &&
        userSession.displayParameters &&
        userSession.displayParameters.decision)
    "
  >
    <v-card>
      <!-- User Update alert -->
      <v-snackbar
        v-if="updateAlertVisible && profile.role == 'User'"
        v-model="updateAlertVisible"
        :timeout="10000"
        color="success"
        top
      >
        <span class="title">
          {{ updateAlertMessage }}
        </span>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-on="attrs"
            @click="updateAlertVisible = false"
          >
            Fermer
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Editing Disable alert -->
      <v-snackbar
        v-if="editingDisabledAlertVisible"
        v-model="editingDisabledAlertVisible"
        :timeout="5000"
        color="error"
        top
      >
        <span class="title">
          {{ editingDisabledAlertMessage }}
        </span>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-on="attrs"
            @click="editingDisabledAlertVisible = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Décisions</h1>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-row align-center justify-center">
          <v-col cols="12" md="4" v-show="profile.role != 'User'">
            <v-select
              :items="sessions"
              item-text="name"
              return-object
              label="Sélectionnez une session"
              v-model="selectedSession"
              @change="OnSessionSelected"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-if="selectedSession != null"
            v-show="profile.role != 'User'"
          >
            <v-select
              :items="teams"
              item-text="name"
              return-object
              label="Sélectionnez une équipe"
              v-model="selectedTeam"
              @change="OnSelection"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="4" v-if="selectedSession != null">
            <v-select
              :items="periods"
              item-text="name"
              return-object
              label="Sélectionnez une période"
              v-model="selectedPeriod"
              @change="OnSelection"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          class="d-flex flex-row align-center justify-center"
          v-if="profile.role != 'User' && profile.role != 'Animator'"
        >
          <v-col
            cols="12"
            v-if="currentDecision != null"
            class="d-flex flex-row align-center justify-center"
          >
            <v-btn
              style="
                padding: 2em 3em;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 2.5px;
                font-weight: 500;
                color: #fff;
                background-color: #f97316;
                border: none;
                border-radius: 45px;
                box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease 0s;
                cursor: pointer;
                outline: none;
                margin: 0 12px;
              "
              @click="resetDecision"
              >Reinitialiser cette decision</v-btn
            >

            <!-- <v-btn
              style="
                padding: 2em 3em;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 2.5px;
                font-weight: 500;
                color: #fff;
                background-color: #ef4444;
                border: none;
                border-radius: 45px;
                box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease 0s;
                cursor: pointer;
                outline: none;
                margin: 0 12px;
              "
              @click="resetAllDecision"
              >Réinitialiser toutes les décisions</v-btn
            > -->

            <v-btn
              style="
                padding: 2em 3em;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 2.5px;
                font-weight: 500;
                color: #fff;
                background-color: #23c483;
                border: none;
                border-radius: 45px;
                box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease 0s;
                cursor: pointer;
                outline: none;
                margin: 0 12px;
              "
              @click="finishAllDecision"
              >Valider toutes les décisions</v-btn
            >
          </v-col>
        </v-row>
        <!-- Editing Disable alert -->
        <!-- <v-alert
          v-if="editingDisabledAlertVisible"
          type="error"
          dismissible
          :icon="false"
          @input="editingDisabledAlertVisible = $event"
        >
          {{ editingDisabledAlertMessage }}
        </v-alert> -->
        <!-- User Update alert -->
        <!-- <v-alert
          v-if="updateAlertVisible && profile.role == 'User'"
          type="success"
          dismissible
          :icon="false"
          @input="updateAlertVisible = $event"
        >
          {{ updateAlertMessage }}
        </v-alert> -->

        <v-stepper
          class="mt-3 pa-3"
          v-model="currentStep"
          vertical
          non-linear
          v-if="currentDecision != null && currentState != null"
          flat
        >
          <span>
            <v-stepper-step
              v-show="displayParams.finances"
              class="pointer"
              @click="moveToStep(1)"
              complete
              :rules="[() => !errors[steps[0]]]"
              :color="
                currentDecision.status.isReady || isColsed
                  ? 'success'
                  : 'primary'
              "
              step="1"
              complete-icon="mdi-cash"
            >
              Gérer mes finances
            </v-stepper-step>
            <v-stepper-content v-show="displayParams.finances" step="1">
              <StepContent
                :isOverlayVisible="
                  profile.role == 'User' &&
                  (currentDecision.status.isReady || isColsed)
                "
                :showEditingDisabledAlert="showEditingDisabledAlert"
              >
                <DecisionFinance
                  ref="DecisionFinance"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :state="currentState"
                  :decision="currentDecision"
                  :periods="periods"
                  @decisionUpdate="saveChanges"
                />
                <v-btn
                  color="primary"
                  @click="tabValiderBtn"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                >
                  Valider
                </v-btn>
              </StepContent>
            </v-stepper-content>

            <v-stepper-step
              v-show="displayParams.studies"
              class="pointer"
              @click="moveToStep(2)"
              complete
              :rules="[() => !errors[steps[1]]]"
              :color="
                currentDecision.status.isReady || isColsed
                  ? 'success'
                  : 'primary'
              "
              step="2"
              complete-icon="mdi-magnify"
            >
              Acheter des études
            </v-stepper-step>
            <v-stepper-content v-show="displayParams.studies" step="2">
              <StepContent
                :isOverlayVisible="
                  profile.role == 'User' &&
                  (currentDecision.status.isReady || isColsed)
                "
                :showEditingDisabledAlert="showEditingDisabledAlert"
              >
                <DecisionStudies
                  ref="DecisionStudies"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :state="currentState"
                  :decision="currentDecision"
                  :periods="periods"
                  @decisionUpdate="saveChanges"
                />
                <v-btn
                  color="primary"
                  @click="tabValiderBtn"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                >
                  Valider
                </v-btn>
              </StepContent>
            </v-stepper-content>

            <v-stepper-step
              v-show="displayParams.machines"
              class="pointer"
              @click="moveToStep(3)"
              complete
              :rules="[() => !errors[steps[2]]]"
              :color="
                currentDecision.status.isReady || isColsed
                  ? 'success'
                  : 'primary'
              "
              step="3"
              complete-icon="mdi-factory"
            >
              Gérer mes machines
            </v-stepper-step>
            <v-stepper-content v-show="displayParams.machines" step="3">
              <StepContent
                :isOverlayVisible="
                  profile.role == 'User' &&
                  (currentDecision.status.isReady || isColsed)
                "
                :showEditingDisabledAlert="showEditingDisabledAlert"
              >
                <DecisionMachines
                  ref="DecisionMachines"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :state="currentState"
                  :decision="currentDecision"
                  :periods="periods"
                  @decisionUpdate="saveChanges"
                />
                <v-btn
                  color="primary"
                  @click="tabValiderBtn"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                >
                  Valider
                </v-btn>
              </StepContent>
            </v-stepper-content>

            <v-stepper-step
              v-show="displayParams.hr"
              class="pointer"
              @click="moveToStep(4)"
              complete
              :rules="[() => !errors[steps[3]]]"
              :color="
                currentDecision.status.isReady || isColsed
                  ? 'success'
                  : 'primary'
              "
              step="4"
              complete-icon="mdi-account"
            >
              Gérer mes ressources humaines
            </v-stepper-step>
            <v-stepper-content v-show="displayParams.hr" step="4">
              <StepContent
                :isOverlayVisible="
                  profile.role == 'User' &&
                  (currentDecision.status.isReady || isColsed)
                "
                :showEditingDisabledAlert="showEditingDisabledAlert"
              >
                <DecisionHR
                  ref="DecisionHR"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :state="currentState"
                  :decision="currentDecision"
                  :periods="periods"
                  @decisionUpdate="saveChanges"
                />
                <v-btn
                  color="primary"
                  @click="tabValiderBtn"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                >
                  Valider
                </v-btn>
              </StepContent>
            </v-stepper-content>

            <v-stepper-step
              v-show="displayParams.production"
              class="pointer"
              @click="moveToStep(5)"
              complete
              :rules="[() => !errors[steps[4]]]"
              :color="
                currentDecision.status.isReady || isColsed
                  ? 'success'
                  : 'primary'
              "
              step="5"
              complete-icon="mdi-archive-outline"
            >
              Gérer ma production
            </v-stepper-step>
            <v-stepper-content v-show="displayParams.production" step="5">
              <StepContent
                :isOverlayVisible="
                  profile.role == 'User' &&
                  (currentDecision.status.isReady || isColsed)
                "
                :showEditingDisabledAlert="showEditingDisabledAlert"
              >
                <DecisionProduction
                  ref="DecisionProduction"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :state="currentState"
                  :decision="currentDecision"
                  :periods="periods"
                  @decisionUpdate="saveChanges"
                />
                <v-btn
                  color="primary"
                  @click="tabValiderBtn"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                >
                  Valider
                </v-btn>
              </StepContent>
            </v-stepper-content>

            <v-stepper-step
              v-show="displayParams.sales"
              class="pointer"
              @click="moveToStep(6)"
              complete
              :rules="[() => !errors[steps[5]]]"
              :color="
                currentDecision.status.isReady || isColsed
                  ? 'success'
                  : 'primary'
              "
              step="6"
              complete-icon="mdi-cart-outline"
            >
              Gérer mes ventes
            </v-stepper-step>
            <v-stepper-content v-show="displayParams.sales" step="6">
              <StepContent
                :isOverlayVisible="
                  profile.role == 'User' &&
                  (currentDecision.status.isReady || isColsed)
                "
                :showEditingDisabledAlert="showEditingDisabledAlert"
              >
                <DecisionSales
                  ref="DecisionSales"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :state="currentState"
                  :decision="currentDecision"
                  :periods="periods"
                  @decisionUpdate="saveChanges"
                />
                <v-btn
                  color="primary"
                  @click="tabValiderBtn"
                  :disabled="
                    profile.role == 'User' &&
                    (currentDecision.status.isReady || isColsed)
                  "
                >
                  Valider
                </v-btn>
              </StepContent>
            </v-stepper-content>

            <v-stepper-step
              v-show="displayParams.forecast"
              class="pointer"
              @click="moveToStep(7)"
              complete
              :rules="[() => !errors[steps[6]]]"
              :color="
                currentDecision.status.isReady || isColsed
                  ? 'success'
                  : 'primary'
              "
              step="7"
              complete-icon="mdi-calculator-variant-outline"
            >
              Prévisions financières
            </v-stepper-step>
            <v-stepper-content v-show="displayParams.forecast" step="7">
              <DecisionForecast
                ref="DecisionForecast"
                :disabled="
                  profile.role == 'User' &&
                  (currentDecision.status.isReady || isColsed)
                "
                :profile="profile"
                :user="user"
                :session="selectedSession"
                :state="currentState"
                :decision="currentDecision"
                :periods="periods"
                @decisionUpdate="saveChanges"
              />
              <v-btn
                color="primary"
                @click="nextStep"
                :disabled="
                  profile.role == 'User' &&
                  (currentDecision.status.isReady || isColsed)
                "
              >
                Valider
              </v-btn>
            </v-stepper-content>

            <v-stepper-step
              v-if="profile.role != 'User'"
              class="pointer"
              @click="moveToStep(8)"
              complete
              :rules="[() => !errors[steps[7]]]"
              :color="
                currentDecision.status.isReady || isColsed
                  ? 'success'
                  : 'primary'
              "
              step="8"
              complete-icon="mdi-account-multiple-plus-outline"
            >
              Gérer les multiplicateurs
            </v-stepper-step>
            <v-stepper-content v-if="profile.role != 'User'" step="8">
              <DecisionCoeffs
                ref="DecisionCoeffs"
                :profile="profile"
                :user="user"
                :session="selectedSession"
                :state="currentState"
                :decision="currentDecision"
                :periods="periods"
                @decisionUpdate="saveChanges"
              />
              <v-btn color="primary" @click="nextStep"> Valider </v-btn>
            </v-stepper-content>

            <v-stepper-step
              class="pointer"
              @click="moveToStep(profile.role != 'User' ? 9 : 8)"
              complete
              :rules="[() => !errors[steps[8]]]"
              :color="
                currentDecision.status.isReady || isColsed
                  ? 'success'
                  : 'primary'
              "
              :step="profile.role != 'User' ? 9 : 8"
              complete-icon="mdi-thumb-up-outline"
            >
              Valider ma décision
            </v-stepper-step>
            <v-stepper-content :step="profile.role != 'User' ? 9 : 8">
              <span v-if="isColsed">
                <v-alert
                  border="left"
                  colored-border
                  color="success"
                  elevation="2"
                >
                  <h3 class="ml-3 mb-3 success--text">
                    Cette décision est désormais close
                  </h3>
                </v-alert>
              </span>
              <span v-else-if="Object.values(errors).includes(true)">
                <v-alert
                  border="left"
                  colored-border
                  color="error"
                  elevation="2"
                >
                  <h3 class="ml-3 mb-3 error--text">
                    Impossible de valider votre décision
                  </h3>
                  <ul class="ml-5 error--text">
                    <li
                      class="pb-5"
                      v-for="page in Object.keys(errorMessages)"
                      v-if="errorMessages[page].length > 0"
                    >
                      <span>
                        <b
                          class="ml-5"
                          v-text="
                            'La page &quot;' +
                            pagesTitle[page] +
                            '&quot; comporte des données manquantes ou éronées :'
                          "
                        ></b>
                        <ul>
                          <li
                            class="py-2 error--text"
                            v-for="message in errorMessages[page]"
                            v-text="message"
                          ></li>
                        </ul>
                      </span>
                    </li>
                  </ul>
                </v-alert>
              </span>
              <!-- <v-btn
                color="success"
                @click="finish"
                v-else-if="!currentDecision.status.isReady"
              >
                Terminer
              </v-btn>
              <v-btn color="error" @click="notFinish" v-else>
                Continuer a modifier
              </v-btn> -->
              <!-- <span class="d-flex align-center justify-start">
                <span class="mr-3" style="font-size: 14px"
                  >Continuer à modifier</span
                >
                <v-switch
                  v-model="isReadyToggle"
                  :disabled="profile.role == 'User' && isColsed"
                  color="success"
                  @change="toggleFinish"
                ></v-switch>
                <span class="ml-3" style="font-size: 14px">Terminer</span>
              </span> -->
              <span class="d-flex align-center justify-start">
                <span class="mr-3" style="font-size: 14px"
                  >Continuer à modifier</span
                >
                <v-switch
                  v-model="isReadyToggle"
                  :disabled="profile.role == 'User' && isColsed"
                  color="success"
                ></v-switch>
                <span class="ml-3" style="font-size: 14px">Terminer</span>
              </span>
              <!-- Add the Valider button -->
              <v-btn
                color="primary"
                @click="toggleFinish"
                :disabled="
                  (profile.role == 'User' && isColsed) ||
                  isReadyToggle === currentDecision.status.isReady
                "
              >
                Valider
              </v-btn>
            </v-stepper-content>
          </span>
        </v-stepper>
        <span v-else-if="selectedPeriod && selectedTeam && selectedSession">
          <div class="text-center my-10">
            <h2 class="display-1">Decision indisponible</h2>
            <h3>
              Cette décision nécessite des données de la période précédente qui
              ne sont pas encore disponibles.
            </h3>
          </div>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import _ from "lodash";

import DecisionFinance from "@/components/decision/DecisionFinance.vue";
import DecisionStudies from "@/components/decision/DecisionStudies.vue";
import DecisionMachines from "@/components/decision/DecisionMachines.vue";
import DecisionHR from "@/components/decision/DecisionHR.vue";
import DecisionProduction from "@/components/decision/DecisionProduction.vue";
import DecisionSales from "@/components/decision/DecisionSales.vue";
import DecisionForecast from "@/components/decision/DecisionForecast.vue";
import DecisionCoeffs from "@/components/decision/DecisionCoeffs.vue";
import StepContent from "@/components/StepContent.vue";

import Session from "@/classes/Session";
import Univers from "@/classes/Univers";
import Period from "@/classes/Period";
import Team from "@/classes/Team";
import InitialState from "@/classes/InitialState";
import Decision from "@/classes/Decision";
import State from "@/classes/State";

import stateManager from "@/assets/functions/engine/stateManager";

export default {
  name: "Decisions",
  props: ["user", "profile", "userSession"],
  components: {
    DecisionFinance,
    DecisionStudies,
    DecisionMachines,
    DecisionHR,
    DecisionProduction,
    DecisionSales,
    DecisionForecast,
    DecisionCoeffs,
    StepContent,
  },
  data() {
    return {
      unsub: [],
      sessions: [],
      teams: [],
      periods: [],

      currentDate: new Date().getTime(),

      selectedSession: null,
      selectedTeam: null,
      selectedPeriod: null,

      currentState: null,
      // allCurrentState: null,
      currentDecision: null,
      allDecisions: [],

      currentDecisionIsLoading: false,

      currentStep: 1,
      steps: [
        "finances",
        "studies",
        "machines",
        "hr",
        "production",
        "sales",
        "forecast",
        "coeffs",
      ],
      errors: {
        finances: false,
        studies: false,
        machines: false,
        hr: false,
        production: false,
        sales: false,
        forecast: false,
        coeffs: false,
      },
      errorMessages: {
        finances: [],
        studies: [],
        machines: [],
        hr: [],
        production: [],
        sales: [],
        forecast: [],
        coeffs: [],
      },
      pagesTitle: {
        finances: "Gérer mes finances",
        studies: "Acheter des études",
        machines: "Gérer mes machines",
        hr: "Gérer mes ressources humaines",
        production: "Gérer ma production",
        sales: "Gérer mes ventes",
        forecast: "Prévisions financières",
        coeffs: "Gérer les multiplicateurs",
      },
      //track previous decision data
      previousDecisionData: null,
      savedDecisionData: null,
      updateAlertVisible: false,
      updateAlertMessage: "",
      isReadyToggle: false,
      editingDisabledAlertVisible: false,
      editingDisabledAlertMessage: "",
    };
  },
  computed: {
    isColsed() {
      let closed = false;
      if (this.selectedPeriod && this.profile.role == "User") {
        closed = this.selectedPeriod.end <= new Date().getTime();
      }
      return closed;
    },
    displayParams() {
      let params = {
        finances: true,
        studies: false,
        machines: true,
        hr: true,
        production: true,
        sales: true,
        forecast: false,
      };

      params.studies =
        this.profile.role != "User" ||
        this.selectedSession.decisionParameters.studies
          .purchaseAttractivityStudy ||
        this.selectedSession.decisionParameters.studies.purchaseCostStudy ||
        this.selectedSession.decisionParameters.studies.purchaseHrStudy ||
        this.selectedSession.decisionParameters.studies.purchaseMarketStudy;

      params.forecast =
        this.profile.role != "User" ||
        this.selectedSession.decisionParameters.forecast.result ||
        this.selectedSession.decisionParameters.forecast.turnover ||
        this.selectedSession.decisionParameters.forecast.treasury;

      this.$forceUpdate();
      return params;
    },
    totalAmount() {
      let workerAmount =
        parseInt(this.currentState.hr.workerAmount) +
        parseInt(this.currentDecision.hr.workers.hiringAmount) -
        parseInt(this.currentDecision.hr.workers.firingAmount);
      let employeeAmount =
        parseInt(this.currentState.hr.employeeAmount) +
        parseInt(this.currentDecision.hr.employees.hiringAmount) -
        parseInt(this.currentDecision.hr.employees.firingAmount);
      let productManagerAmount =
        this.currentDecision.hr.productManagers.config.length +
        this.currentDecision.hr.productManagers.hiring.length -
        this.currentDecision.hr.productManagers.firing.length;
      let serviceManagerAmount =
        this.currentDecision.hr.serviceManagers.config.length +
        this.currentDecision.hr.serviceManagers.hiring.length -
        this.currentDecision.hr.serviceManagers.firing.length;
      let salesmanAmount =
        this.currentDecision.hr.salesmen.config.length +
        this.currentDecision.hr.salesmen.hiring.length -
        this.currentDecision.hr.salesmen.firing.length;

      return (
        workerAmount +
        employeeAmount +
        productManagerAmount +
        serviceManagerAmount +
        salesmanAmount
      );
    },
  },
  async created() {
    setInterval(() => {
      this.currentDate = new Date().getTime();
    }, 5000);

    if (
      this.profile &&
      this.profile.role == "User" &&
      this.profile.team != null
    ) {
      this.selectedTeam = await Team.getById(this.profile.team);
      let currentUnivers = await Univers.getById(this.selectedTeam.univers);
      this.selectedSession = await Session.getById(currentUnivers.session);

      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
        })
      );
    } else {
      this.unsub.push(
        Session.listenOnlySession((sessions) => {
          sessions.sort((a, b) => b.date - a.date);
          this.sessions = sessions;
        })
      );
    }
  },
  methods: {
    async saveChanges() {
      if (this.currentDecision.id) {
        await this.currentDecision.save();
        this.checkAllErrors();
        // Update savedDecisionData after saving
        this.savedDecisionData = JSON.parse(
          JSON.stringify(this.currentDecision)
        );
      }
    },
    checkAllErrors() {
      let tmp_errors = {};
      let tmp_errorMessages = {};

      if (this.$refs.DecisionFinance) {
        tmp_errors.finances = this.$refs.DecisionFinance.checkErrors();
        if (tmp_errors.finances) {
          tmp_errorMessages.finances =
            this.$refs.DecisionFinance.getErrorMessages();
        } else {
          tmp_errorMessages.finances = [];
        }
      } else {
        tmp_errors.finances = false;
        tmp_errorMessages.finances = [];
      }

      if (this.$refs.DecisionStudies) {
        tmp_errors.studies = this.$refs.DecisionStudies.checkErrors();
        if (tmp_errors.studies) {
          tmp_errorMessages.studies =
            this.$refs.DecisionStudies.getErrorMessages();
        } else {
          tmp_errorMessages.studies = [];
        }
      } else {
        tmp_errors.studies = false;
        tmp_errorMessages.studies = [];
      }

      if (this.$refs.DecisionMachines) {
        tmp_errors.machines = this.$refs.DecisionMachines.checkErrors();
        if (tmp_errors.machines) {
          tmp_errorMessages.machines =
            this.$refs.DecisionMachines.getErrorMessages();
        } else {
          tmp_errorMessages.machines = [];
        }
      } else {
        tmp_errors.machines = false;
        tmp_errorMessages.machines = [];
      }

      if (this.$refs.DecisionHR) {
        tmp_errors.hr = this.$refs.DecisionHR.checkErrors();
        if (tmp_errors.hr) {
          tmp_errorMessages.hr = this.$refs.DecisionHR.getErrorMessages();
        } else {
          tmp_errorMessages.hr = [];
        }
      } else {
        tmp_errors.hr = false;
        tmp_errorMessages.hr = [];
      }

      if (this.$refs.DecisionProduction) {
        tmp_errors.production = this.$refs.DecisionProduction.checkErrors();
        if (tmp_errors.production) {
          tmp_errorMessages.production =
            this.$refs.DecisionProduction.getErrorMessages();
        } else {
          tmp_errorMessages.production = [];
        }
      } else {
        tmp_errors.production = false;
        tmp_errorMessages.production = [];
      }

      if (this.$refs.DecisionSales) {
        tmp_errors.sales = this.$refs.DecisionSales.checkErrors();
        if (tmp_errors.sales) {
          tmp_errorMessages.sales = this.$refs.DecisionSales.getErrorMessages();
        } else {
          tmp_errorMessages.sales = [];
        }
      } else {
        tmp_errors.sales = false;
        tmp_errorMessages.sales = [];
      }

      if (this.$refs.DecisionForecast) {
        tmp_errors.forecast = this.$refs.DecisionForecast.checkErrors();
        if (tmp_errors.forecast) {
          tmp_errorMessages.forecast =
            this.$refs.DecisionForecast.getErrorMessages();
        } else {
          tmp_errorMessages.forecast = [];
        }
      } else {
        tmp_errors.forecast = false;
        tmp_errorMessages.forecast = [];
      }

      if (this.$refs.DecisionCoeffs) {
        tmp_errors.coeffs = this.$refs.DecisionCoeffs.checkErrors();
        if (tmp_errors.coeffs) {
          tmp_errorMessages.coeffs =
            this.$refs.DecisionCoeffs.getErrorMessages();
        } else {
          tmp_errorMessages.coeffs = [];
        }
      } else {
        tmp_errors.coeffs = false;
        tmp_errorMessages.coeffs = [];
      }

      this.errors = tmp_errors;
      this.errorMessages = tmp_errorMessages;
      this.$forceUpdate();
    },
    async moveToStep(step) {
      // if(this.isColsed ||(this.currentDecision.status.isReady && this.profile.role == 'User')){
      //     step = 8
      // }
      const currentSection = this.steps[this.currentStep - 1];
      if (
        !_.isEqual(
          this.currentDecision[currentSection],
          this.savedDecisionData[currentSection]
        )
      ) {
        // Unsaved changes detected
        const result = await Swal.fire({
          title: "Attention",
          text: `Vous avez des modifications non enregistrées sur le formulaire "${this.pagesTitle[currentSection]}". Si vous continuez sans valider, vous risquez de perdre vos modifications.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continuer",
          cancelButtonText: "Rester sur le formulaire",
          reverseButtons: true,
        });
        if (!result.isConfirmed) {
          return;
        }
      }
      if (this.profile.role == "User") {
        this.currentDecision.status.pagesFilled[
          this.steps[this.currentStep - 1]
        ] = true;
      }
      this.currentStep = step;
      // await this.saveChanges();
    },
    async nextStep() {
      this.moveToStep(this.currentStep + 1);
    },
    async tabValiderBtn() {
      this.currentDecision.lastUpdatedBy = this.profile.uid;
      await this.saveChanges();
      await this.nextStep();
    },
    async toggleFinish() {
      if (this.isReadyToggle) {
        await this.finish();
      } else {
        await this.notFinish();
      }
    },
    async finish() {
      // if (this.errorMessages.length > 0) {
      //   // Revert the toggle if there are errors
      //   this.isReadyToggle = false;
      //   return;
      // }
      this.checkAllErrors();
      // If the user is a student and there are errors, prevent validation
      if (
        this.profile.role == "User" &&
        Object.values(this.errors).includes(true)
      ) {
        // Show SweetAlert error message
        Swal.fire({
          icon: "error",
          title: "Impossible de valider votre décision",
          html: `
        <div style="text-align: left;">
          <p>Des erreurs sont présentes dans vos décisions. Veuillez les corriger avant de valider.</p>
          ${this.generateErrorListHtml()}
        </div>
      `,
        });
        // Revert the toggle
        this.isReadyToggle = false;
        return;
      }
      if (this.profile.role == "User") {
        for (let page in this.currentDecision.status.pagesFilled) {
          this.currentDecision.status.pagesFilled[page] = true;
        }
      }
      this.currentDecision.status.isReady = true;
      await this.saveChanges();
    },
    generateErrorListHtml() {
      let html = "<ul>";
      for (let page in this.errorMessages) {
        if (this.errorMessages[page].length > 0) {
          html += `<li><strong>${this.pagesTitle[page]}</strong><ul>`;
          for (let message of this.errorMessages[page]) {
            html += `<li>${message}</li>`;
          }
          html += "</ul></li>";
        }
      }
      html += "</ul>";
      return html;
    },
    finishAllDecision() {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez-vous vraiment valider toutes les décisions ?",
        icon: "question",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then(async () => {
        if (this.errorMessages.length > 0) {
          return;
        }

        if (this.selectedPeriod && this.selectedPeriod.id != null) {
          this.allDecisions = await Decision.getByPeriod(
            this.selectedPeriod.id
          );
        }

        if (this.allDecisions.length > 0) {
          for (const decision of this.allDecisions) {
            if (this.profile.role == "User") {
              for (let page in decision.status.pagesFilled) {
                decision.status.pagesFilled[page] = true;
              }
            }
            decision.status.isReady = true;
            await decision.save();
            this.checkAllErrors();
            // console.log("decision", decision);
          }
        }
      });
    },

    async notFinish() {
      this.currentDecision.status.isReady = false;
      await this.saveChanges();
    },
    async OnSessionSelected() {
      let sessionUniverses = await Univers.getBySession(
        this.selectedSession.id
      );
      let tmp_teams = [];
      this.teams = [];

      this.selectedPeriod = null;
      this.selectedTeam = null;

      this.currentState = null;
      this.currentDecision = null;

      this.currentStep = 1;

      sessionUniverses.forEach((univers) => {
        this.unsub.push(
          Team.listenByUnivers(univers.id, (teams) => {
            teams.forEach((team) => {
              tmp_teams.push(team);
            });
            tmp_teams.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.teams = tmp_teams;
          })
        );
      });
      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods;
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
        })
      );
    },
    isFirstPeriod(period) {
      this.periods.sort((a, b) => {
        return a.end - b.end;
      });
      return period.id == this.periods[0].id;
    },
    async OnSelection() {
      if (this.selectedTeam != null && this.selectedPeriod != null) {
        this.currentDecisionIsLoading = true;
        await this.loadDecision();
      }
    },
    async loadState() {
      this.currentState = null;
      if (this.isFirstPeriod(this.selectedPeriod)) {
        let tmp_initialState = await InitialState.getBySession(
          this.selectedSession.id
        );
        if (tmp_initialState.length == 0) {
          let initialState = await stateManager.computeInitialState(
            this.selectedSession.id
          );
          await initialState.save();
          this.currentState = initialState;
        } else {
          this.currentState = tmp_initialState[0];
        }
      } else {
        let periodIndex = this.periods.findIndex(
          (period) => period.id == this.selectedPeriod.id
        );
        let previousPeriod = this.periods[periodIndex - 1];

        let tmp_states = await State.getByPeriodAndTeam(
          previousPeriod.id,
          this.selectedTeam.id
        );

        this.currentState = tmp_states[0];
      }
    },

    async loadDecision() {
      // await this.loadAllStatesByPeriod();
      await this.loadState();
      if (this.currentState != null) {
        this.unsub.push(
          Decision.listenByPeriodAndTeam(
            this.selectedPeriod.id,
            this.selectedTeam.id,
            async (decisions) => {
              if (decisions.length > 0) {
                // this.currentDecision = decisions[0];
                const newDecision = decisions[0];
                // console.log("newDecision", newDecision);

                if (newDecision.lastUpdatedBy !== this.profile.uid) {
                  if (this.previousDecisionData) {
                    this.detectDecisionChanges(
                      this.previousDecisionData,
                      newDecision
                    );
                  }
                }
                this.previousDecisionData = JSON.parse(
                  JSON.stringify(newDecision)
                );
                this.currentDecision = newDecision;
                // console.log("this.currentDecision", this.currentDecision);
                // Synchronize the switch state
                this.isReadyToggle = this.currentDecision.status.isReady;
                // Initialize savedDecisionData
                this.savedDecisionData = JSON.parse(
                  JSON.stringify(this.currentDecision)
                );

                if (decisions.length > 1) {
                  for (let i = 1; i < decisions.length; i++) {
                    await decisions[i].delete();
                  }
                }
              } else {
                let periodIndex = this.periods.findIndex(
                  (period) => period.id == this.selectedPeriod.id
                );

                let tmp_decision = await Decision.initializeOne(
                  this.selectedPeriod.id,
                  this.selectedTeam.id
                );
                for (let loan of this.currentState.finances.contractedLoans) {
                  tmp_decision.finances.refunds[loan.uuid] = {
                    amount: 0,
                  };
                }
                for (let cv of this.currentState.hr.currentCvs) {
                  if (!this.currentState.hr.firedCvs.includes(cv.uuid)) {
                    if (cv.job == "ProductManager") {
                      tmp_decision.hr.productManagers.config.push(cv);
                    } else if (cv.job == "ServiceManager") {
                      tmp_decision.hr.serviceManagers.config.push(cv);
                    } else if (cv.job == "Salesman") {
                      tmp_decision.hr.salesmen.config.push(cv);
                    }
                  }
                }

                if (periodIndex > 0) {
                  let previousPeriod = this.periods[periodIndex - 1];
                  let tmp_previousDecision = await Decision.getByPeriodAndTeam(
                    previousPeriod.id,
                    this.selectedTeam.id
                  );
                  let previousDecision = null;
                  if (tmp_previousDecision.length > 0) {
                    previousDecision = tmp_previousDecision[0];
                    if (tmp_previousDecision.length > 1) {
                      for (let i = 1; i < tmp_previousDecision.length; i++) {
                        await tmp_previousDecision[i].delete();
                      }
                    }
                  }
                  tmp_decision.hr.socialBudget =
                    previousDecision.hr.socialBudget;
                  tmp_decision.machines.maintenanceBudget =
                    previousDecision.machines.maintenanceBudget;

                  tmp_decision.production = previousDecision.production;
                  tmp_decision.production.products.researching = {};
                  tmp_decision.production.services.researching = {};

                  tmp_decision.sales = previousDecision.sales;
                }

                await tmp_decision.save();
                this.currentDecision = tmp_decision;
              }
              await this.checkSessionConfig();
              this.checkAllErrors();
              if (
                this.currentDecisionIsLoading &&
                this.profile.role == "User"
              ) {
                if (this.currentDecision.status.isReady || this.isColsed) {
                  this.currentStep = this.profile.role != "User" ? 8 : 7;
                } else {
                  this.currentStep = 1;
                }
                this.currentDecisionIsLoading = false;
              }
              this.$forceUpdate();

              if (this.$refs.DecisionProduction != undefined) {
                this.$refs.DecisionProduction.preLoad();
              }
              if (this.$refs.DecisionSales != undefined) {
                this.$refs.DecisionSales.preLoad();
              }
            }
          )
        );
      }
    },
    async detectDecisionChanges(oldDecision, newDecision) {
      const changedSections = [];
      // Compare sections of decisions and detect changes
      for (const section of this.steps) {
        if (!_.isEqual(oldDecision[section], newDecision[section])) {
          changedSections.push(section);
        }
      }

      // Get the current section the user is on
      const currentSection = this.steps[this.currentStep - 1];
      if (changedSections.includes(currentSection)) {
        // Display an alert
        this.showUpdateAlert(currentSection);
      }
    },
    showUpdateAlert(section) {
      this.updateAlertMessage = `Le formulaire "${this.pagesTitle[section]}" a été mis à jour par un membre de votre équipe.`;
      this.updateAlertVisible = true;

      // Auto-close the alert after 2 seconds (2000 milliseconds)
      setTimeout(() => {
        this.updateAlertVisible = false;
      }, 7000);
    },

    showEditingDisabledAlert() {
      if (this.isColsed) {
        // If the period is over and the decision is closed
        this.editingDisabledAlertMessage =
          "La décision est clôturée, vous ne pouvez plus la modifier.";
      } else if (this.currentDecision.status.isReady) {
        // If the decision is marked as 'Terminer' but the period is still open
        this.editingDisabledAlertMessage =
          "Pour continuer à modifier, veuillez basculer le bouton 'Terminer' sur 'Continuer à modifier'.";
      } else {
        // Any other cases, if necessary
        this.editingDisabledAlertMessage =
          "Vous ne pouvez pas modifier la décision actuellement.";
      }
      this.editingDisabledAlertVisible = true;

      // Auto-close the alert after 5 seconds (5000 milliseconds)
      setTimeout(() => {
        this.editingDisabledAlertVisible = false;
      }, 5000);
    },
    async checkSessionConfig() {
      if (this.selectedSession && this.currentDecision) {
        let decisionHasChanged = false;

        if (
          this.selectedSession.mainParameters.financial.loanRate !=
          this.currentDecision.finances.newLoan.rate
        ) {
          this.currentDecision.finances.newLoan.rate =
            this.selectedSession.mainParameters.financial.loanRate;
          this.currentDecision.finances.newLoan.adminRate =
            this.selectedSession.mainParameters.financial.loanRate;
          decisionHasChanged = true;
        }

        for (let zoneId in this.currentDecision.sales) {
          if (
            !this.currentDecision.sales[zoneId].transport ||
            !this.currentDecision.sales[zoneId].transport.cost
          ) {
            let transportList = this.buildTransportList(
              this.selectedSession.zonesConfig[zoneId]
            );
            if (transportList.length > 0) {
              this.currentDecision.sales[zoneId].transport = transportList[0];
              decisionHasChanged = true;
            }
          }
        }

        if (this.selectedSession.decisionParameters.budget.social == false) {
          let socialBudget =
            this.totalAmount *
            this.selectedSession.hrParameters.common.minimalSocialBudget;
          if (this.currentDecision.hr.socialBudget != socialBudget) {
            this.currentDecision.hr.socialBudget = socialBudget;
            decisionHasChanged = true;
          }
        }

        if (decisionHasChanged) {
          await this.currentDecision.save();
        }
      }
    },
    buildTransportList(zone) {
      let transportList = [];
      let transportsNames = {
        car: "Camion",
        train: "Train",
        boat: "Bateau",
        plane: "Avion",
      };
      for (let transport in zone.transports) {
        if (zone.transports[transport].available) {
          let tmp_transport = JSON.parse(
            JSON.stringify(zone.transports[transport])
          );
          tmp_transport.type = transport;
          tmp_transport.name = transportsNames[transport];
          transportList.push(tmp_transport);
        }
      }
      transportList.sort((a, b) => a.cost - b.cost);
      return transportList;
    },
    resetDecision() {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez vous vraiment reinitialiser cette decision ?",
        icon: "question",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          await this.currentDecision.delete();

          this.currentDecision = null;
          this.$forceUpdate();
        }
      });
    },
    // resetAllDecision() {
    //   Swal.fire({
    //     title: "Etes vous sûr ?",
    //     text: "Voulez vous vraiment réinitialiser toutes les décisions ?",
    //     icon: "question",
    //     confirmButtonText: "Confirmer",
    //     showCancelButton: true,
    //     cancelButtonText: "Annuler",
    //     reverseButtons: true,
    //   }).then(async (result) => {
    //     if (result.value) {
    //       if (this.allDecisions.length > 0) {
    //         for (const decision of this.allDecisions) {
    //           // console.log("Decision ID:", decision.id);

    //           await decision.delete();
    //         }

    //         this.currentDecision = null;
    //         this.$forceUpdate();
    //       }
    //     }
    //   });
    // },
  },
  // watch: {
  //   "currentDecision.status.isReady"(newVal) {
  //     this.isReadyToggle = newVal;
  //   },
  // },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
