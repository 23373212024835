<template>
  <span v-if="state && session && decision">
    <v-alert
      border="left"
      colored-border
      color="error"
      elevation="2"
      v-if="errorMessages.length > 0"
    >
      <h3 class="ml-5 mb-3 error--text">
        Cette page comporte des données manquantes ou éronées
      </h3>
      <ul class="ml-5">
        <li
          class="py-2 error--text"
          v-for="message in errorMessages"
          v-text="message"
        ></li>
      </ul>
    </v-alert>
    <v-card class="mb-5" outlined>
      <v-card-title primary-title>
        Récapitulatif fin de période précédente:
      </v-card-title>
      <v-card-text>
        <span class="d-flex flex-row flex-wrap align-center justify-start">
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Capital Social :</b>
            <span
              v-text="
                dataFormater.formatMoney(parseFloat(state.finances.capital))
              "
            ></span>
          </span>
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Capitaux propres :</b>
            <span
              v-text="
                dataFormater.formatMoney(
                  parseFloat(state.finances.netSituation)
                )
              "
            ></span>
          </span>
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Trésorerie :</b>
            <span
              v-text="
                dataFormater.formatMoney(parseFloat(state.finances.treasury))
              "
            ></span>
          </span>
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Fonds de Roulement :</b>
            <span
              v-text="
                dataFormater.formatMoney(
                  parseFloat(state.finances.workingCapital)
                )
              "
            ></span>
          </span>
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Besoin en Fonds de Roulement :</b>
            <span
              v-text="
                dataFormater.formatMoney(
                  parseFloat(state.finances.workingCapitalNeeds)
                )
              "
            ></span>
          </span>
        </span>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col
        cols="12"
        md="6"
        v-show="
          profile.role != 'User' ||
          session.decisionParameters.common.capitalIncreasement
        "
      >
        <v-card class="mb-5" outlined>
          <v-card-title primary-title> Augmentation de capital : </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <!-- <v-text-field
                  :disabled="disabled"
                  label="Montant de l'augmentation"
                  suffix="€"
                  v-model="decision.finances.capitalIncrease"
                  :error="errorRules.capitalIncrease"
                  @change="onDecisionUpdate"
                ></v-text-field> -->
                <v-text-field
                  :disabled="disabled"
                  label="Montant de l'augmentation"
                  suffix="€"
                  :value="formatNumber(decision.finances.capitalIncrease)"
                  :error="errorRules.capitalIncrease"
                  @input="
                    (val) => {
                      decision.finances.capitalIncrease = parseNumber(val);
                    }
                  "
                  @change="onDecisionUpdate"
                  @keypress="onlyWholeNumber"
                  @paste="onPasteWholeNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-show="
          profile.role != 'User' || session.decisionParameters.common.discount
        "
      >
        <v-card class="mb-5" outlined>
          <v-card-title primary-title>
            Escompte :
            <v-tooltip bottom max-width="600" color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" icon small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-help</v-icon>
                </v-btn>
              </template>
              <span
                v-text="
                  'Pourcentage de vos factures en attente à la fin de la période que vous souhaitez confier à votre banque pour qu\'elle vous les paie instantanément, moyennant une commission sur le montant final de ' +
                  session.mainParameters.financial.discountRate +
                  '%.'
                "
              ></span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :disabled="disabled"
                  label="Pourcentage des créances a escompter"
                  suffix="%"
                  :value="formatNumber(decision.finances.discountPercentage)"
                  :error="errorRules.discountPercentage"
                  @input="
                    (val) => {
                      decision.finances.discountPercentage = parseNumber(val);
                    }
                  "
                  @change="onDecisionUpdate"
                  @keypress="onlyWholeNumber"
                  @paste="onPasteWholeNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card
      class="mb-5"
      outlined
      v-show="profile.role != 'User' || session.decisionParameters.common.loan"
    >
      <v-card-title primary-title>
        Contracter un nouvel emprunt :
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <!-- <v-text-field
              :disabled="disabled"
              label="Valeur de l'emprunt"
              suffix="€"
              v-model="decision.finances.newLoan.amount"
              :error="errorRules.loanAmount"
            ></v-text-field> -->
            <!-- <v-text-field
              :disabled="disabled"
              label="Valeur de l'emprunt"
              suffix="€"
              v-model="loanAmountFormatted"
              :error="errorRules.loanAmount"
              @input="onInputChange"
            ></v-text-field> -->
            <v-text-field
              :disabled="disabled"
              label="Valeur de l'emprunt"
              suffix="€"
              :value="formatNumber(decision.finances.newLoan.amount)"
              :error="errorRules.loanAmount"
              @input="
                (val) => {
                  decision.finances.newLoan.amount = parseNumber(val);
                  onInputChange();
                }
              "
              @keypress="onlyWholeNumber"
              @paste="onPasteWholeNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :disabled="disabled"
              label="Durée de l'emprunt"
              suffix="Période(s)"
              :value="formatNumber(decision.finances.newLoan.duration)"
              :error="errorRules.loanDuration"
              @input="
                (val) => {
                  decision.finances.newLoan.duration = parseNumber(val);
                  onInputChange();
                }
              "
              @keypress="onlyWholeNumber"
              @paste="onPasteWholeNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :disabled="disabled || profile.role == 'User'"
              label="Taux"
              suffix="%"
              :value="formatNumber(decision.finances.newLoan.adminRate)"
              :error="errorRules.loanAdminRate"
              @input="
                (val) => {
                  decision.finances.newLoan.adminRate = parseNumber(val);
                }
              "
              @change="onDecisionUpdate"
              @keypress="onlyWholeNumber"
              @paste="onPasteWholeNumber"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="mb-5 pa-0" v-if="state.finances.contractedLoans.length > 0">
      <v-card-title primary-title> Mes emprunts </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="loan in state.finances.contractedLoans"
          :key="loan.uuid"
        >
          <v-card class="pa-3" outlined>
            <v-card-title primary-title class="justify-center">
              <span v-text="'Emprunt : ' + loan.uuid.slice(-8)"></span>
              <!-- <v-progress-linear
                color="accent"
                height="10"
                value="20"
              ></v-progress-linear> -->
            </v-card-title>
            <v-card-title primary-title class="justify-center">
              <span>
                {{ computeNextPayment(loan) == 0 ? "Terminé" : "En cours" }}
              </span>
            </v-card-title>
            <v-card-text>
              <v-row class="py-5">
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Capital emprunté :&nbsp;</b>
                    <span
                      v-text="dataFormater.formatMoney(parseFloat(loan.value))"
                    ></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Période d'emprunt :&nbsp;</b>
                    <span v-text="periodsTable[loan.startPeriod].name"></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Restant dû :&nbsp;</b>
                    <span
                      v-text="dataFormater.formatMoney(computeDueCapital(loan))"
                    ></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Durée :&nbsp;</b>
                    <span v-text="loan.duration + ' période(s)'"></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Taux :&nbsp;</b>
                    <span v-text="loan.loanRate + '%'"></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Prochain versement :&nbsp;</b>
                    <span
                      v-text="
                        dataFormater.formatMoney(computeNextPayment(loan))
                      "
                    ></span>
                  </span>
                </v-col>
              </v-row>
              <v-divider
                v-show="
                  profile.role != 'User' ||
                  session.decisionParameters.common.loan
                "
              ></v-divider>
              <span
                v-if="
                  loan.uuid != undefined &&
                  decision.finances.refunds != undefined &&
                  decision.finances.refunds[loan.uuid] != undefined &&
                  errorRules.loansRefund != undefined
                "
                v-show="
                  profile.role != 'User' ||
                  session.decisionParameters.common.loan
                "
              >
                <h3 class="pt-5">
                  Remboursement anticipé (en plus du prochain versement)
                </h3>
                <!-- <v-text-field
                  :disabled="disabled"
                  color="accent"
                  label="Montant du remboursement"
                  suffix="€"
                  v-model="decision.finances.refunds[loan.uuid].amount"
                  :error="errorRules.loansRefund[loan.uuid]"
                ></v-text-field> -->
                <v-text-field
                  :disabled="disabled || computeNextPayment(loan) == 0"
                  color="accent"
                  label="Montant du remboursement"
                  suffix="€"
                  :error="errorRules.loansRefund[loan.uuid]"
                  :value="
                    formatNumber(decision.finances.refunds[loan.uuid].amount)
                  "
                  @input="
                    (val) => {
                      decision.finances.refunds[loan.uuid].amount =
                        parseNumber(val);
                      onInputChange();
                    }
                  "
                  @keypress="onlyWholeNumber"
                  @paste="onPasteWholeNumber"
                ></v-text-field>

                <span
                  class="py-2 d-flex flex-row flex-wrap align-center justify-start"
                >
                  <b
                    v-html="
                      'Pénalité (' +
                      parseFloat(
                        session.mainParameters.financial.loanPenalityRate
                      ) +
                      '%) : &nbsp'
                    "
                  ></b>
                  <span
                    v-text="
                      dataFormater.formatMoney(
                        computeNextPenality(
                          decision.finances.refunds[loan.uuid].amount
                        )
                      )
                    "
                  ></span>
                </span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "DecisionFinance",
  props: [
    "disabled",
    "profile",
    "user",
    "session",
    "state",
    "decision",
    "periods",
  ],
  setup() {
    return {
      dataFormater,
      errorMessages: [],
      errorRules: {
        capitalIncrease: false,
        discountPercentage: false,
        loanAmount: false,
        loanDuration: false,
        loanAdminRate: false,
      },
    };
  },
  mounted() {
    this.checkErrors();
  },
  computed: {
    periodsTable() {
      let tmp_periodsTable = {};
      for (let period of this.periods) {
        tmp_periodsTable[period.id] = period;
      }
      return tmp_periodsTable;
    },
    // allLoansCompleted() {
    //   return this.state.finances.contractedLoans.every(
    //     (loan) => this.computeNextPayment(loan) == 0
    //   );
    // },
    // loanAmountFormatted: {
    //   get() {
    //     const value = this.decision.finances.newLoan.amount;
    //     if (value !== null && value !== undefined && value !== "") {
    //       let numberValue = parseFloat(value);
    //       if (isNaN(numberValue)) {
    //         numberValue = 0;
    //       }
    //       // Format number to French currency format without currency symbol
    //       return numberValue.toLocaleString("fr-FR");
    //     } else {
    //       return "";
    //     }
    //   },
    //   set(val) {
    //     // Remove spaces and non-digit characters except comma and dot
    //     let cleanedVal = val.replace(/\s/g, "").replace(/[^0-9,.-]/g, "");
    //     // Replace comma with dot for decimal
    //     cleanedVal = cleanedVal.replace(",", ".");
    //     let numberValue = parseFloat(cleanedVal);
    //     if (isNaN(numberValue)) {
    //       numberValue = 0;
    //     }
    //     this.decision.finances.newLoan.amount = numberValue;
    //   },
    // },
  },
  methods: {
    onDecisionUpdate() {
      this.$emit("decisionUpdate");
    },
    onInputChange() {
      this.checkErrors();
    },
    onlyWholeNumber(event) {
      const char = event.key;
      // Allow only whole numbers
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    },
    onPasteWholeNumber(event) {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      let [integerPart] = paste.split(",");
      let cleanedVal = integerPart.replace(/\D/g, "");

      event.preventDefault();

      let input = event.target;

      // Get cursor position
      let start = input.selectionStart;
      let end = input.selectionEnd;

      // Get the current value
      let value = input.value;

      input.value =
        value.substring(0, start) + cleanedVal + value.substring(end);

      let newPos = start + cleanedVal.length;
      input.setSelectionRange(newPos, newPos);

      input.dispatchEvent(new Event("input"));
    },
    // onlyNumber(event) {
    //   const char = event.key;
    //   const allowedChars = "0123456789,.";
    //   // Disallow any character not in allowedChars
    //   if (!allowedChars.includes(char)) {
    //     event.preventDefault();
    //     return;
    //   }
    //   // Get the current value and cursor position
    //   let value = event.target.value;
    //   let cursorPosition = event.target.selectionStart;

    //   // Replace dot (.) with comma (,)
    //   if (char === ".") {
    //     event.preventDefault();
    //     // Insert the comma at the cursor position
    //     event.target.value =
    //       value.substring(0, cursorPosition) +
    //       "," +
    //       value.substring(cursorPosition);
    //     // Set the cursor position after the comma
    //     event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
    //     return;
    //   }

    //   // If comma is already present, prevent additional commas
    //   if (char === "," && value.includes(",")) {
    //     event.preventDefault();
    //     return;
    //   }
    //   // If already two digits after comma, prevent more input
    //   if (value.includes(",")) {
    //     let commaIndex = value.indexOf(",");
    //     let decimals = value.substring(commaIndex + 1);
    //     if (decimals.length >= 2 && cursorPosition > commaIndex) {
    //       event.preventDefault();
    //       return;
    //     }
    //   }
    // },
    // onPasteNumber(event) {
    //   // Get pasted data via clipboard API
    //   let paste = (event.clipboardData || window.clipboardData).getData("text");
    //   // Clean the pasted data
    //   let cleanedVal = paste.replace(/[^\d,\.]/g, ""); // Keep digits, comma, dot
    //   // Replace dot with comma
    //   cleanedVal = cleanedVal.replace(".", ",");
    //   // Ensure only one comma
    //   let parts = cleanedVal.split(",");
    //   if (parts.length > 2) {
    //     cleanedVal = parts[0] + "," + parts.slice(1).join("");
    //   }
    //   // Limit decimals to two digits
    //   if (parts.length === 2) {
    //     parts[1] = parts[1].substring(0, 2);
    //     cleanedVal = parts[0] + "," + parts[1];
    //   }
    //   // Prevent the default paste behavior
    //   event.preventDefault();
    //   // Insert the cleaned value into the input
    //   let input = event.target;
    //   // Get cursor position
    //   let start = input.selectionStart;
    //   let end = input.selectionEnd;
    //   // Get the current value
    //   let value = input.value;
    //   // Insert the cleaned value at the cursor position
    //   input.value =
    //     value.substring(0, start) + cleanedVal + value.substring(end);
    //   // Move the cursor to the end of the inserted text
    //   let newPos = start + cleanedVal.length;
    //   input.setSelectionRange(newPos, newPos);
    //   // Trigger input event to update the data binding
    //   input.dispatchEvent(new Event("input"));
    // },
    // formatCurrency(value) {
    //   if (value !== null && value !== undefined && value !== "") {
    //     let numberValue = parseFloat(value);
    //     if (isNaN(numberValue)) {
    //       numberValue = 0;
    //     }
    //     // Format number to French currency format without currency symbol
    //     return numberValue.toLocaleString("fr-FR");
    //   } else {
    //     return "";
    //   }
    // },

    formatNumber(value) {
      if (value !== null && value !== undefined && value !== "") {
        let numberValue = parseInt(value, 10);
        if (isNaN(numberValue)) {
          numberValue = 0;
        }
        // Format number to French format
        return numberValue.toLocaleString("fr-FR");
      } else {
        return "";
      }
    },
    parseNumber(val) {
      // Remove spaces and non-digit characters
      let cleanedVal = val.replace(/\s/g, "").replace(/\D/g, "");
      let numberValue = parseInt(cleanedVal, 10);
      if (isNaN(numberValue)) {
        numberValue = 0;
      }
      return numberValue;
    },

    // formatCurrency(value) {
    //   if (value !== null && value !== undefined && value !== "") {
    //     let numberValue = parseFloat(value);
    //     if (isNaN(numberValue)) {
    //       numberValue = 0;
    //     }

    //     // Round the number to two decimal places
    //     numberValue = Math.round(numberValue * 100) / 100;

    //     let decimalPart = numberValue.toString().split(".")[1];

    //     let options = {
    //       minimumFractionDigits: 0,
    //       maximumFractionDigits: 2,
    //     };

    //     if (decimalPart) {
    //       if (decimalPart.length === 1) {
    //         options.minimumFractionDigits = 2;
    //         options.maximumFractionDigits = 2;
    //       } else {
    //         options.minimumFractionDigits = decimalPart.length;
    //         options.maximumFractionDigits = decimalPart.length;
    //       }
    //     }

    //     // Format the number using French locale
    //     return numberValue.toLocaleString("fr-FR", options);
    //   } else {
    //     return "";
    //   }
    // },
    // parseCurrency(val) {
    //   // Remove spaces and non-digit characters except comma and dot
    //   let cleanedVal = val.replace(/\s/g, "").replace(/[^0-9,.-]/g, "");
    //   // Replace comma with dot for decimal
    //   cleanedVal = cleanedVal.replace(",", ".");
    //   let numberValue = parseFloat(cleanedVal);
    //   if (isNaN(numberValue)) {
    //     numberValue = 0;
    //   }
    //   return numberValue;
    // },
    computeDueCapital(loan) {
      let tmp_dueCapital = loan.value;
      for (let payment of loan.payments) {
        tmp_dueCapital -= payment.capitalPaid;
      }
      for (let refund of loan.refunds) {
        tmp_dueCapital -= refund.capitalPaid;
      }
      return tmp_dueCapital;
    },
    computeNextCapitalPayment(loan) {
      let dueCapital = this.computeDueCapital(loan);
      let remainingPayments = loan.duration - loan.payments.length;
      if (remainingPayments > 0) {
        return dueCapital / remainingPayments;
      } else {
        return 0;
      }
      // return dueCapital / remainingPayments;
    },
    computeNextIntrestPayment(loan) {
      let dueCapital = this.computeDueCapital(loan);
      return dueCapital * (loan.loanRate / 100);
    },
    computeNextPayment(loan) {
      return (
        this.computeNextCapitalPayment(loan) +
        this.computeNextIntrestPayment(loan)
      );
    },
    computeNextPenality(refund) {
      return (
        refund *
        (parseFloat(this.session.mainParameters.financial.loanPenalityRate) /
          100)
      );
    },
    checkErrors() {
      let tmp_errorMessages = [];
      let tmp_rules = {
        capitalIncrease: false,
        discountPercentage: false,
        loanAmount: false,
        loanDuration: false,
        loanAdminRate: false,
        loansRefund: {},
      };
      let errorStatus = false;

      if (isNaN(parseFloat(this.decision.finances.capitalIncrease))) {
        tmp_errorMessages.push("L'augmentation de capital doit être un nombre");
        errorStatus = true;
        tmp_rules.capitalIncrease = true;
      } else if (parseFloat(this.decision.finances.capitalIncrease) < 0) {
        tmp_errorMessages.push(
          "L'augmentation de capital doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.capitalIncrease = true;
      }

      if (isNaN(parseFloat(this.decision.finances.discountPercentage))) {
        tmp_errorMessages.push("Le pourcentage d'escompte doit être un nombre");
        errorStatus = true;
        tmp_rules.discountPercentage = true;
      } else if (parseFloat(this.decision.finances.discountPercentage) < 0) {
        tmp_errorMessages.push(
          "Le pourcentage d'escompte doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.discountPercentage = true;
      } else if (parseFloat(this.decision.finances.discountPercentage) > 100) {
        tmp_errorMessages.push(
          "Le pourcentage d'escompte doit être inférieur ou egal à 100%"
        );
        errorStatus = true;
        tmp_rules.discountPercentage = true;
      } else if (
        parseFloat(this.decision.finances.discountPercentage) >
        parseFloat(this.session.mainParameters.financial.maxDiscount)
      ) {
        tmp_errorMessages.push(
          "Le pourcentage d'escompte doit être inférieur ou egal à " +
            dataFormater.formatPercentage(
              parseFloat(this.session.mainParameters.financial.maxDiscount)
            )
        );
        errorStatus = true;
        tmp_rules.discountPercentage = true;
      }

      if (isNaN(parseFloat(this.decision.finances.newLoan.amount))) {
        tmp_errorMessages.push("Le montant de l'emprunt doit être un nombre");
        errorStatus = true;
        tmp_rules.loanAmount = true;
      } else if (parseFloat(this.decision.finances.newLoan.amount) < 0) {
        tmp_errorMessages.push(
          "Le montant de l'emprunt doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.loanAmount = true;
      }
      // else if (
      //   parseFloat(this.decision.finances.newLoan.amount) >
      //   parseFloat(this.state.finances.netSituation) +
      //     parseFloat(this.decision.finances.capitalIncrease)
      // ) {
      //   tmp_errorMessages.push(
      //     "Le montant de l'emprunt doit être inférieur ou egal aux capitaux propres"
      //   );
      //   errorStatus = true;
      //   tmp_rules.loanAmount = true;
      // }
      else if (
        parseFloat(this.decision.finances.newLoan.amount) > 0 &&
        parseFloat(this.decision.finances.newLoan.amount) >
          parseFloat(this.state.finances.netSituation) +
            parseFloat(this.decision.finances.capitalIncrease)
      ) {
        tmp_errorMessages.push(
          "Le montant de l'emprunt doit être inférieur ou egal aux capitaux propres, sauf si le montant de l'emprunt est 0"
        );
        errorStatus = true;
        tmp_rules.loanAmount = true;
      }

      if (isNaN(parseFloat(this.decision.finances.newLoan.duration))) {
        tmp_errorMessages.push("La durée de l'emprunt doit être un nombre");
        errorStatus = true;
        tmp_rules.loanDuration = true;
      } else if (
        parseFloat(this.decision.finances.newLoan.duration) <= 1 &&
        parseFloat(this.decision.finances.newLoan.amount) > 0
      ) {
        tmp_errorMessages.push(
          "La durée de l'emprunt doit être de 2 périodes ou plus"
        );
        errorStatus = true;
        tmp_rules.loanDuration = true;
      } else if (
        parseFloat(this.decision.finances.newLoan.duration) > 0 &&
        parseFloat(this.decision.finances.newLoan.amount) == 0
      ) {
        tmp_errorMessages.push(
          "La durée de l'emprunt n'est requise que si le montant de l'emprunt est supérieur à 0"
        );
        errorStatus = true;
        tmp_rules.loanDuration = true;
      } else if (parseFloat(this.decision.finances.newLoan.duration) % 1 != 0) {
        tmp_errorMessages.push(
          "La durée de l'emprunt doit être un nombre entier"
        );
        errorStatus = true;
        tmp_rules.loanDuration = true;
      } else if (
        parseFloat(this.decision.finances.newLoan.duration) >
        parseFloat(this.session.mainParameters.financial.maxLoanDuration)
      ) {
        tmp_errorMessages.push(
          "La durée de l'emprunt doit être inférieure ou égale à la durée maximale autorisée"
        );
        errorStatus = true;
        tmp_rules.loanDuration = true;
      }

      if (isNaN(parseFloat(this.decision.finances.newLoan.adminRate))) {
        tmp_errorMessages.push(
          "Le taux admin de l'emprunt doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.loanAdminRate = true;
      } else if (parseFloat(this.decision.finances.newLoan.adminRate) < 0) {
        tmp_errorMessages.push(
          "Le taux admin de l'emprunt doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.loanAdminRate = true;
      }

      for (let loanID in this.decision.finances.refunds) {
        if (tmp_rules.loansRefund[loanID] == undefined) {
          tmp_rules.loansRefund[loanID] = false;
        }
        if (isNaN(parseFloat(this.decision.finances.refunds[loanID].amount))) {
          tmp_errorMessages.push(
            "Le montant du remboursement de l'emprunt " +
              loanID.slice(-8) +
              " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.loansRefund[loanID] = true;
        }
        if (parseFloat(this.decision.finances.refunds[loanID].amount) < 0) {
          tmp_errorMessages.push(
            "Le montant du remboursement de l'emprunt " +
              loanID.slice(-8) +
              " doit être supérieur ou egal à 0"
          );
          errorStatus = true;
          tmp_rules.loansRefund[loanID] = true;
        }
        for (let loan of this.state.finances.contractedLoans) {
          if (loan.uuid == loanID) {
            if (
              parseFloat(this.decision.finances.refunds[loanID].amount) >
              this.computeDueCapital(loan) -
                this.computeNextCapitalPayment(loan)
            ) {
              tmp_errorMessages.push(
                "Le montant du remboursement de l'emprunt " +
                  loanID.slice(-8) +
                  " doit être inférieur ou egal a " +
                  dataFormater.formatMoney(
                    this.computeDueCapital(loan) -
                      this.computeNextCapitalPayment(loan)
                  )
              );
              errorStatus = true;
              tmp_rules.loansRefund[loanID] = true;
            }
          }
        }
      }

      this.errorMessages = tmp_errorMessages;
      this.errorRules = tmp_rules;
      this.$forceUpdate();
      return errorStatus;
    },
    getErrorMessages() {
      return this.errorMessages;
    },
  },
};
</script>
