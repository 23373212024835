var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.state && _vm.session && _vm.decision)?_c('span',[(_vm.errorMessages.length > 0)?_c('v-alert',{attrs:{"border":"left","colored-border":"","color":"error","elevation":"2"}},[_c('h3',{staticClass:"ml-5 mb-3 error--text"},[_vm._v(" Cette page comporte des données manquantes ou éronées ")]),_c('ul',{staticClass:"ml-5"},_vm._l((_vm.errorMessages),function(message){return _c('li',{staticClass:"py-2 error--text",domProps:{"textContent":_vm._s(message)}})}),0)]):_vm._e(),_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.salesTypePanels),callback:function ($$v) {_vm.salesTypePanels=$$v},expression:"salesTypePanels"}},_vm._l((_vm.salesZones),function(sales){return (
          sales.products &&
          sales.services &&
          _vm.errorRules.communication[sales.zone.uuid] != undefined &&
          _vm.errorRules.quantity[sales.zone.uuid] &&
          _vm.errorRules.price[sales.zone.uuid] &&
          _vm.errorRules.marketing[sales.zone.uuid]
        )?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('h3',{staticClass:"d-flex justify-start align-center"},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-earth")]),_c('span',{domProps:{"textContent":_vm._s(sales.zone.name)}})],1)]),_c('v-expansion-panel-content',{staticClass:"pt-5 px-4"},[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.profile.role != 'User' ||
                _vm.session.decisionParameters.budget.communication
              ),expression:"\n                profile.role != 'User' ||\n                session.decisionParameters.budget.communication\n              "}],attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"Budget communication","suffix":"€","value":_vm.formatNumber(sales.communication),"error":_vm.errorRules.communication[sales.zone.uuid]},on:{"input":(val) => {
                    sales.communication = _vm.parseNumber(val);
                    _vm.onInputChange();
                  },"keypress":_vm.onlyWholeNumber,"paste":_vm.onPasteWholeNumber}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.buildTransportList(sales.zone),"return-object":"","error":_vm.errorRules.transport[sales.zone.uuid],"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row align-center justify-start"},[_c('div',[_c('v-img',{attrs:{"contain":"","src":require(`@/assets/images/icons/transports/${item.type}.svg`),"height":"70"}})],1),_c('div',[_c('h3',{staticClass:"pb-2",domProps:{"textContent":_vm._s(item.name)}}),_c('div',{domProps:{"textContent":_vm._s('Coût : ' + item.cost + ' %')}}),_c('div',{domProps:{"textContent":_vm._s(
                          'Emission de CO2 : ' +
                          item.carbonEmission +
                          'g/colis'
                        )}})])])]}},{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('div',{staticClass:"d-flex flex-row align-center justify-start"},[_c('div',[_c('v-img',{attrs:{"contain":"","src":require(`@/assets/images/icons/transports/${item.type}.svg`),"height":"70"}})],1),_c('div',[_c('h3',{staticClass:"pb-2",domProps:{"textContent":_vm._s(item.name)}}),_c('div',{domProps:{"textContent":_vm._s('Coût : ' + item.cost + ' %')}}),_c('div',{domProps:{"textContent":_vm._s(
                            'Emission de CO2 : ' +
                            item.carbonEmission +
                            'g/colis'
                          )}})])])])]}}],null,true),model:{value:(sales.transport),callback:function ($$v) {_vm.$set(sales, "transport", $$v)},expression:"sales.transport"}})],1)],1),(_vm.productsOrdered.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Produits :")])]),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex justify-start align-start flex-wrap"},_vm._l((_vm.productsOrdered),function(product){return (
                    product &&
                    _vm.errorRules.quantity[sales.zone.uuid][product.uuid] !=
                      undefined &&
                    _vm.errorRules.price[sales.zone.uuid][product.uuid] !=
                      undefined &&
                    _vm.errorRules.marketing[sales.zone.uuid][product.uuid] !=
                      undefined
                  )?_c('v-card',{staticClass:"pa-2 ma-2",staticStyle:{"min-width":"240px"},attrs:{"elevation":"1"}},[_c('h3',{staticClass:"pb-3 d-flex justify-start align-center"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"50","color":"white","rounded":"0"}},[_c('v-img',{attrs:{"contain":"","height":"50","width":"50","src":require(`@/assets/images/icons/products/Product${product.icon}.svg`)}})],1),_c('span',{domProps:{"textContent":_vm._s(product.name)}})],1),_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"Quantité proposée à la vente","suffix":"Unité(s)","value":_vm.formatNumber(sales.products[product.uuid].amount),"error":_vm.errorRules.quantity[sales.zone.uuid][product.uuid]},on:{"input":(val) => {
                        sales.products[product.uuid].amount =
                          _vm.parseNumber(val);
                        _vm.onInputChange();
                      },"keypress":_vm.onlyWholeNumber,"paste":_vm.onPasteWholeNumber}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.profile.role != 'User' ||
                      _vm.session.decisionParameters.production.productPrice
                    ),expression:"\n                      profile.role != 'User' ||\n                      session.decisionParameters.production.productPrice\n                    "}],attrs:{"disabled":_vm.disabled,"label":"Prix","suffix":"€","value":_vm.formatDecimal(sales.products[product.uuid].price),"error":_vm.errorRules.price[sales.zone.uuid][product.uuid]},on:{"input":(val) => {
                        sales.products[product.uuid].price =
                          _vm.parseDecimal(val);
                        _vm.onInputChange();
                      },"blur":function($event){return _vm.onBlurField(
                        $event,
                        sales.products[product.uuid],
                        'price'
                      )},"keypress":_vm.onlyNumber,"paste":_vm.onPasteNumber}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.profile.role != 'User' ||
                      _vm.session.decisionParameters.budget.marketing
                    ),expression:"\n                      profile.role != 'User' ||\n                      session.decisionParameters.budget.marketing\n                    "}],attrs:{"disabled":_vm.disabled,"label":"Budget marketing","suffix":"€","value":_vm.formatNumber(sales.products[product.uuid].marketing),"error":_vm.errorRules.marketing[sales.zone.uuid][product.uuid]},on:{"input":(val) => {
                        sales.products[product.uuid].marketing =
                          _vm.parseNumber(val);
                        _vm.onInputChange();
                      },"keypress":_vm.onlyWholeNumber,"paste":_vm.onPasteWholeNumber}})],1):_vm._e()}),1)])],1):_vm._e(),(_vm.servicesOrdered.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Services :")])]),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex justify-start align-start flex-wrap"},_vm._l((_vm.servicesOrdered),function(service){return (
                    service &&
                    _vm.errorRules.quantity[sales.zone.uuid][service.uuid] !=
                      undefined &&
                    _vm.errorRules.price[sales.zone.uuid][service.uuid] !=
                      undefined &&
                    _vm.errorRules.marketing[sales.zone.uuid][service.uuid] !=
                      undefined
                  )?_c('v-card',{staticClass:"pa-2 ma-2",staticStyle:{"min-width":"240px"},attrs:{"elevation":"1"}},[_c('h3',{staticClass:"pb-3 d-flex justify-start align-center"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"50","color":"white","rounded":"0"}},[_c('v-img',{attrs:{"contain":"","height":"50","width":"50","src":require(`@/assets/images/icons/services/Service${service.icon}.svg`)}})],1),_c('span',{domProps:{"textContent":_vm._s(service.name)}})],1),_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"Quantité proposée à la vente","suffix":"Unité(s)","value":_vm.formatNumber(sales.services[service.uuid].amount),"error":_vm.errorRules.quantity[sales.zone.uuid][service.uuid]},on:{"input":(val) => {
                        sales.services[service.uuid].amount =
                          _vm.parseNumber(val);
                        _vm.onInputChange();
                      },"keypress":_vm.onlyWholeNumber,"paste":_vm.onPasteWholeNumber}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.profile.role != 'User' ||
                      _vm.session.decisionParameters.production.servicePrice
                    ),expression:"\n                      profile.role != 'User' ||\n                      session.decisionParameters.production.servicePrice\n                    "}],attrs:{"disabled":_vm.disabled,"label":"Prix","suffix":"€","value":_vm.formatDecimal(sales.services[service.uuid].price),"error":_vm.errorRules.price[sales.zone.uuid][service.uuid]},on:{"input":(val) => {
                        sales.services[service.uuid].price =
                          _vm.parseDecimal(val);
                        _vm.onInputChange();
                      },"blur":function($event){return _vm.onBlurField(
                        $event,
                        sales.services[service.uuid],
                        'price'
                      )},"keypress":_vm.onlyNumber,"paste":_vm.onPasteNumber}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.profile.role != 'User' ||
                      _vm.session.decisionParameters.budget.marketing
                    ),expression:"\n                      profile.role != 'User' ||\n                      session.decisionParameters.budget.marketing\n                    "}],attrs:{"disabled":_vm.disabled,"label":"Budget marketing","suffix":"€","value":_vm.formatNumber(sales.services[service.uuid].marketing),"error":_vm.errorRules.marketing[sales.zone.uuid][service.uuid]},on:{"change":_vm.onDecisionUpdate,"input":(val) => {
                        sales.services[service.uuid].marketing =
                          _vm.parseNumber(val);
                        _vm.onInputChange();
                      },"keypress":_vm.onlyWholeNumber,"paste":_vm.onPasteWholeNumber}})],1):_vm._e()}),1)])],1):_vm._e()],1)],1):_vm._e()}),1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }