var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.state && _vm.session && _vm.decision)?_c('span',[(_vm.errorMessages.length > 0)?_c('v-alert',{attrs:{"border":"left","colored-border":"","color":"error","elevation":"2"}},[_c('h3',{staticClass:"ml-5 mb-3 error--text"},[_vm._v(" Cette page comporte des données manquantes ou éronées ")]),_c('ul',{staticClass:"ml-5"},_vm._l((_vm.errorMessages),function(message){return _c('li',{staticClass:"py-2 error--text",domProps:{"textContent":_vm._s(message)}})}),0)]):_vm._e(),_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Récapitulatif : ")]),_c('v-card-text',[_c('span',{staticClass:"d-flex flex-row flex-wrap align-center justify-start"},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',{staticClass:"pr-1"},[_vm._v("Coût Recherche & Développement total :")]),_c('span',{domProps:{"textContent":_vm._s(_vm.dataFormater.formatMoney(parseFloat(_vm.totalRndCost)))}})])])])],1),_c('div',{staticClass:"mb-12 pa-0"},[_c('v-row',[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.productionTypePanels),callback:function ($$v) {_vm.productionTypePanels=$$v},expression:"productionTypePanels"}},[(
            Object.values(_vm.decision.production.products.production).length >
              0 ||
            Object.values(_vm.decision.production.products.researching).length > 0
          )?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"py-3"},[_vm._v("Mes produits :")])])],1)],1),_c('v-expansion-panel-content',[_c('v-row',[_vm._l((Object.values(
                  _vm.decision.production.products.production
                ).sort((a, b) =>
                  a.product.name.localeCompare(b.product.name)
                )),function(production){return _c('v-col',{attrs:{"cols":"12","md":"6"}},[(production.product)?_c('v-card',{staticClass:"pa-3",attrs:{"outlined":"","min-height":"100%"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-wrap align-center justify-start",attrs:{"cols":"12"}},[_c('v-avatar',{attrs:{"size":"60","color":"white","rounded":"0"}},[_c('v-img',{attrs:{"contain":"","height":"60","width":"60","src":require(`@/assets/images/icons/products/Product${production.product.icon}.svg`)}})],1),_c('span',{staticClass:"d-flex align-center pl-5"},[_c('span',{domProps:{"textContent":_vm._s(production.product.name)}})])],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"py-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Coût de base : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(production.product.productionCost)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Temps de production : ")]),_c('span',{domProps:{"textContent":_vm._s(production.product.productionTime + 'h')}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Prix min : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(production.product.minimalPrice)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Prix max : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(production.product.maximalPrice)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Stock actuel : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.currentStocks[production.product.uuid]
                                ? _vm.currentStocks[production.product.uuid]
                                : 0
                            )}})])])],1),_c('v-divider'),_c('v-row',{staticClass:"mt-5 d-flex flex-row align-center justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-2 my-0",attrs:{"disabled":_vm.disabled,"label":"Production souhaitée","suffix":"unité(s)","value":_vm.formatNumber(production.production),"error":_vm.errorRules.products.production[
                              production.product.uuid
                            ]},on:{"input":(val) => {
                              production.production = _vm.parseNumber(val);
                              _vm.onInputChange();
                            },"keypress":_vm.onlyWholeNumber,"paste":_vm.onPasteWholeNumber}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                          _vm.profile.role != 'User' ||
                          _vm.session.decisionParameters.budget.quality
                        ),expression:"\n                          profile.role != 'User' ||\n                          session.decisionParameters.budget.quality\n                        "}],attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-2 my-0",attrs:{"disabled":_vm.disabled,"label":"Budget RSE et qualité","suffix":"€","value":_vm.formatNumber(production.quality),"error":_vm.errorRules.products.quality[
                              production.product.uuid
                            ]},on:{"input":(val) => {
                              production.quality = _vm.parseNumber(val);
                              _vm.onInputChange();
                            },"keypress":_vm.onlyWholeNumber,"paste":_vm.onPasteWholeNumber}})],1)],1),_c('span',{staticClass:"d-flex align-center justify-center mt-5"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":_vm.disabled ||
                          parseInt(production.quality) +
                            parseFloat(production.production) <=
                            0},on:{"click":function($event){return _vm.stopProduction(production)}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-power")]),_c('span',[_vm._v(" Desactiver la production ")])],1)],1)],1)],1):_vm._e()],1)}),_vm._l((Object.values(
                  _vm.decision.production.products.researching
                ).sort((a, b) =>
                  a.product.name.localeCompare(b.product.name)
                )),function(research){return _c('v-col',{attrs:{"cols":"12","md":"6"}},[(research.product)?_c('v-card',{staticClass:"pa-3",attrs:{"outlined":"","min-height":"100%"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-wrap align-center justify-start",attrs:{"cols":"12"}},[_c('v-avatar',{attrs:{"size":"60","color":"white","rounded":"0"}},[_c('v-img',{attrs:{"contain":"","height":"60","width":"60","src":require(`@/assets/images/icons/products/Product${research.product.icon}.svg`)}})],1),_c('span',{staticClass:"d-flex align-center pl-5"},[_c('span',{domProps:{"textContent":_vm._s(research.product.name)}})])],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"py-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Coût de base : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(research.product.productionCost)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Temps de production : ")]),_c('span',{domProps:{"textContent":_vm._s(research.product.productionTime + 'h')}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Prix min : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(research.product.minimalPrice)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Prix max : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(research.product.maximalPrice)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Coûts Recherche & Développement : ")]),_c('span',{domProps:{"textContent":_vm._s(research.product.rndCost)}})])]),(research.inResearch)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',{staticClass:"accent--text"},[_vm._v("Recherche en cours !")])])]):_vm._e()],1),_c('v-divider'),_c('v-row',{staticClass:"mt-5 d-flex flex-row align-center justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-2 my-0",attrs:{"disabled":_vm.disabled,"type":"number","label":"Production souhaitée","suffix":"unité(s)","value":"0","disabled":""}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                          _vm.profile.role != 'User' ||
                          _vm.session.decisionParameters.budget.quality
                        ),expression:"\n                          profile.role != 'User' ||\n                          session.decisionParameters.budget.quality\n                        "}],attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-2 my-0",attrs:{"disabled":_vm.disabled,"type":"number","label":"Budget RSE et qualité","suffix":"€","value":"0","disabled":""}})],1)],1),_c('span',{staticClass:"d-flex align-center justify-center mt-5"},[(research.inResearch)?_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"accent","outlined":""},on:{"click":function($event){research.inResearch = !research.inResearch;
                          _vm.onDecisionUpdate();}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-lock-outline")]),_c('span',{domProps:{"textContent":_vm._s('Annuler la recherche')}})],1):_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"primary","outlined":""},on:{"click":function($event){research.inResearch = !research.inResearch;
                          _vm.onDecisionUpdate();}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-lock-outline")]),_c('span',{domProps:{"textContent":_vm._s(
                            'Rechercher (' +
                            _vm.dataFormater.formatMoney(
                              parseFloat(research.product.rndCost)
                            ) +
                            ')'
                          )}})],1)],1)],1)],1):_vm._e()],1)})],2)],1)],1):_vm._e(),(
            Object.values(_vm.decision.production.services.production).length >
              0 ||
            Object.values(_vm.decision.production.services.researching).length > 0
          )?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"py-3"},[_vm._v("Mes services :")])])],1)],1),_c('v-expansion-panel-content',[_c('v-row',[_vm._l((Object.values(
                  _vm.decision.production.services.production
                ).sort((a, b) =>
                  a.product.name.localeCompare(b.product.name)
                )),function(production){return _c('v-col',{attrs:{"cols":"12","md":"6"}},[(production.service)?_c('v-card',{staticClass:"pa-3",attrs:{"outlined":"","min-height":"100%"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-wrap align-center justify-start",attrs:{"cols":"12"}},[_c('v-avatar',{attrs:{"size":"60","color":"white","rounded":"0"}},[_c('v-img',{attrs:{"contain":"","height":"60","width":"60","src":require(`@/assets/images/icons/services/Service${production.service.icon}.svg`)}})],1),_c('span',{staticClass:"d-flex align-center pl-5"},[_c('span',{domProps:{"textContent":_vm._s(production.service.name)}})])],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"py-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Coût de base : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(production.service.productionCost)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Temps de production : ")]),_c('span',{domProps:{"textContent":_vm._s(production.service.productionTime + 'h')}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Prix min : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(production.service.minimalPrice)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Prix max : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(production.service.maximalPrice)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Stock actuel : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.currentStocks[production.service.uuid]
                                ? _vm.currentStocks[production.service.uuid]
                                : 0
                            )}})])])],1),_c('v-divider'),_c('v-row',{staticClass:"mt-5 d-flex flex-row align-center justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-2 my-0",attrs:{"disabled":_vm.disabled,"label":"Production max","suffix":"unité(s)","value":_vm.formatNumber(production.production),"error":_vm.errorRules.services.production[
                              production.service.uuid
                            ]},on:{"input":(val) => {
                              production.production = _vm.parseNumber(val);
                              _vm.onInputChange();
                            },"keypress":_vm.onlyWholeNumber,"paste":_vm.onPasteWholeNumber}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                          _vm.profile.role != 'User' ||
                          _vm.session.decisionParameters.budget.quality
                        ),expression:"\n                          profile.role != 'User' ||\n                          session.decisionParameters.budget.quality\n                        "}],attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-2 my-0",attrs:{"disabled":_vm.disabled,"label":"Budget RSE et qualité","suffix":"€","value":_vm.formatNumber(production.quality),"error":_vm.errorRules.services.quality[
                              production.service.uuid
                            ]},on:{"input":(val) => {
                              production.quality = _vm.parseNumber(val);
                              _vm.onInputChange();
                            },"keypress":_vm.onlyWholeNumber,"paste":_vm.onPasteWholeNumber}})],1)],1),_c('span',{staticClass:"d-flex align-center justify-center mt-5"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":_vm.disabled ||
                          parseInt(production.quality) +
                            parseFloat(production.production) <=
                            0},on:{"click":function($event){return _vm.stopProduction(production)}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-power")]),_c('span',[_vm._v(" Desactiver la production ")])],1)],1)],1)],1):_vm._e()],1)}),_vm._l((Object.values(
                  _vm.decision.production.services.researching
                ).sort((a, b) =>
                  a.product.name.localeCompare(b.product.name)
                )),function(research){return _c('v-col',{attrs:{"cols":"12","md":"6"}},[(research.service)?_c('v-card',{staticClass:"pa-3",attrs:{"outlined":"","min-height":"100%"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-wrap align-center justify-start",attrs:{"cols":"12"}},[_c('v-avatar',{attrs:{"size":"60","color":"white","rounded":"0"}},[_c('v-img',{attrs:{"contain":"","height":"60","width":"60","src":require(`@/assets/images/icons/services/Service${research.service.icon}.svg`)}})],1),_c('span',{staticClass:"d-flex align-center pl-5"},[_c('span',{domProps:{"textContent":_vm._s(research.service.name)}})])],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"py-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Coût de base : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(research.service.productionCost)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Temps de production : ")]),_c('span',{domProps:{"textContent":_vm._s(research.service.productionTime + 'h')}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Prix min : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(research.service.minimalPrice)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Prix max : ")]),_c('span',{domProps:{"textContent":_vm._s(
                              _vm.dataFormater.formatMoney(
                                parseFloat(research.service.maximalPrice)
                              )
                            )}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Coûts Recherche & Développement : ")]),_c('span',{domProps:{"textContent":_vm._s(research.service.rndCost)}})])]),(research.inResearch)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',{staticClass:"accent--text"},[_vm._v("Recherche en cours !")])])]):_vm._e()],1),_c('v-divider'),_c('v-row',{staticClass:"mt-5 d-flex flex-row align-center justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-2 my-0",attrs:{"disabled":_vm.disabled,"type":"number","label":"Production souhaitée","suffix":"unité(s)","value":"0","disabled":""}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                          _vm.profile.role != 'User' ||
                          _vm.session.decisionParameters.budget.quality
                        ),expression:"\n                          profile.role != 'User' ||\n                          session.decisionParameters.budget.quality\n                        "}],attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-2 my-0",attrs:{"disabled":_vm.disabled,"type":"number","label":"Budget RSE et qualité","suffix":"€","value":"0","disabled":""}})],1)],1),_c('span',{staticClass:"d-flex align-center justify-center mt-5"},[(research.inResearch)?_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"accent","outlined":""},on:{"click":function($event){research.inResearch = !research.inResearch;
                          _vm.onDecisionUpdate();}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-lock-outline")]),_c('span',{domProps:{"textContent":_vm._s('Annuler la recherche')}})],1):_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"primary","outlined":""},on:{"click":function($event){research.inResearch = !research.inResearch;
                          _vm.onDecisionUpdate();}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-lock-outline")]),_c('span',{domProps:{"textContent":_vm._s(
                            'Rechercher (' +
                            _vm.dataFormater.formatMoney(
                              parseFloat(research.service.rndCost)
                            ) +
                            ')'
                          )}})],1)],1)],1)],1):_vm._e()],1)})],2)],1)],1):_vm._e()],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }