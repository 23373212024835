<template>
  <span v-if="state && session && decision">
    <v-alert
      border="left"
      colored-border
      color="error"
      elevation="2"
      v-if="errorMessages.length > 0"
    >
      <h3 class="ml-5 mb-3 error--text">
        Cette page comporte des données manquantes ou éronées
      </h3>
      <ul class="ml-5">
        <li
          class="py-2 error--text"
          v-for="message in errorMessages"
          v-text="message"
        ></li>
      </ul>
    </v-alert>
    <v-card class="mb-12" outlined>
      <v-card-title primary-title> Récapitulatif : </v-card-title>
      <v-card-text>
        <span class="d-flex flex-row flex-wrap align-center justify-start">
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Coûts des salariés :</b>
            <span
              v-text="
                isNaN(parseFloat(salarialCost))
                  ? 'indisponible'
                  : dataFormater.formatMoney(parseFloat(salarialCost))
              "
            ></span>
          </span>
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b
              class="pr-1"
              v-show="
                profile.role != 'User' || session.decisionParameters.hr.training
              "
              >Coûts des formations:</b
            >
            <span
              v-text="
                isNaN(parseFloat(trainingCost))
                  ? 'indisponible'
                  : dataFormater.formatMoney(parseFloat(trainingCost))
              "
              v-show="
                profile.role != 'User' || session.decisionParameters.hr.training
              "
            ></span>
          </span>
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Frais d'embauche et de licenciement:</b>
            <span
              v-text="
                isNaN(parseFloat(otherCost))
                  ? 'indisponible'
                  : dataFormater.formatMoney(parseFloat(otherCost))
              "
            ></span>
          </span>
        </span>
        <div
          class="ma-0 pa-2"
          v-show="
            profile.role != 'User' || session.decisionParameters.budget.social
          "
        >
          <v-text-field
            :disabled="disabled"
            label="Budget Social"
            suffix="€"
            :value="formatNumber(decision.hr.socialBudget)"
            :error="errorRules.socialBudget"
            @input="
              (val) => {
                decision.hr.socialBudget = parseNumber(val);
                onInputChange();
              }
            "
            @keypress="onlyWholeNumber"
            @change="onDecisionUpdate"
            @paste="onPasteWholeNumber"
          ></v-text-field>
        </div>
      </v-card-text>
    </v-card>
    <div class="mb-12 pa-0">
      <v-card-title primary-title>
        <span class="pr-3">Mes salariés :</span>
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          :md="
            Object.values(decision.production.services.production).length > 0 ||
            Object.values(decision.production.services.researching).length > 0
              ? 6
              : 12
          "
          v-if="
            Object.values(decision.production.products.production).length > 0 ||
            Object.values(decision.production.products.researching).length > 0
          "
        >
          <v-card class="pa-3" outlined>
            <v-card-title primary-title class="d-flex align-center">
              <v-avatar size="80" color="white" rounded="0" class="mr-3">
                <v-img
                  contain
                  height="80"
                  width="80"
                  :src="require(`@/assets/images/icons/hr/Worker.svg`)"
                ></v-img>
              </v-avatar>
              <span>Mes ouvriers</span>
            </v-card-title>
            <v-card-text>
              <v-row class="py-5">
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Effectif P-1 :&nbsp;</b>
                    <span v-text="state.hr.workerAmount"></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Coût de base :&nbsp;</b>
                    <span
                      v-text="
                        dataFormater.formatMoney(
                          parseFloat(session.hrParameters.worker.baseSalary)
                        )
                      "
                    ></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Nombre d'heures travaillées :&nbsp;</b>
                    <span
                      v-text="
                        parseInt(session.hrParameters.worker.productionTime) +
                        'h'
                      "
                    ></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Coût de actuel :&nbsp;</b>
                    <span
                      v-text="
                        dataFormater.formatMoney(
                          parseFloat(
                            session.hrParameters.worker.baseSalary *
                              (decision.hr.workers.socialIndex / 100)
                          )
                        )
                      "
                    ></span>
                  </span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="mt-5 d-flex flex-row align-center justify-center">
                <v-col
                  cols="12"
                  md="12"
                  lg="4"
                  v-show="
                    profile.role != 'User' ||
                    session.decisionParameters.hr.salary
                  "
                >
                  <v-text-field
                    :disabled="disabled"
                    class="mx-2 my-0"
                    label="Indice social"
                    suffix="%"
                    :value="formatNumber(decision.hr.workers.socialIndex)"
                    :error="errorRules.workers.socialIndex"
                    @input="
                      (val) => {
                        decision.hr.workers.socialIndex = parseNumber(val);
                        onInputChange();
                      }
                    "
                    @keypress="onlyWholeNumber"
                    @paste="onPasteWholeNumber"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                  v-show="
                    profile.role != 'User' ||
                    session.decisionParameters.hr.worker.hiring
                  "
                >
                  <v-text-field
                    :disabled="disabled"
                    class="mx-2 my-0"
                    label="Embaucher"
                    suffix="ouvrier(s)"
                    :value="formatNumber(decision.hr.workers.hiringAmount)"
                    :error="errorRules.workers.hiringAmount"
                    @input="
                      (val) => {
                        decision.hr.workers.hiringAmount = parseNumber(val);
                        onInputChange();
                      }
                    "
                    @change="onDecisionUpdate"
                    @keypress="onlyWholeNumber"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                  v-show="
                    profile.role != 'User' ||
                    session.decisionParameters.hr.worker.firing
                  "
                >
                  <v-text-field
                    :disabled="disabled"
                    class="mx-2 my-0"
                    label="Licencier"
                    suffix="ouvrier(s)"
                    :value="formatNumber(decision.hr.workers.firingAmount)"
                    :error="errorRules.workers.firingAmount"
                    @input="
                      (val) => {
                        decision.hr.workers.firingAmount = parseNumber(val);
                        onInputChange();
                      }
                    "
                    @keypress="onlyWholeNumber"
                    @paste="onPasteWholeNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-expansion-panels
            class="mt-2"
            v-model="jobPanels"
            multiple
            v-if="
              decision.hr.productManagers.config.length +
                decision.hr.productManagers.hiring.length +
                availableCVs['productManagers'].length >
              0
            "
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row>
                  <h3 class="py-3">
                    Mes chefs de production
                    <span
                      v-text="
                        '(' +
                        (decision.hr.productManagers.config.length +
                          decision.hr.productManagers.hiring.length) +
                        ')'
                      "
                    ></span>
                  </h3>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-10"
                    v-show="
                      profile.role != 'User' ||
                      session.decisionParameters.hr.productManager.hiring
                    "
                    :disabled="disabled"
                    color="primary"
                    outlined
                    @click.prevent.stop="
                      hiringDialog = true;
                      selectedJob = 'productManagers';
                    "
                    >Embaucher</v-btn
                  >
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <v-row class="d-flex flex-row align-center justify-start">
                    <v-col
                      cols="12"
                      v-if="
                        decision.hr.productManagers.config.length +
                          decision.hr.productManagers.hiring.length <=
                        0
                      "
                    >
                      <h2 class="text--secondary">
                        Aucun chef de production embaucher pour le moment !
                      </h2>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="6"
                      v-for="cv in decision.hr.productManagers.config"
                    >
                      <v-card>
                        <v-card-title
                          class="d-flex align-center justify-center"
                          style="width: 100%"
                        >
                          <h3
                            class="text-center"
                            v-text="cv.lastname + ' ' + cv.firstname"
                          ></h3>
                        </v-card-title>
                        <v-card-text>
                          <v-divider></v-divider>
                          <span
                            class="d-flex align-center justify-center py-3"
                            style="width: 100%"
                          >
                            <v-avatar
                              size="100"
                              color="white"
                              rounded="0"
                              class="mr-3"
                            >
                              <v-img
                                contain
                                height="100"
                                width="100"
                                :src="
                                  require(`@/assets/images/icons/hr/ProductManager.svg`)
                                "
                              ></v-img>
                            </v-avatar>
                          </span>
                          <v-divider></v-divider>
                          <v-row class="py-5">
                            <v-col cols="12" md="6" class="py-0">
                              <span
                                class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                              >
                                <b>Capacité :&nbsp;</b>
                                <span v-text="cv.staffCapacity"></span>
                              </span>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <span
                                class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                              >
                                <b>Coût actuel :&nbsp;</b>
                                <span
                                  v-text="
                                    isNaN(parseFloat(cv.socialIndex))
                                      ? 'indisponible'
                                      : dataFormater.formatMoney(
                                          parseFloat(cv.baseSalary) *
                                            (parseFloat(cv.socialIndex) / 100)
                                        )
                                  "
                                ></span>
                              </span>
                            </v-col>
                          </v-row>
                          <v-text-field
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.salary
                            "
                            :disabled="disabled"
                            class="mx-2 mt-5 mb-0"
                            label="Indice social"
                            suffix="%"
                            :value="formatNumber(cv.socialIndex)"
                            :error="
                              errorRules.productManagers.socialIndex[cv.uuid]
                            "
                            @input="
                              (val) => {
                                cv.socialIndex = parseNumber(val);
                                onInputChange();
                              }
                            "
                            @keypress="onlyWholeNumber"
                            @paste="onPasteWholeNumber"
                          ></v-text-field>
                          <span
                            class="pa-2 mt-5 d-flex flex-row flex-wrap align-center justify-center"
                          >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.training
                              "
                              :disabled="disabled"
                              class="mr-3 mt-2"
                              color="accent"
                              @click="cancelTraining(cv)"
                              v-if="cv.training"
                              outlined
                              >Annuler la formation</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.training
                              "
                              :disabled="disabled"
                              class="mr-3 mt-2"
                              color="primary"
                              @click="openTrainingDialog(cv)"
                              v-else
                              outlined
                              >Former</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.productManager
                                  .firing
                              "
                              :disabled="disabled"
                              class="mt-2"
                              color="accent"
                              outlined
                              @click="fireCv(cv)"
                              v-if="
                                !decision.hr.productManagers.firing.includes(
                                  cv.uuid
                                )
                              "
                              >Licencier</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.productManager
                                  .firing
                              "
                              :disabled="disabled"
                              class="mt-2"
                              color="secondary"
                              outlined
                              @click="cancelFiringCv(cv)"
                              v-else
                              >Annuler licenciement</v-btn
                            >
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="6"
                      v-for="cv in decision.hr.productManagers.hiring"
                    >
                      <v-card>
                        <v-card-title
                          class="d-flex align-center justify-center"
                          style="width: 100%"
                        >
                          <h3
                            class="text-center"
                            v-text="cv.lastname + ' ' + cv.firstname"
                          ></h3>
                        </v-card-title>
                        <v-card-text>
                          <v-divider></v-divider>
                          <span
                            class="d-flex align-center justify-center py-3"
                            style="width: 100%"
                          >
                            <v-avatar
                              size="100"
                              color="white"
                              rounded="0"
                              class="mr-3"
                            >
                              <v-img
                                contain
                                height="100"
                                width="100"
                                :src="
                                  require(`@/assets/images/icons/hr/ProductManager.svg`)
                                "
                              ></v-img>
                            </v-avatar>
                          </span>
                          <v-divider></v-divider>
                          <v-row class="py-5">
                            <v-col cols="12" md="6" class="py-0">
                              <span
                                class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                              >
                                <b>Capacité :&nbsp;</b>
                                <span v-text="cv.staffCapacity"></span>
                              </span>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <span
                                class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                              >
                                <b>Coût actuel :&nbsp;</b>
                                <span
                                  v-text="
                                    isNaN(parseFloat(cv.socialIndex))
                                      ? 'indisponible'
                                      : dataFormater.formatMoney(
                                          parseFloat(cv.baseSalary) *
                                            (parseFloat(cv.socialIndex) / 100)
                                        )
                                  "
                                ></span>
                              </span>
                            </v-col>
                          </v-row>
                          <v-text-field
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.salary
                            "
                            :disabled="disabled"
                            class="mx-2 mt-5 mb-0"
                            label="Indice social"
                            suffix="%"
                            :value="formatNumber(cv.socialIndex)"
                            :error="
                              errorRules.productManagers.socialIndex[cv.uuid]
                            "
                            @input="
                              (val) => {
                                cv.socialIndex = parseNumber(val);
                                onInputChange();
                              }
                            "
                            @keypress="onlyWholeNumber"
                            @paste="onPasteWholeNumber"
                          ></v-text-field>
                          <span
                            class="pa-2 mt-5 d-flex flex-row flex-wrap align-center justify-center"
                          >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.training
                              "
                              :disabled="disabled"
                              class="mr-3 mt-2"
                              color="accent"
                              @click="cancelTraining(cv)"
                              v-if="cv.training"
                              outlined
                              >Annuler la formation</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.training
                              "
                              :disabled="disabled"
                              class="mr-3 mt-2"
                              color="primary"
                              @click="openTrainingDialog(cv)"
                              v-else
                              outlined
                              >Former</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.serviceManager
                                  .hiring
                              "
                              :disabled="disabled"
                              class="mt-2"
                              color="accent"
                              outlined
                              @click="cancelHiring(cv)"
                              >Annuler l'embauche</v-btn
                            >
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col
          cols="12"
          :md="
            Object.values(decision.production.products.production).length > 0 ||
            Object.values(decision.production.products.researching).length > 0
              ? 6
              : 12
          "
          v-if="
            Object.values(decision.production.services.production).length > 0 ||
            Object.values(decision.production.services.researching).length > 0
          "
        >
          <v-card class="pa-3" outlined>
            <v-card-title primary-title class="d-flex align-center">
              <v-avatar size="80" color="white" rounded="0" class="mr-3">
                <v-img
                  contain
                  height="80"
                  width="80"
                  :src="require(`@/assets/images/icons/hr/Employes.svg`)"
                ></v-img>
              </v-avatar>
              <span>Mes employés</span>
            </v-card-title>
            <v-card-text>
              <v-row class="py-5">
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Effectif P-1 :&nbsp;</b>
                    <span v-text="state.hr.employeeAmount"></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Coût de base :&nbsp;</b>
                    <span
                      v-text="
                        dataFormater.formatMoney(
                          parseFloat(session.hrParameters.employee.baseSalary)
                        )
                      "
                    ></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Nombre d'heures travaillées :&nbsp;</b>
                    <span
                      v-text="
                        parseInt(session.hrParameters.employee.productionTime) +
                        'h'
                      "
                    ></span>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span
                    class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                  >
                    <b>Coût de actuel :&nbsp;</b>
                    <span
                      v-text="
                        dataFormater.formatMoney(
                          parseFloat(
                            session.hrParameters.employee.baseSalary *
                              (decision.hr.employees.socialIndex / 100)
                          )
                        )
                      "
                    ></span>
                  </span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="mt-5 d-flex flex-row align-center justify-center">
                <v-col
                  cols="12"
                  md="12"
                  lg="4"
                  v-show="
                    profile.role != 'User' ||
                    session.decisionParameters.hr.salary
                  "
                >
                  <v-text-field
                    :disabled="disabled"
                    class="mx-2 my-0"
                    label="Indice social"
                    suffix="%"
                    :value="formatNumber(decision.hr.employees.socialIndex)"
                    :error="errorRules.employees.socialIndex"
                    @input="
                      (val) => {
                        decision.hr.employees.socialIndex = parseNumber(val);
                        onInputChange();
                      }
                    "
                    @keypress="onlyWholeNumber"
                    @paste="onPasteWholeNumber"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                  v-show="
                    profile.role != 'User' ||
                    session.decisionParameters.hr.employee.hiring
                  "
                >
                  <v-text-field
                    :disabled="disabled"
                    class="mx-2 my-0"
                    label="Embaucher"
                    suffix="ouvrier(s)"
                    :value="formatNumber(decision.hr.employees.hiringAmount)"
                    :error="errorRules.employees.hiringAmount"
                    @change="onDecisionUpdate"
                    @input="
                      (val) => {
                        decision.hr.employees.hiringAmount = parseNumber(val);
                        onInputChange();
                      }
                    "
                    @keypress="onlyWholeNumber"
                    @paste="onPasteWholeNumber"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                  v-show="
                    profile.role != 'User' ||
                    session.decisionParameters.hr.employee.firing
                  "
                >
                  <v-text-field
                    :disabled="disabled"
                    class="mx-2 my-0"
                    label="Licencier"
                    suffix="ouvrier(s)"
                    :value="formatNumber(decision.hr.employees.firingAmount)"
                    :error="errorRules.employees.firingAmount"
                    @input="
                      (val) => {
                        decision.hr.employees.firingAmount = parseNumber(val);
                        onInputChange();
                      }
                    "
                    @keypress="onlyWholeNumber"
                    @paste="onPasteWholeNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-expansion-panels
            class="mt-2"
            v-model="jobPanels"
            multiple
            v-if="
              decision.hr.serviceManagers.config.length +
                decision.hr.serviceManagers.hiring.length +
                availableCVs['serviceManagers'].length >
              0
            "
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row>
                  <h3 class="py-3">
                    Mes managers
                    <span
                      v-text="
                        '(' +
                        (decision.hr.serviceManagers.config.length +
                          decision.hr.serviceManagers.hiring.length) +
                        ')'
                      "
                    ></span>
                  </h3>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-10"
                    v-show="
                      profile.role != 'User' ||
                      session.decisionParameters.hr.serviceManager.hiring
                    "
                    :disabled="disabled"
                    color="primary"
                    outlined
                    @click.prevent.stop="
                      hiringDialog = true;
                      selectedJob = 'serviceManagers';
                    "
                    >Embaucher</v-btn
                  >
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <v-row class="d-flex flex-row align-center justify-start">
                    <v-col
                      cols="12"
                      v-if="
                        decision.hr.serviceManagers.config.length +
                          decision.hr.serviceManagers.hiring.length <=
                        0
                      "
                    >
                      <h2 class="text--secondary">
                        Aucun manager embaucher pour le moment !
                      </h2>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="6"
                      v-for="cv in decision.hr.serviceManagers.config"
                    >
                      <v-card>
                        <v-card-title
                          class="d-flex align-center justify-center"
                          style="width: 100%"
                        >
                          <h3
                            class="text-center"
                            v-text="cv.lastname + ' ' + cv.firstname"
                          ></h3>
                        </v-card-title>
                        <v-card-text>
                          <v-divider></v-divider>
                          <span
                            class="d-flex align-center justify-center py-3"
                            style="width: 100%"
                          >
                            <v-avatar
                              size="100"
                              color="white"
                              rounded="0"
                              class="mr-3"
                            >
                              <v-img
                                contain
                                height="100"
                                width="100"
                                :src="
                                  require(`@/assets/images/icons/hr/ServiceManager.svg`)
                                "
                              ></v-img>
                            </v-avatar>
                          </span>
                          <v-divider></v-divider>
                          <v-row class="py-5">
                            <v-col cols="12" md="6" class="py-0">
                              <span
                                class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                              >
                                <b>Capacité :&nbsp;</b>
                                <span v-text="cv.staffCapacity"></span>
                              </span>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <span
                                class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                              >
                                <b>Coût actuel :&nbsp;</b>
                                <span
                                  v-text="
                                    isNaN(parseFloat(cv.socialIndex))
                                      ? 'indisponible'
                                      : dataFormater.formatMoney(
                                          parseFloat(cv.baseSalary) *
                                            (parseFloat(cv.socialIndex) / 100)
                                        )
                                  "
                                ></span>
                              </span>
                            </v-col>
                          </v-row>
                          <v-text-field
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.salary
                            "
                            :disabled="disabled"
                            class="mx-2 mt-5 mb-0"
                            label="Indice social"
                            suffix="%"
                            :value="formatNumber(cv.socialIndex)"
                            :error="
                              errorRules.serviceManagers.socialIndex[cv.uuid]
                            "
                            @input="
                              (val) => {
                                cv.socialIndex = parseNumber(val);
                                onInputChange();
                              }
                            "
                            @keypress="onlyWholeNumber"
                            @paste="onPasteWholeNumber"
                          ></v-text-field>
                          <span
                            class="pa-2 mt-5 d-flex flex-row flex-wrap align-center justify-center"
                          >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.training
                              "
                              :disabled="disabled"
                              class="mr-3 mt-2"
                              color="accent"
                              @click="cancelTraining(cv)"
                              v-if="cv.training"
                              outlined
                              >Annuler la formation</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.training
                              "
                              :disabled="disabled"
                              class="mr-3 mt-2"
                              color="primary"
                              @click="openTrainingDialog(cv)"
                              v-else
                              outlined
                              >Former</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.serviceManager
                                  .firing
                              "
                              :disabled="disabled"
                              class="mt-2"
                              color="accent"
                              outlined
                              @click="fireCv(cv)"
                              v-if="
                                !decision.hr.serviceManagers.firing.includes(
                                  cv.uuid
                                )
                              "
                              >Licencier</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.serviceManager
                                  .firing
                              "
                              :disabled="disabled"
                              class="mt-2"
                              color="secondary"
                              outlined
                              @click="cancelFiringCv(cv)"
                              v-else
                              >Annuler licenciement</v-btn
                            >
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="6"
                      v-for="cv in decision.hr.serviceManagers.hiring"
                    >
                      <v-card>
                        <v-card-title
                          class="d-flex align-center justify-center"
                          style="width: 100%"
                        >
                          <h3
                            class="text-center"
                            v-text="cv.lastname + ' ' + cv.firstname"
                          ></h3>
                        </v-card-title>
                        <v-card-text>
                          <v-divider></v-divider>
                          <span
                            class="d-flex align-center justify-center py-3"
                            style="width: 100%"
                          >
                            <v-avatar
                              size="100"
                              color="white"
                              rounded="0"
                              class="mr-3"
                            >
                              <v-img
                                contain
                                height="100"
                                width="100"
                                :src="
                                  require(`@/assets/images/icons/hr/ServiceManager.svg`)
                                "
                              ></v-img>
                            </v-avatar>
                          </span>
                          <v-divider></v-divider>
                          <v-row class="py-5">
                            <v-col cols="12" md="6" class="py-0">
                              <span
                                class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                              >
                                <b>Capacité :&nbsp;</b>
                                <span v-text="cv.staffCapacity"></span>
                              </span>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <span
                                class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                              >
                                <b>Coût actuel :&nbsp;</b>
                                <span
                                  v-text="
                                    isNaN(parseFloat(cv.socialIndex))
                                      ? 'indisponible'
                                      : dataFormater.formatMoney(
                                          parseFloat(cv.baseSalary) *
                                            (parseFloat(cv.socialIndex) / 100)
                                        )
                                  "
                                ></span>
                              </span>
                            </v-col>
                          </v-row>
                          <v-text-field
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.salary
                            "
                            :disabled="disabled"
                            class="mx-2 mt-5 mb-0"
                            label="Indice social"
                            suffix="%"
                            :value="formatNumber(cv.socialIndex)"
                            :error="
                              errorRules.serviceManagers.socialIndex[cv.uuid]
                            "
                            @input="
                              (val) => {
                                cv.socialIndex = parseNumber(val);
                                onInputChange();
                              }
                            "
                            @keypress="onlyWholeNumber"
                            @paste="onPasteWholeNumber"
                          ></v-text-field>
                          <span
                            class="pa-2 mt-5 d-flex flex-row flex-wrap align-center justify-center"
                          >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.training
                              "
                              :disabled="disabled"
                              class="mr-3 mt-2"
                              color="accent"
                              @click="cancelTraining(cv)"
                              v-if="cv.training"
                              outlined
                              >Annuler la formation</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.training
                              "
                              :disabled="disabled"
                              class="mr-3 mt-2"
                              color="primary"
                              @click="openTrainingDialog(cv)"
                              v-else
                              outlined
                              >Former</v-btn
                            >
                            <v-btn
                              v-show="
                                profile.role != 'User' ||
                                session.decisionParameters.hr.serviceManager
                                  .hiring
                              "
                              :disabled="disabled"
                              class="mt-2"
                              color="accent"
                              outlined
                              @click="cancelHiring(cv)"
                              >Annuler l'embauche</v-btn
                            >
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row
        class="mt-5"
        v-if="
          session.decisionParameters.hr.salesman.commission ||
          session.decisionParameters.hr.salesman.deployment ||
          session.decisionParameters.hr.salesman.firing ||
          session.decisionParameters.hr.salesman.hiring
        "
      >
        <v-expansion-panels
          v-model="jobPanels"
          multiple
          v-if="
            decision.hr.salesmen.config.length +
              decision.hr.salesmen.hiring.length +
              availableCVs['salesmen'].length >
            0
          "
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-row>
                <h3 class="py-3">
                  Mes commerciaux
                  <span
                    v-text="
                      '(' +
                      (decision.hr.salesmen.config.length +
                        decision.hr.salesmen.hiring.length) +
                      ')'
                    "
                  ></span>
                </h3>
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-10"
                  v-show="
                    profile.role != 'User' ||
                    session.decisionParameters.hr.salesman.hiring
                  "
                  :disabled="disabled"
                  color="primary"
                  outlined
                  @click.prevent.stop="
                    hiringDialog = true;
                    selectedJob = 'salesmen';
                  "
                  >Embaucher</v-btn
                >
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col cols="12">
                <v-row class="d-flex flex-row align-center justify-start">
                  <v-col
                    cols="12"
                    v-if="
                      decision.hr.salesmen.config.length +
                        decision.hr.salesmen.hiring.length <=
                      0
                    "
                  >
                    <h2 class="text--secondary">
                      Aucun commercial embaucher pour le moment !
                    </h2>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                    v-for="cv in decision.hr.salesmen.config"
                  >
                    <v-card>
                      <v-card-title
                        class="d-flex align-center justify-center"
                        style="width: 100%"
                      >
                        <h3
                          class="text-center"
                          v-text="cv.lastname + ' ' + cv.firstname"
                        ></h3>
                      </v-card-title>
                      <v-card-text>
                        <v-divider></v-divider>
                        <span
                          class="d-flex align-center justify-center py-3"
                          style="width: 100%"
                        >
                          <v-avatar
                            size="100"
                            color="white"
                            rounded="0"
                            class="mr-3"
                          >
                            <v-img
                              contain
                              height="100"
                              width="100"
                              :src="
                                require(`@/assets/images/icons/hr/Salesman.svg`)
                              "
                            ></v-img>
                          </v-avatar>
                        </span>
                        <v-divider></v-divider>
                        <v-row class="py-5">
                          <v-col cols="12" md="6" class="py-0">
                            <span
                              class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                            >
                              <b>Coût actuel :&nbsp;</b>
                              <span
                                v-text="
                                  isNaN(parseFloat(cv.socialIndex))
                                    ? 'indisponible'
                                    : dataFormater.formatMoney(
                                        parseFloat(cv.baseSalary) *
                                          (parseFloat(cv.socialIndex) / 100)
                                      )
                                "
                              ></span>
                            </span>
                          </v-col>
                        </v-row>
                        <v-text-field
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.hr.salary
                          "
                          :disabled="disabled"
                          class="mx-2 mt-5 mb-0"
                          label="Indice social"
                          suffix="%"
                          :value="formatNumber(cv.socialIndex)"
                          :error="errorRules.salesmen.socialIndex[cv.uuid]"
                          @input="
                            (val) => {
                              cv.socialIndex = parseNumber(val);
                              onInputChange();
                            }
                          "
                          @keypress="onlyWholeNumber"
                          @paste="onPasteWholeNumber"
                        ></v-text-field>
                        <v-text-field
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.hr.salesman.commission
                          "
                          :disabled="disabled"
                          class="mx-2 mb-0"
                          label="Commission"
                          suffix="%"
                          :value="formatDecimal(cv.commission)"
                          :error="errorRules.salesmen.commission[cv.uuid]"
                          @input="
                            (val) => {
                              cv.commission = parseDecimal(val);
                              onInputChange();
                            }
                          "
                          @blur="onBlurField($event, cv, 'commission')"
                          @keypress="onlyNumber"
                          @paste="onPasteNumber"
                        ></v-text-field>
                        <v-select
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.hr.salesman.deployment
                          "
                          :disabled="disabled"
                          class="mx-2 mb-0"
                          :items="Object.values(session.zonesConfig)"
                          item-text="name"
                          item-value="uuid"
                          v-model="cv.zone"
                          label="Zone"
                        ></v-select>
                        <span
                          class="pa-2 mt-5 d-flex flex-row flex-wrap align-center justify-center"
                        >
                          <v-btn
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.training
                            "
                            :disabled="disabled"
                            class="mr-3 mt-2"
                            color="accent"
                            @click="cancelTraining(cv)"
                            v-if="cv.training"
                            outlined
                            >Annuler la formation</v-btn
                          >
                          <v-btn
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.training
                            "
                            :disabled="disabled"
                            class="mr-3 mt-2"
                            color="primary"
                            @click="openTrainingDialog(cv)"
                            v-else
                            outlined
                            >Former</v-btn
                          >
                          <v-btn
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.salesman.firing
                            "
                            :disabled="disabled"
                            class="mt-2"
                            color="accent"
                            outlined
                            @click="fireCv(cv)"
                            v-if="
                              !decision.hr.salesmen.firing.includes(cv.uuid)
                            "
                            >Licencier</v-btn
                          >
                          <v-btn
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.salesman.firing
                            "
                            :disabled="disabled"
                            class="mt-2"
                            color="secondary"
                            outlined
                            @click="cancelFiringCv(cv)"
                            v-else
                            >Annuler licenciement</v-btn
                          >
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                    v-for="cv in decision.hr.salesmen.hiring"
                  >
                    <v-card>
                      <v-card-title
                        class="d-flex align-center justify-center"
                        style="width: 100%"
                      >
                        <h3
                          class="text-center"
                          v-text="cv.lastname + ' ' + cv.firstname"
                        ></h3>
                      </v-card-title>
                      <v-card-text>
                        <v-divider></v-divider>
                        <span
                          class="d-flex align-center justify-center py-3"
                          style="width: 100%"
                        >
                          <v-avatar
                            size="100"
                            color="white"
                            rounded="0"
                            class="mr-3"
                          >
                            <v-img
                              contain
                              height="100"
                              width="100"
                              :src="
                                require(`@/assets/images/icons/hr/Salesman.svg`)
                              "
                            ></v-img>
                          </v-avatar>
                        </span>
                        <v-divider></v-divider>
                        <v-row class="py-5">
                          <v-col cols="12" md="6" class="py-0">
                            <span
                              class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                            >
                              <b>Coût actuel :&nbsp;</b>
                              <span
                                v-text="
                                  isNaN(parseFloat(cv.socialIndex))
                                    ? 'indisponible'
                                    : dataFormater.formatMoney(
                                        parseFloat(cv.baseSalary) *
                                          (parseFloat(cv.socialIndex) / 100)
                                      )
                                "
                              ></span>
                            </span>
                          </v-col>
                        </v-row>
                        <v-text-field
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.hr.salary
                          "
                          :disabled="disabled"
                          class="mx-2 mt-5 mb-0"
                          label="Indice social"
                          suffix="%"
                          :value="formatNumber(cv.socialIndex)"
                          :error="errorRules.salesmen.socialIndex[cv.uuid]"
                          @input="
                            (val) => {
                              cv.socialIndex = parseNumber(val);
                              onInputChange();
                            }
                          "
                          @keypress="onlyWholeNumber"
                          @paste="onPasteWholeNumber"
                        ></v-text-field>
                        <v-text-field
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.hr.salesman.commission
                          "
                          :disabled="disabled"
                          class="mx-2 mb-0"
                          label="Commission"
                          suffix="%"
                          :value="formatDecimal(cv.commission)"
                          :error="errorRules.salesmen.commission[cv.uuid]"
                          @input="
                            (val) => {
                              cv.commission = parseDecimal(val);
                              onInputChange();
                            }
                          "
                          @blur="onBlurField($event, cv, 'commission')"
                          @keypress="onlyNumber"
                          @paste="onPasteNumber"
                        ></v-text-field>
                        <v-select
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.hr.salesman.deployment
                          "
                          :disabled="disabled"
                          class="mx-2 mb-0"
                          :items="Object.values(session.zonesConfig)"
                          item-text="name"
                          item-value="uuid"
                          v-model="cv.zone"
                          label="Zone"
                        ></v-select>
                        <span
                          class="pa-2 mt-5 d-flex flex-row flex-wrap align-center justify-center"
                        >
                          <v-btn
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.training
                            "
                            :disabled="disabled"
                            class="mr-3 mt-2"
                            color="accent"
                            @click="cancelTraining(cv)"
                            v-if="cv.training"
                            outlined
                            >Annuler la formation</v-btn
                          >
                          <v-btn
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.training
                            "
                            :disabled="disabled"
                            class="mr-3 mt-2"
                            color="primary"
                            @click="openTrainingDialog(cv)"
                            v-else
                            outlined
                            >Former</v-btn
                          >
                          <v-btn
                            v-show="
                              profile.role != 'User' ||
                              session.decisionParameters.hr.salesman.hiring
                            "
                            :disabled="disabled"
                            class="mt-2"
                            color="accent"
                            outlined
                            @click="cancelHiring(cv)"
                            >Annuler l'embauche</v-btn
                          >
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-dialog v-model="hiringDialog" persistent max-width="1200px">
        <v-card>
          <v-card-title primary-title class="justify-space-between">
            <span>Embaucher un responsable</span>
            <v-btn icon @click="hiringDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-select
              :items="jobsList"
              item-text="text"
              item-value="value"
              v-model="selectedJob"
              label="Poste recherché"
            ></v-select>
            <h3 v-if="selectedJob" class="my-3">Candidats disponibles :</h3>
            <v-row v-if="selectedJob">
              <v-col v-if="availableCVs[selectedJob].length == 0">
                <h2>Aucun candidat pour le moment !</h2>
              </v-col>
              <v-col
                cols="12"
                md="6"
                xl="4"
                v-else
                v-for="cv in availableCVs[selectedJob]"
              >
                <v-card
                  outlined
                  class="d-flex flex-column align-center justify-center"
                >
                  <v-avatar
                    size="80"
                    color="white"
                    rounded="0"
                    class="mr-3"
                    v-if="selectedJob == 'salesmen'"
                  >
                    <v-img
                      contain
                      height="80"
                      width="80"
                      :src="require(`@/assets/images/icons/hr/Salesman.svg`)"
                    ></v-img>
                  </v-avatar>
                  <v-avatar
                    size="80"
                    color="white"
                    rounded="0"
                    class="mr-3"
                    v-else-if="selectedJob == 'productManagers'"
                  >
                    <v-img
                      contain
                      height="80"
                      width="80"
                      :src="
                        require(`@/assets/images/icons/hr/ProductManager.svg`)
                      "
                    ></v-img>
                  </v-avatar>
                  <v-avatar
                    size="80"
                    color="white"
                    rounded="0"
                    class="mr-3"
                    v-else
                  >
                    <v-img
                      contain
                      height="80"
                      width="80"
                      :src="
                        require(`@/assets/images/icons/hr/ServiceManager.svg`)
                      "
                    ></v-img>
                  </v-avatar>
                  <p>
                    <b v-text="cv.lastname"></b>
                    <span v-text="cv.firstname"></span>
                  </p>
                  <p>
                    Coût de base :
                    <b
                      v-text="
                        dataFormater.formatMoney(parseFloat(cv.baseSalary))
                      "
                    ></b>
                  </p>
                  <p v-if="selectedJob != 'Salesman'">
                    Capacité de prise en charge :
                    <b
                      v-text="
                        cv.staffCapacity +
                        (selectedJob == 'ProductManager'
                          ? '  ouvrier(s)'
                          : '  employé(s)')
                      "
                    ></b>
                  </p>
                  <span class="mb-3">
                    <v-btn
                      color="accent"
                      outlined
                      class="ma-2"
                      v-if="cv.fileId"
                      @click="openCV(cv)"
                      >Voir son CV</v-btn
                    >
                    <v-btn
                      color="secondary"
                      outlined
                      class="ma-2"
                      disabled
                      v-else
                      >CV indisponnible</v-btn
                    >
                    <v-btn
                      color="primary"
                      outlined
                      class="ma-2"
                      @click="hireCv(cv)"
                      >Embaucher</v-btn
                    >
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="trainingDialog" persistent max-width="1200px">
        <v-card v-if="selectedCv">
          <v-card-title primary-title class="justify-space-between">
            <span
              v-text="
                'Former ' +
                selectedCv.lastname +
                ' ' +
                selectedCv.firstname +
                ' (' +
                (selectedCv.job == 'Salesman'
                  ? 'Commercial'
                  : selectedCv.job == 'ProductManager'
                  ? 'Chef de production'
                  : 'Manager') +
                ')'
              "
            ></span>
            <v-btn icon @click="trainingDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="trainings[selectedCv.job].length == 0">
                <h2>Aucune formation disponibles pour le moment !</h2>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="4"
                v-else
                v-for="training in trainings[selectedCv.job]"
              >
                <v-card>
                  <v-card-title
                    class="d-flex flex-column align-center justify-center"
                  >
                    <h2 v-text="training.name"></h2>
                  </v-card-title>
                  <v-card-text
                    class="d-flex flex-column align-center justify-center"
                  >
                    <v-avatar
                      size="100"
                      color="white"
                      rounded="0"
                      class="mr-3"
                      v-if="training.job == 'Salesman'"
                    >
                      <v-img
                        contain
                        height="100"
                        width="100"
                        :src="require(`@/assets/images/icons/hr/Salesman.svg`)"
                      ></v-img>
                    </v-avatar>
                    <v-avatar
                      size="100"
                      color="white"
                      rounded="0"
                      class="mr-3"
                      v-else-if="training.job == 'ProductManagers'"
                    >
                      <v-img
                        contain
                        height="100"
                        width="100"
                        :src="
                          require(`@/assets/images/icons/hr/ProductManager.svg`)
                        "
                      ></v-img>
                    </v-avatar>
                    <v-avatar
                      size="100"
                      color="white"
                      rounded="0"
                      class="mr-3"
                      v-else
                    >
                      <v-img
                        contain
                        height="100"
                        width="100"
                        :src="
                          require(`@/assets/images/icons/hr/ServiceManager.svg`)
                        "
                      ></v-img>
                    </v-avatar>
                    <p class="mt-5">
                      Coût de la formation :
                      <b
                        v-text="
                          dataFormater.formatMoney(parseFloat(training.cost))
                        "
                      ></b>
                    </p>
                    <p>
                      Durée de la formation :
                      <b v-text="parseFloat(training.duration) + ' Jours'"></b>
                    </p>
                    <p>
                      Augmentation salariale :
                      <b v-text="parseFloat(training.salaryIncrease) + '%'"></b>
                    </p>
                    <p v-if="training.job != 'Salesman'">
                      Augmentation de capacité :
                      <b
                        v-text="parseFloat(training.staffCapacityIncrease)"
                      ></b>
                    </p>
                    <span
                      class="mb-3"
                      v-if="selectedCv.trainingReceived.includes(training.uuid)"
                    >
                      <v-btn color="secondary" disabled outlined class="ma-2"
                        >Déjà formé</v-btn
                      >
                    </span>
                    <span class="mb-3" v-else>
                      <v-btn
                        color="primary"
                        outlined
                        class="ma-2"
                        @click="startTraining(selectedCv, training)"
                        >Former</v-btn
                      >
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </span>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "DecisionHR",
  props: [
    "disabled",
    "profile",
    "user",
    "session",
    "state",
    "decision",
    "periods",
  ],
  setup() {
    return {
      storage: getStorage(),
      dataFormater,
    };
  },
  data() {
    return {
      hiringDialog: false,
      trainingDialog: false,
      selectedCv: null,
      selectedJob: null,
      jobsList: [
        {
          text: "Vendeur",
          value: "salesmen",
        },
        {
          text: "Chef de production",
          value: "productManagers",
        },
        {
          text: "Manager",
          value: "serviceManagers",
        },
      ],
      selectedTrining: null,
      trainings: {},
      allCVs: null,
      jobPanels: [0, 1, 2],
      errorMessages: [],
      errorRules: {
        socialBudget: false,
        workers: {
          socialIndex: false,
          hiringAmount: false,
          firingAmount: false,
        },
        employees: {
          socialIndex: false,
          hiringAmount: false,
          firingAmount: false,
        },
        productManagers: {
          socialIndex: [],
        },
        serviceManagers: {
          socialIndex: [],
        },
        salesmen: {
          socialIndex: [],
          commission: [],
        },
      },
    };
  },
  mounted() {
    // this.allCVs = {
    //   Salesman: Object.values(this.session.cvConfig).filter(
    //     (cv) => cv.job == "Salesman"
    //   ),
    //   ProductManager: Object.values(this.session.cvConfig).filter(
    //     (cv) => cv.job == "ProductManager"
    //   ),
    //   ServiceManager: Object.values(this.session.cvConfig).filter(
    //     (cv) => cv.job == "ServiceManager"
    //   ),
    // };

    // fix the salesman error
    if (this.session && this.session.cvConfig) {
      this.allCVs = {
        Salesman: Object.values(this.session.cvConfig).filter(
          (cv) => cv.job == "Salesman"
        ),
        ProductManager: Object.values(this.session.cvConfig).filter(
          (cv) => cv.job == "ProductManager"
        ),
        ServiceManager: Object.values(this.session.cvConfig).filter(
          (cv) => cv.job == "ServiceManager"
        ),
      };
    } else {
      this.allCVs = {
        Salesman: [],
        ProductManager: [],
        ServiceManager: [],
      };
    }

    // this.trainings = {
    //   Salesman: Object.values(this.session.formationsConfig).filter(
    //     (training) => training.job == "Salesman"
    //   ),
    //   ProductManager: Object.values(this.session.formationsConfig).filter(
    //     (training) => training.job == "ProductManager"
    //   ),
    //   ServiceManager: Object.values(this.session.formationsConfig).filter(
    //     (training) => training.job == "ServiceManager"
    //   ),
    // };

    // fix the salesman error
    if (this.session && this.session.formationsConfig) {
      this.trainings = {
        Salesman: Object.values(this.session.formationsConfig).filter(
          (training) => training.job == "Salesman"
        ),
        ProductManager: Object.values(this.session.formationsConfig).filter(
          (training) => training.job == "ProductManager"
        ),
        ServiceManager: Object.values(this.session.formationsConfig).filter(
          (training) => training.job == "ServiceManager"
        ),
      };
    } else {
      this.trainings = {
        Salesman: [],
        ProductManager: [],
        ServiceManager: [],
      };
    }
    // console.log("SALESMAN", this.decision.hr.salesmen);

    // console.log("SALLES", this.session.decisionParameters.hr.salesman);
    this.checkErrors();
  },
  computed: {
    availableCVs() {
      let availableCvs = {
        salesmen: [],
        productManagers: [],
        serviceManagers: [],
      };

      let currentCvs = {
        salesmen: [],
        productManagers: [],
        serviceManagers: [],
      };

      // currentCvs.salesmen = this.decision.hr.salesmen.config
      //   .map((cv) => cv.uuid)
      //   .concat(this.decision.hr.salesmen.hiring.map((cv) => cv.uuid));
      if (
        this.decision &&
        this.decision.hr &&
        this.decision.hr.salesmen &&
        this.decision.hr.salesmen.config &&
        this.decision.hr.salesmen.hiring
      ) {
        currentCvs.salesmen = this.decision.hr.salesmen.config
          .map((cv) => cv.uuid)
          .concat(this.decision.hr.salesmen.hiring.map((cv) => cv.uuid));
      }

      // currentCvs.productManagers = this.decision.hr.productManagers.config
      //   .map((cv) => cv.uuid)
      //   .concat(this.decision.hr.productManagers.hiring.map((cv) => cv.uuid));
      if (
        this.decision &&
        this.decision.hr &&
        this.decision.hr.productManagers &&
        this.decision.hr.productManagers.config &&
        this.decision.hr.productManagers.hiring
      ) {
        currentCvs.productManagers = this.decision.hr.productManagers.config
          .map((cv) => cv.uuid)
          .concat(this.decision.hr.productManagers.hiring.map((cv) => cv.uuid));
      }

      // currentCvs.serviceManagers = this.decision.hr.serviceManagers.config
      //   .map((cv) => cv.uuid)
      //   .concat(this.decision.hr.serviceManagers.hiring.map((cv) => cv.uuid));
      if (
        this.decision &&
        this.decision.hr &&
        this.decision.hr.serviceManagers &&
        this.decision.hr.serviceManagers.config &&
        this.decision.hr.serviceManagers.hiring
      ) {
        currentCvs.serviceManagers = this.decision.hr.serviceManagers.config
          .map((cv) => cv.uuid)
          .concat(this.decision.hr.serviceManagers.hiring.map((cv) => cv.uuid));
      }

      // availableCvs.salesmen = this.allCVs.Salesman.filter(
      //   (cv) => !currentCvs.salesmen.includes(cv.uuid)
      // );
      // availableCvs.productManagers = this.allCVs.ProductManager.filter(
      //   (cv) => !currentCvs.productManagers.includes(cv.uuid)
      // );
      // availableCvs.serviceManagers = this.allCVs.ServiceManager.filter(
      //   (cv) => !currentCvs.serviceManagers.includes(cv.uuid)
      // );

      if (this.allCVs) {
        if (this.allCVs.Salesman) {
          availableCvs.salesmen = this.allCVs.Salesman.filter(
            (cv) => !currentCvs.salesmen.includes(cv.uuid)
          );
        }
        if (this.allCVs.ProductManager) {
          availableCvs.productManagers = this.allCVs.ProductManager.filter(
            (cv) => !currentCvs.productManagers.includes(cv.uuid)
          );
        }
        if (this.allCVs.ServiceManager) {
          availableCvs.serviceManagers = this.allCVs.ServiceManager.filter(
            (cv) => !currentCvs.serviceManagers.includes(cv.uuid)
          );
        }
      }

      return availableCvs;
    },
    salarialCost() {
      let workerCost =
        ((parseInt(this.state.hr.workerAmount) +
          parseInt(this.decision.hr.workers.hiringAmount) -
          parseInt(this.decision.hr.workers.firingAmount)) *
          parseFloat(this.session.hrParameters.worker.baseSalary) *
          parseFloat(this.decision.hr.workers.socialIndex)) /
        100;
      let employeeCost =
        ((parseInt(this.state.hr.employeeAmount) +
          parseInt(this.decision.hr.employees.hiringAmount) -
          parseInt(this.decision.hr.employees.firingAmount)) *
          parseFloat(this.session.hrParameters.employee.baseSalary) *
          parseFloat(this.decision.hr.employees.socialIndex)) /
        100;

      let productManagerCost = 0;
      let serviceManagerCost = 0;
      let salesmanCost = 0;

      this.decision.hr.productManagers.config.forEach((cv) => {
        productManagerCost +=
          (parseFloat(cv.baseSalary) * parseFloat(cv.socialIndex)) / 100;
      });
      this.decision.hr.productManagers.hiring.forEach((cv) => {
        productManagerCost +=
          (parseFloat(cv.baseSalary) * parseFloat(cv.socialIndex)) / 100;
      });

      this.decision.hr.serviceManagers.config.forEach((cv) => {
        serviceManagerCost +=
          (parseFloat(cv.baseSalary) * parseFloat(cv.socialIndex)) / 100;
      });
      this.decision.hr.serviceManagers.hiring.forEach((cv) => {
        serviceManagerCost +=
          (parseFloat(cv.baseSalary) * parseFloat(cv.socialIndex)) / 100;
      });

      this.decision.hr.salesmen.config.forEach((cv) => {
        salesmanCost +=
          (parseFloat(cv.baseSalary) * parseFloat(cv.socialIndex)) / 100;
      });
      this.decision.hr.salesmen.hiring.forEach((cv) => {
        salesmanCost +=
          (parseFloat(cv.baseSalary) * parseFloat(cv.socialIndex)) / 100;
      });

      return (
        workerCost +
        employeeCost +
        productManagerCost +
        serviceManagerCost +
        salesmanCost
      );
    },
    trainingCost() {
      let productManagerCost = 0;
      let serviceManagerCost = 0;
      let salesmanCost = 0;

      this.decision.hr.productManagers.config.forEach((cv) => {
        if (cv.training && cv.training.uuid && cv.training.cost) {
          productManagerCost += parseFloat(cv.training.cost);
        }
      });
      this.decision.hr.productManagers.hiring.forEach((cv) => {
        if (cv.training && cv.training.uuid && cv.training.cost) {
          productManagerCost += parseFloat(cv.training.cost);
        }
      });

      this.decision.hr.serviceManagers.config.forEach((cv) => {
        if (cv.training && cv.training.uuid && cv.training.cost) {
          serviceManagerCost += parseFloat(cv.training.cost);
        }
      });
      this.decision.hr.serviceManagers.hiring.forEach((cv) => {
        if (cv.training && cv.training.uuid && cv.training.cost) {
          serviceManagerCost += parseFloat(cv.training.cost);
        }
      });

      this.decision.hr.salesmen.config.forEach((cv) => {
        if (cv.training && cv.training.uuid && cv.training.cost) {
          salesmanCost += parseFloat(cv.training.cost);
        }
      });
      this.decision.hr.salesmen.hiring.forEach((cv) => {
        if (cv.training && cv.training.uuid && cv.training.cost) {
          salesmanCost += parseFloat(cv.training.cost);
        }
      });

      return productManagerCost + serviceManagerCost + salesmanCost;
    },
    otherCost() {
      let hiringCharges =
        parseFloat(this.session.hrParameters.common.hiringCharges) / 100;
      let firingCharges =
        parseFloat(this.session.hrParameters.common.firingCharges) / 100;

      let workerCost =
        parseInt(this.decision.hr.workers.hiringAmount) *
          parseFloat(this.session.hrParameters.worker.baseSalary) *
          hiringCharges +
        parseInt(this.decision.hr.workers.firingAmount) *
          parseFloat(this.session.hrParameters.worker.baseSalary) *
          firingCharges;
      let employeeCost =
        parseInt(this.decision.hr.employees.hiringAmount) *
          parseFloat(this.session.hrParameters.employee.baseSalary) *
          hiringCharges +
        parseInt(this.decision.hr.employees.firingAmount) *
          parseFloat(this.session.hrParameters.employee.baseSalary) *
          firingCharges;

      let productManagerCost = 0;
      let serviceManagerCost = 0;
      let salesmanCost = 0;

      this.decision.hr.productManagers.firing.forEach((cv) => {
        productManagerCost +=
          parseFloat(cv.baseSalary) * parseFloat(firingCharges);
      });
      this.decision.hr.productManagers.hiring.forEach((cv) => {
        productManagerCost +=
          parseFloat(cv.baseSalary) * parseFloat(hiringCharges);
      });

      this.decision.hr.serviceManagers.firing.forEach((cv) => {
        serviceManagerCost +=
          parseFloat(cv.baseSalary) * parseFloat(firingCharges);
      });
      this.decision.hr.serviceManagers.hiring.forEach((cv) => {
        serviceManagerCost +=
          parseFloat(cv.baseSalary) * parseFloat(hiringCharges);
      });

      this.decision.hr.salesmen.firing.forEach((cv) => {
        salesmanCost += parseFloat(cv.baseSalary) * parseFloat(firingCharges);
      });
      this.decision.hr.salesmen.hiring.forEach((cv) => {
        salesmanCost += parseFloat(cv.baseSalary) * parseFloat(hiringCharges);
      });

      return (
        workerCost +
        employeeCost +
        productManagerCost +
        serviceManagerCost +
        salesmanCost
      );
    },
    totalAmount() {
      let workerAmount =
        parseInt(this.state.hr.workerAmount) +
        parseInt(this.decision.hr.workers.hiringAmount) -
        parseInt(this.decision.hr.workers.firingAmount);
      let employeeAmount =
        parseInt(this.state.hr.employeeAmount) +
        parseInt(this.decision.hr.employees.hiringAmount) -
        parseInt(this.decision.hr.employees.firingAmount);
      let productManagerAmount =
        this.decision.hr.productManagers.config.length +
        this.decision.hr.productManagers.hiring.length -
        this.decision.hr.productManagers.firing.length;
      let serviceManagerAmount =
        this.decision.hr.serviceManagers.config.length +
        this.decision.hr.serviceManagers.hiring.length -
        this.decision.hr.serviceManagers.firing.length;
      let salesmanAmount =
        this.decision.hr.salesmen.config.length +
        this.decision.hr.salesmen.hiring.length -
        this.decision.hr.salesmen.firing.length;

      return (
        workerAmount +
        employeeAmount +
        productManagerAmount +
        serviceManagerAmount +
        salesmanAmount
      );
    },
  },

  methods: {
    onDecisionUpdate() {
      this.$emit("decisionUpdate");
    },
    onInputChange() {
      this.checkErrors();
    },
    formatNumber(value) {
      if (value !== null && value !== undefined && value !== "") {
        let numberValue = parseInt(value, 10);
        if (isNaN(numberValue)) {
          numberValue = 0;
        }
        // Format number to French format
        return numberValue.toLocaleString("fr-FR");
      } else {
        return "";
      }
    },
    parseNumber(val) {
      // Remove spaces and non-digit characters
      let cleanedVal = val.replace(/\s/g, "").replace(/\D/g, "");
      let numberValue = parseInt(cleanedVal, 10);
      if (isNaN(numberValue)) {
        numberValue = 0;
      }
      return numberValue;
    },
    onlyWholeNumber(event) {
      const char = event.key;
      // Allow only whole numbers
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    },
    onPasteWholeNumber(event) {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      let [integerPart] = paste.split(",");
      let cleanedVal = integerPart.replace(/\D/g, "");

      event.preventDefault();

      let input = event.target;

      // Get cursor position
      let start = input.selectionStart;
      let end = input.selectionEnd;

      // Get the current value
      let value = input.value;

      input.value =
        value.substring(0, start) + cleanedVal + value.substring(end);

      let newPos = start + cleanedVal.length;
      input.setSelectionRange(newPos, newPos);

      input.dispatchEvent(new Event("input"));
    },
    onlyNumber(event) {
      const char = event.key;
      const allowedChars = "0123456789,.";
      // Disallow any character not in allowedChars
      if (!allowedChars.includes(char)) {
        event.preventDefault();
        return;
      }
      // Get the current value and cursor position
      let value = event.target.value;
      let cursorPosition = event.target.selectionStart;

      // Replace dot (.) with comma (,)
      if (char === ".") {
        event.preventDefault();
        // Insert the comma at the cursor position
        event.target.value =
          value.substring(0, cursorPosition) +
          "," +
          value.substring(cursorPosition);
        // Set the cursor position after the comma
        event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        return;
      }

      // If comma is already present, prevent additional commas
      if (char === "," && value.includes(",")) {
        event.preventDefault();
        return;
      }
      // If already two digits after comma, prevent more input
      if (value.includes(",")) {
        let commaIndex = value.indexOf(",");
        let decimals = value.substring(commaIndex + 1);
        if (decimals.length >= 2 && cursorPosition > commaIndex) {
          event.preventDefault();
          return;
        }
      }
    },
    onPasteNumber(event) {
      // Get pasted data via clipboard API
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      // Clean the pasted data
      let cleanedVal = paste.replace(/[^\d,\.]/g, ""); // Keep digits, comma, dot
      // Replace dot with comma
      cleanedVal = cleanedVal.replace(".", ",");
      // Ensure only one comma
      let parts = cleanedVal.split(",");
      if (parts.length > 2) {
        cleanedVal = parts[0] + "," + parts.slice(1).join("");
      }
      // Limit decimals to two digits
      if (parts.length === 2) {
        parts[1] = parts[1].substring(0, 2);
        cleanedVal = parts[0] + "," + parts[1];
      }
      // Prevent the default paste behavior
      event.preventDefault();
      // Insert the cleaned value into the input
      let input = event.target;
      // Get cursor position
      let start = input.selectionStart;
      let end = input.selectionEnd;
      // Get the current value
      let value = input.value;
      // Insert the cleaned value at the cursor position
      input.value =
        value.substring(0, start) + cleanedVal + value.substring(end);
      // Move the cursor to the end of the inserted text
      let newPos = start + cleanedVal.length;
      input.setSelectionRange(newPos, newPos);
      // Trigger input event to update the data binding
      input.dispatchEvent(new Event("input"));
    },

    onBlurField(event, obj, prop) {
      let val = event.target.value;
      let parsedValue = this.parseDecimal(val);

      let hasDecimal = val.includes(",") || val.includes(".");

      let formattedValue = this.formatDecimal(parsedValue, hasDecimal);

      this.$set(obj, prop, parsedValue);

      event.target.value = formattedValue;
    },

    // formatDecimal(value) {
    //   if (value !== null && value !== undefined && value !== "") {
    //     let numberValue = parseFloat(value);
    //     if (isNaN(numberValue)) {
    //       numberValue = 0;
    //     }

    //     // Round the number to two decimal places
    //     numberValue = Math.round(numberValue * 100) / 100;

    //     let decimalPart = numberValue.toString().split(".")[1];

    //     let options = {
    //       minimumFractionDigits: 0,
    //       maximumFractionDigits: 2,
    //     };

    //     if (decimalPart) {
    //       if (decimalPart.length === 1) {
    //         options.minimumFractionDigits = 2;
    //         options.maximumFractionDigits = 2;
    //       } else {
    //         options.minimumFractionDigits = decimalPart.length;
    //         options.maximumFractionDigits = decimalPart.length;
    //       }
    //     }

    //     // Format the number using French locale
    //     return numberValue.toLocaleString("fr-FR", options);
    //   } else {
    //     return "";
    //   }
    // },
    formatDecimal(value, forceTwoDecimals = false) {
      if (value !== null && value !== undefined && value !== "") {
        let numberValue = parseFloat(value);
        if (isNaN(numberValue)) {
          numberValue = 0;
        }

        let options = {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        };

        if (forceTwoDecimals) {
          // If the number is not an integer, enforce two decimal places
          if (!Number.isInteger(numberValue)) {
            options.minimumFractionDigits = 2;
          } else {
            // For whole numbers, no decimal places
            options.minimumFractionDigits = 0;
            options.maximumFractionDigits = 0;
          }
        } else {
          let decimalPart = numberValue.toString().split(".")[1];
          if (decimalPart) {
            options.minimumFractionDigits = decimalPart.length;
            options.maximumFractionDigits = decimalPart.length;
          }
        }

        // Format the number using French locale
        return numberValue.toLocaleString("fr-FR", options);
      } else {
        return "";
      }
    },
    parseDecimal(val) {
      // Remove spaces and non-digit characters except comma and dot
      let cleanedVal = val.replace(/\s/g, "").replace(/[^0-9,.-]/g, "");
      // Replace comma with dot for decimal
      cleanedVal = cleanedVal.replace(",", ".");
      let numberValue = parseFloat(cleanedVal);
      if (isNaN(numberValue)) {
        numberValue = 0;
      }
      return numberValue;
    },
    async openCV(cv) {
      let cvFile = await getDownloadURL(ref(this.storage, cv.fileId));
      window.open(cvFile);
    },
    hireCv(cv) {
      let tmp_cv = { ...cv };
      tmp_cv.socialIndex = 100;
      switch (cv.job) {
        case "Salesman":
          tmp_cv.commission = 0;
          tmp_cv.zone = null;
          this.decision.hr.salesmen.hiring.push(tmp_cv);
          break;
        case "ProductManager":
          this.decision.hr.productManagers.hiring.push(tmp_cv);
          break;
        case "ServiceManager":
          this.decision.hr.serviceManagers.hiring.push(tmp_cv);
          break;
      }
      this.hiringDialog = false;
      this.onDecisionUpdate();
    },
    cancelHiring(cv) {
      switch (cv.job) {
        case "Salesman":
          this.decision.hr.salesmen.hiring =
            this.decision.hr.salesmen.hiring.filter(
              (oneCv) => oneCv.uuid != cv.uuid
            );
          break;
        case "ProductManager":
          this.decision.hr.productManagers.hiring =
            this.decision.hr.productManagers.hiring.filter(
              (oneCv) => oneCv.uuid != cv.uuid
            );
          break;
        case "ServiceManager":
          this.decision.hr.serviceManagers.hiring =
            this.decision.hr.serviceManagers.hiring.filter(
              (oneCv) => oneCv.uuid != cv.uuid
            );
          break;
      }
      this.onDecisionUpdate();
    },
    fireCv(cv) {
      switch (cv.job) {
        case "Salesman":
          this.decision.hr.salesmen.firing.push(cv.uuid);
          break;
        case "ProductManager":
          this.decision.hr.productManagers.firing.push(cv.uuid);
          break;
        case "ServiceManager":
          this.decision.hr.serviceManagers.firing.push(cv.uuid);
          break;
      }
      this.onDecisionUpdate();
    },
    cancelFiringCv(cv) {
      switch (cv.job) {
        case "Salesman":
          this.decision.hr.salesmen.firing =
            this.decision.hr.salesmen.firing.filter(
              (oneCv) => oneCv != cv.uuid
            );
          break;
        case "ProductManager":
          this.decision.hr.productManagers.firing =
            this.decision.hr.productManagers.firing.filter(
              (oneCv) => oneCv != cv.uuid
            );
          break;
        case "ServiceManager":
          this.decision.hr.serviceManagers.firing =
            this.decision.hr.serviceManagers.firing.filter(
              (oneCv) => oneCv != cv.uuid
            );
          break;
      }
      this.onDecisionUpdate();
    },
    openTrainingDialog(cv) {
      this.selectedCv = cv;
      this.trainingDialog = true;
    },
    startTraining(cv, training) {
      if (cv.job == "ProductManager" || cv.job == "ServiceManager") {
        cv.staffCapacity =
          parseFloat(cv.staffCapacity) +
          parseFloat(training.staffCapacityIncrease);
        cv.productivityBoost =
          parseFloat(cv.productivityBoost) +
          (parseFloat(training.efficiencyIncrease) / 100) *
            parseFloat(cv.productivityBoost);
      } else {
        cv.sellingEfficiency =
          parseFloat(cv.sellingEfficiency) +
          (parseFloat(training.efficiencyIncrease) / 100) *
            parseFloat(cv.sellingEfficiency);
      }
      cv.socialIndex =
        parseFloat(cv.socialIndex) + parseFloat(training.salaryIncrease);
      cv.training = training;
      this.trainingDialog = false;
      this.$forceUpdate();
      this.onDecisionUpdate();
    },
    cancelTraining(cv) {
      if (cv.job == "ProductManager" || cv.job == "ServiceManager") {
        cv.staffCapacity =
          parseFloat(cv.staffCapacity) -
          parseFloat(cv.training.staffCapacityIncrease);
        cv.productivityBoost =
          (parseFloat(cv.productivityBoost) * 100) /
          (parseFloat(cv.training.efficiencyIncrease) + 100);
      } else {
        cv.sellingEfficiency =
          (parseFloat(cv.sellingEfficiency) * 100) /
          (parseFloat(cv.training.efficiencyIncrease) + 100);
      }
      cv.socialIndex =
        parseFloat(cv.socialIndex) - parseFloat(cv.training.salaryIncrease);
      cv.training = null;
      this.$forceUpdate();
      this.onDecisionUpdate();
    },
    checkErrors() {
      let tmp_errorMessages = [];
      let tmp_rules = {
        socialBudget: false,
        workers: {
          socialIndex: false,
          hiringAmount: false,
          firingAmount: false,
        },
        employees: {
          socialIndex: false,
          hiringAmount: false,
          firingAmount: false,
        },
        productManagers: {
          socialIndex: [],
        },
        serviceManagers: {
          socialIndex: [],
        },
        salesmen: {
          socialIndex: [],
          commission: [],
        },
      };
      let errorStatus = false;

      if (isNaN(parseFloat(this.decision.hr.socialBudget))) {
        tmp_errorMessages.push("Le budget social doit être un nombre");
        errorStatus = true;
        tmp_rules.socialBudget = true;
      } else if (parseFloat(this.decision.hr.socialBudget) < 0) {
        tmp_errorMessages.push(
          "Le budget social doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.socialBudget = true;
      } else if (
        parseFloat(this.decision.hr.socialBudget) <
        parseFloat(this.session.hrParameters.common.minimalSocialBudget) *
          this.totalAmount
      ) {
        tmp_errorMessages.push(
          "Le budget social doit être supérieur ou egal a " +
            parseFloat(this.session.hrParameters.common.minimalSocialBudget) *
              this.totalAmount +
            "€ (" +
            parseFloat(this.session.hrParameters.common.minimalSocialBudget) +
            "€ minimum par salarié)"
        );
        errorStatus = true;
        tmp_rules.socialBudget = true;
      } else if (
        parseFloat(this.decision.hr.socialBudget) >
        parseFloat(this.session.hrParameters.common.maximalSocialBudget) *
          this.totalAmount
      ) {
        tmp_errorMessages.push(
          "Le budget social doit être inférieur ou egal a " +
            parseFloat(this.session.hrParameters.common.maximalSocialBudget) *
              this.totalAmount +
            "€ (" +
            parseFloat(this.session.hrParameters.common.maximalSocialBudget) +
            "€ maximum par salarié)"
        );
        errorStatus = true;
        tmp_rules.socialBudget = true;
      }

      if (isNaN(parseFloat(this.decision.hr.workers.socialIndex))) {
        tmp_errorMessages.push(
          "L'indice social des ouvriers doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.workers.socialIndex = true;
      } else if (parseFloat(this.decision.hr.workers.socialIndex) < 100) {
        tmp_errorMessages.push(
          "L'indice social des ouvriers doit être supérieur ou egal à 100"
        );
        errorStatus = true;
        tmp_rules.workers.socialIndex = true;
      } else if (
        parseFloat(this.decision.hr.workers.socialIndex) <
        parseFloat(this.session.hrParameters.common.minimalSocialIndex)
      ) {
        tmp_errorMessages.push(
          "L'indice social des ouvriers doit être supérieur ou egal a " +
            parseFloat(this.session.hrParameters.common.minimalSocialIndex)
        );
        errorStatus = true;
        tmp_rules.workers.socialIndex = true;
      } else if (
        parseFloat(this.decision.hr.workers.socialIndex) >
        parseFloat(this.session.hrParameters.common.maximalSocialIndex)
      ) {
        tmp_errorMessages.push(
          "L'indice social des ouvriers doit être inférieur ou egal a " +
            parseFloat(this.session.hrParameters.common.maximalSocialIndex)
        );
        errorStatus = true;
        tmp_rules.workers.socialIndex = true;
      }

      if (isNaN(parseFloat(this.decision.hr.workers.hiringAmount))) {
        tmp_errorMessages.push(
          "Le nombre d'embauche des ouvriers doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.workers.hiringAmount = true;
      } else if (parseFloat(this.decision.hr.workers.hiringAmount) < 0) {
        tmp_errorMessages.push(
          "Le nombre d'embauche des ouvriers doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.workers.hiringAmount = true;
      }

      if (isNaN(parseFloat(this.decision.hr.workers.firingAmount))) {
        tmp_errorMessages.push(
          "Le nombre de licenciement des ouvriers doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.workers.firingAmount = true;
      } else if (parseFloat(this.decision.hr.workers.firingAmount) < 0) {
        tmp_errorMessages.push(
          "Le nombre de licenciement des ouvriers doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.workers.firingAmount = true;
      } else if (
        parseFloat(this.decision.hr.workers.firingAmount) >
        parseFloat(this.state.hr.workerAmount)
      ) {
        tmp_errorMessages.push(
          "Le nombre de licenciement des ouvriers ne peut pas dépasser le nombre total d'ouvriers disponibles"
        );
        errorStatus = true;
        tmp_rules.workers.firingAmount = true;
      }

      if (isNaN(parseFloat(this.decision.hr.employees.socialIndex))) {
        tmp_errorMessages.push(
          "L'indice social des employés doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.employees.socialIndex = true;
      } else if (parseFloat(this.decision.hr.employees.socialIndex) < 100) {
        tmp_errorMessages.push(
          "L'indice social des employés doit être supérieur ou egal à 100"
        );
        errorStatus = true;
        tmp_rules.employees.socialIndex = true;
      } else if (
        parseFloat(this.decision.hr.employees.socialIndex) <
        parseFloat(this.session.hrParameters.common.minimalSocialIndex)
      ) {
        tmp_errorMessages.push(
          "L'indice social des employés doit être supérieur ou egal a " +
            parseFloat(this.session.hrParameters.common.minimalSocialIndex)
        );
        errorStatus = true;
        tmp_rules.employees.socialIndex = true;
      } else if (
        parseFloat(this.decision.hr.employees.socialIndex) >
        parseFloat(this.session.hrParameters.common.maximalSocialIndex)
      ) {
        tmp_errorMessages.push(
          "L'indice social des employés doit être inférieur ou egal a " +
            parseFloat(this.session.hrParameters.common.maximalSocialIndex)
        );
        errorStatus = true;
        tmp_rules.employees.socialIndex = true;
      }

      if (isNaN(parseFloat(this.decision.hr.employees.hiringAmount))) {
        tmp_errorMessages.push(
          "Le nombre d'embauche des employés doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.employees.hiringAmount = true;
      } else if (parseFloat(this.decision.hr.employees.hiringAmount) < 0) {
        tmp_errorMessages.push(
          "Le nombre d'embauche des employés doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.employees.hiringAmount = true;
      }

      if (isNaN(parseFloat(this.decision.hr.employees.firingAmount))) {
        tmp_errorMessages.push(
          "Le nombre de licenciement des employés doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.employees.firingAmount = true;
      } else if (parseFloat(this.decision.hr.employees.firingAmount) < 0) {
        tmp_errorMessages.push(
          "Le nombre de licenciement des employés doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.employees.firingAmount = true;
      } else if (
        parseFloat(this.decision.hr.employees.firingAmount) >
        parseFloat(this.state.hr.employeeAmount)
      ) {
        tmp_errorMessages.push(
          "Le nombre de licenciement des employés ne peut pas dépasser le nombre total d'employés disponibles"
        );
        errorStatus = true;
        tmp_rules.workers.firingAmount = true;
      }

      for (let cv of this.decision.hr.productManagers.config.concat(
        this.decision.hr.productManagers.hiring
      )) {
        if (isNaN(parseFloat(cv.socialIndex))) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.productManagers.socialIndex[cv.uuid] = true;
        } else if (parseFloat(cv.socialIndex) < 100) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal à 100"
          );
          errorStatus = true;
          tmp_rules.productManagers.socialIndex[cv.uuid] = true;
        } else if (
          parseFloat(cv.socialIndex) <
          parseFloat(this.session.hrParameters.common.minimalSocialIndex)
        ) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal a " +
              parseFloat(this.session.hrParameters.common.minimalSocialIndex)
          );
          errorStatus = true;
          tmp_rules.productManagers.socialIndex[cv.uuid] = true;
        } else if (
          parseFloat(cv.socialIndex) >
          parseFloat(this.session.hrParameters.common.maximalSocialIndex)
        ) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être inférieur ou egal a " +
              parseFloat(this.session.hrParameters.common.maximalSocialIndex)
          );
          errorStatus = true;
          tmp_rules.productManagers.socialIndex[cv.uuid] = true;
        } else if (
          cv.training &&
          cv.training.salaryIncrease &&
          parseFloat(cv.socialIndex) <
            100 + parseFloat(cv.training.salaryIncrease)
        ) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal a " +
              (100 + parseFloat(cv.training.salaryIncrease)) +
              " en raison de sa formation"
          );
          errorStatus = true;
          tmp_rules.productManagers.socialIndex[cv.uuid] = true;
        } else {
          tmp_rules.productManagers.socialIndex[cv.uuid] = false;
        }
      }

      for (let cv of this.decision.hr.serviceManagers.config.concat(
        this.decision.hr.serviceManagers.hiring
      )) {
        if (isNaN(parseFloat(cv.socialIndex))) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.serviceManagers.socialIndex[cv.uuid] = true;
        } else if (parseFloat(cv.socialIndex) < 100) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal à 100"
          );
          errorStatus = true;
          tmp_rules.serviceManagers.socialIndex[cv.uuid] = true;
        } else if (
          parseFloat(cv.socialIndex) <
          parseFloat(this.session.hrParameters.common.minimalSocialIndex)
        ) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal a " +
              parseFloat(this.session.hrParameters.common.minimalSocialIndex)
          );
          errorStatus = true;
          tmp_rules.serviceManagers.socialIndex[cv.uuid] = true;
        } else if (
          parseFloat(cv.socialIndex) >
          parseFloat(this.session.hrParameters.common.maximalSocialIndex)
        ) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être inférieur ou egal a " +
              parseFloat(this.session.hrParameters.common.maximalSocialIndex)
          );
          errorStatus = true;
          tmp_rules.serviceManagers.socialIndex[cv.uuid] = true;
        } else if (
          cv.training &&
          cv.training.salaryIncrease &&
          parseFloat(cv.socialIndex) <
            100 + parseFloat(cv.training.salaryIncrease)
        ) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal a " +
              (100 + parseFloat(cv.training.salaryIncrease)) +
              " en raison de sa formation"
          );
          errorStatus = true;
          tmp_rules.serviceManagers.socialIndex[cv.uuid] = true;
        } else {
          tmp_rules.serviceManagers.socialIndex[cv.uuid] = false;
        }
      }

      for (let cv of this.decision.hr.salesmen.config.concat(
        this.decision.hr.salesmen.hiring
      )) {
        if (isNaN(parseFloat(cv.socialIndex))) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.salesmen.socialIndex[cv.uuid] = true;
        } else if (parseFloat(cv.socialIndex) < 100) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal à 100"
          );
          errorStatus = true;
          tmp_rules.salesmen.socialIndex[cv.uuid] = true;
        } else if (
          parseFloat(cv.socialIndex) <
          parseFloat(this.session.hrParameters.common.minimalSocialIndex)
        ) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal a " +
              parseFloat(this.session.hrParameters.common.minimalSocialIndex)
          );
          errorStatus = true;
          tmp_rules.salesmen.socialIndex[cv.uuid] = true;
        } else if (
          parseFloat(cv.socialIndex) >
          parseFloat(this.session.hrParameters.common.maximalSocialIndex)
        ) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être inférieur ou egal a " +
              parseFloat(this.session.hrParameters.common.maximalSocialIndex)
          );
          errorStatus = true;
          tmp_rules.salesmen.socialIndex[cv.uuid] = true;
        } else if (
          cv.training &&
          cv.training.salaryIncrease &&
          parseFloat(cv.socialIndex) <
            100 + parseFloat(cv.training.salaryIncrease)
        ) {
          tmp_errorMessages.push(
            "L'indice social de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal a " +
              (100 + parseFloat(cv.training.salaryIncrease)) +
              " en raison de sa formation"
          );
          errorStatus = true;
          tmp_rules.salesmen.socialIndex[cv.uuid] = true;
        } else {
          tmp_rules.salesmen.socialIndex[cv.uuid] = false;
        }

        if (isNaN(parseFloat(cv.commission))) {
          tmp_errorMessages.push(
            "La commission de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.salesmen.commission[cv.uuid] = true;
        } else if (parseFloat(cv.commission) < 0) {
          tmp_errorMessages.push(
            "La commission de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être supérieur ou egal à 0"
          );
          errorStatus = true;
          tmp_rules.salesmen.commission[cv.uuid] = true;
        } else if (parseFloat(cv.commission) > 100) {
          tmp_errorMessages.push(
            "La commission de " +
              cv.lastname +
              " " +
              cv.firstname +
              " doit être inférieur ou égal à 100"
          );
          errorStatus = true;
          tmp_rules.salesmen.commission[cv.uuid] = true;
        } else {
          tmp_rules.salesmen.commission[cv.uuid] = false;
        }
      }

      this.errorMessages = tmp_errorMessages;
      this.errorRules = tmp_rules;
      this.$forceUpdate();
      return errorStatus;
    },
    getErrorMessages() {
      return this.errorMessages;
    },
  },
};
</script>
