import {
  getFirestore,
  doc,
  collection,
  query,
  where,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";

let db = getFirestore();
let collectionName = "decisions";

class Decision {
  constructor(
    id,
    period,
    team,
    status,
    finances,
    studies,
    machines,
    hr,
    production,
    sales,
    coeff,
    forecast,
    lastUpdatedBy
  ) {
    this.id = id;
    this.period = period;
    this.team = team;
    this.status = status;
    this.finances = finances;
    this.studies = studies;
    this.machines = machines;
    this.hr = hr;
    this.production = production;
    this.sales = sales;
    this.coeff = coeff;
    this.forecast = forecast;
    this.lastUpdatedBy = lastUpdatedBy || null;
  }

  static async initializeOne(periodId, teamID) {
    let tmp_status = {
      isReady: false,
      pagesFilled: {
        finances: false,
        studies: false,
        machines: false,
        hr: false,
        production: false,
        sales: false,
        forecast: false,
        coeffs: false,
      },
    };

    let tmp_financesDecision = {
      capitalIncrease: 0,
      discountPercentage: 0,
      newLoan: {
        duration: 0,
        amount: 0,
        rate: 0,
        adminRate: 0,
      },
      refunds: {},
    };

    let tmp_studiesDecision = {
      purchaseMarketResearch: false,
      purchaseCostResearch: false,
      purchaseHrResearch: false,
      purchaseAttractivityResearch: false,
      purchaseRseResearch: false,
    };

    let tmp_machinesDecision = {
      maintenanceBudget: 0,
      buying: [],
      selling: [],
      researching: [],
    };

    let tmp_hrDecision = {
      socialBudget: 0,
      workers: {
        hiringAmount: 0,
        firingAmount: 0,
        socialIndex: 100,
      },
      employees: {
        hiringAmount: 0,
        firingAmount: 0,
        socialIndex: 100,
      },
      salesmen: {
        hiring: [],
        firing: [],
        config: [],
      },
      serviceManagers: {
        hiring: [],
        firing: [],
        config: [],
      },
      productManagers: {
        hiring: [],
        firing: [],
        config: [],
      },
    };

    let tmp_productionDecision = {
      products: {
        production: {},
        researching: {},
      },
      services: {
        production: {},
        researching: {},
      },
    };

    let tmp_salesDecision = {};

    let tmp_coeff = {
      communication: 100,
      marketing: 100,
      salesForce: 100,
      productivity: 100,
      rawMaterialCost: 100,
      distributionCost: 100,
    };

    let tmp_forecast = {
      results: 0,
      treasury: 0,
      turnover: 0,
    };

    let tmp_decision = new Decision(
      null,
      periodId,
      teamID,
      tmp_status,
      tmp_financesDecision,
      tmp_studiesDecision,
      tmp_machinesDecision,
      tmp_hrDecision,
      tmp_productionDecision,
      tmp_salesDecision,
      tmp_coeff,
      tmp_forecast,
      null // lastUpdatedBy is null for new decisions
    );
    return tmp_decision;
  }

  static async getAll() {
    let collectionRef = collection(db, collectionName);
    let response = await getDocs(collectionRef);

    let decisions = [];

    response.forEach((doument) => {
      let data = doument.data();
      let tmp_decision = new Decision(
        doument.id,
        data.period,
        data.team,
        data.status,
        data.finances,
        data.studies,
        data.machines,
        data.hr,
        data.production,
        data.sales,
        data.coeff,
        data.forecast,
        data.lastUpdatedBy || null
      );
      decisions.push(tmp_decision);
    });

    return decisions;
  }

  static async getById(id) {
    let documentRef = doc(db, collectionName, id);
    let response = await getDoc(documentRef);

    let data = response.data();
    let decision = new Decision(
      response.id,
      data.period,
      data.team,
      data.status,
      data.finances,
      data.studies,
      data.machines,
      data.hr,
      data.production,
      data.sales,
      data.coeff,
      data.forecast,
      data.lastUpdatedBy || null
    );

    return decision;
  }

  static async getByPeriod(periodId) {
    let collectionRef = collection(db, collectionName);
    let documentQuery = query(collectionRef, where("period", "==", periodId));
    let response = await getDocs(documentQuery);

    let decisions = [];

    response.forEach((document) => {
      let data = document.data();
      let tmp_decision = new Decision(
        document.id,
        data.period,
        data.team,
        data.status,
        data.finances,
        data.studies,
        data.machines,
        data.hr,
        data.production,
        data.sales,
        data.coeff,
        data.forecast,
        data.lastUpdatedBy || null
      );
      decisions.push(tmp_decision);
    });

    return decisions;
  }

  static async getByTeam(teamId) {
    let collectionRef = collection(db, collectionName);
    let documentQuery = query(collectionRef, where("team", "==", teamId));
    let response = await getDocs(documentQuery);

    let decisions = [];

    response.forEach((document) => {
      let data = document.data();
      let tmp_decision = new Decision(
        document.id,
        data.period,
        data.team,
        data.status,
        data.finances,
        data.studies,
        data.machines,
        data.hr,
        data.production,
        data.sales,
        data.coeff,
        data.forecast,
        data.lastUpdatedBy || null
      );
      decisions.push(tmp_decision);
    });

    return decisions;
  }

  static async getByPeriodAndTeam(periodId, teamId) {
    let collectionRef = collection(db, collectionName);
    let documentQuery = query(
      collectionRef,
      where("period", "==", periodId),
      where("team", "==", teamId)
    );
    let response = await getDocs(documentQuery);

    let decisions = [];

    response.forEach((document) => {
      let data = document.data();
      let tmp_decision = new Decision(
        document.id,
        data.period,
        data.team,
        data.status,
        data.finances,
        data.studies,
        data.machines,
        data.hr,
        data.production,
        data.sales,
        data.coeff,
        data.forecast,
        data.lastUpdatedBy || null
      );
      decisions.push(tmp_decision);
    });

    return decisions;
  }

  static listenAll(callback = null) {
    let collectionRef = collection(db, collectionName);
    let unsub = onSnapshot(collectionRef, (snapshot) => {
      let decisions = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_decision = new Decision(
          document.id,
          data.period,
          data.team,
          data.status,
          data.finances,
          data.studies,
          data.machines,
          data.hr,
          data.production,
          data.sales,
          data.coeff,
          data.forecast,
          data.lastUpdatedBy || null
        );
        decisions.push(tmp_decision);
      });

      if (callback != null) {
        callback(decisions);
      }
    });
    return unsub;
  }

  static listenById(id, callback = null) {
    let documentRef = doc(db, collectionName, id);
    let unsub = onSnapshot(documentRef, (snapshot) => {
      let data = snapshot.data();
      let decision = new Decision(
        snapshot.id,
        data.period,
        data.team,
        data.status,
        data.finances,
        data.studies,
        data.machines,
        data.hr,
        data.production,
        data.sales,
        data.coeff,
        data.forecast,
        data.lastUpdatedBy || null
      );

      if (callback != null) {
        callback(decision);
      }
    });
    return unsub;
  }

  static async listenByPeriod(periodId, callback = null) {
    let collectionRef = collection(db, collectionName);
    let documentsQuery = query(collectionRef, where("period", "==", periodId));

    let unsub = onSnapshot(documentsQuery, (snapshot) => {
      let decisions = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_decision = new Decision(
          document.id,
          data.period,
          data.team,
          data.status,
          data.finances,
          data.studies,
          data.machines,
          data.hr,
          data.production,
          data.sales,
          data.coeff,
          data.forecast,
          data.lastUpdatedBy || null
        );
        decisions.push(tmp_decision);
      });

      if (callback != null) {
        callback(decisions);
      }
    });
    return unsub;
  }

  static async listenByTeam(teamId, callback = null) {
    let collectionRef = collection(db, collectionName);
    let documentsQuery = query(collectionRef, where("team", "==", teamId));

    let unsub = onSnapshot(documentsQuery, (snapshot) => {
      let decisions = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_decision = new Decision(
          document.id,
          data.period,
          data.team,
          data.status,
          data.finances,
          data.studies,
          data.machines,
          data.hr,
          data.production,
          data.sales,
          data.coeff,
          data.forecast,
          data.lastUpdatedBy || null
        );
        decisions.push(tmp_decision);
      });

      if (callback != null) {
        callback(decisions);
      }
    });
    return unsub;
  }

  static async listenByPeriodAndTeam(periodId, teamId, callback = null) {
    let collectionRef = collection(db, collectionName);
    let documentsQuery = query(
      collectionRef,
      where("period", "==", periodId),
      where("team", "==", teamId)
    );

    let unsub = onSnapshot(documentsQuery, (snapshot) => {
      let decisions = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_decision = new Decision(
          document.id,
          data.period,
          data.team,
          data.status,
          data.finances,
          data.studies,
          data.machines,
          data.hr,
          data.production,
          data.sales,
          data.coeff,
          data.forecast,
          data.lastUpdatedBy || null
        );
        decisions.push(tmp_decision);
      });

      if (callback != null) {
        callback(decisions);
      }
    });
    return unsub;
  }

  async save() {
    let collectionRef = collection(db, collectionName);
    if (this.id == null) {
      let response = await addDoc(collectionRef, {
        period: this.period,
        team: this.team,
        status: this.status,
        finances: this.finances,
        studies: this.studies,
        machines: this.machines,
        hr: this.hr,
        production: this.production,
        sales: this.sales,
        coeff: this.coeff,
        forecast: this.forecast,
        lastUpdatedBy: this.lastUpdatedBy || null,
      });
      this.id = response.id;
    } else {
      await updateDoc(doc(db, collectionName, this.id), {
        period: this.period,
        team: this.team,
        status: this.status,
        finances: this.finances,
        studies: this.studies,
        machines: this.machines,
        hr: this.hr,
        production: this.production,
        sales: this.sales,
        coeff: this.coeff,
        forecast: this.forecast,
        lastUpdatedBy: this.lastUpdatedBy || null,
      });
    }
    return this;
  }

  delete() {
    let documentRef = doc(db, collectionName, this.id);
    deleteDoc(documentRef);
  }
}

export default Decision;
