var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.state && _vm.session && _vm.decision)?_c('span',[(_vm.errorMessages.length > 0)?_c('v-alert',{attrs:{"border":"left","colored-border":"","color":"error","elevation":"2"}},[_c('h3',{staticClass:"ml-5 mb-3 error--text"},[_vm._v(" Cette page comporte des données manquantes ou éronées ")]),_c('ul',{staticClass:"ml-5"},_vm._l((_vm.errorMessages),function(message){return _c('li',{staticClass:"py-2 error--text",domProps:{"textContent":_vm._s(message)}})}),0)]):_vm._e(),_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Prévisions financières : ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.profile.role != 'User' ||
            _vm.session.decisionParameters.forecast.result
          ),expression:"\n            profile.role != 'User' ||\n            session.decisionParameters.forecast.result\n          "}],attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"Résultat net","suffix":"€","value":_vm.formatDecimal(_vm.decision.forecast.results),"error":_vm.errorRules.results},on:{"change":_vm.onDecisionUpdate,"input":(val) => {
                _vm.decision.forecast.results = _vm.parseDecimal(val);
                _vm.onInputChange();
              },"blur":function($event){return _vm.onBlurField($event, _vm.decision.forecast, 'results')},"keypress":_vm.onlyPositiveAndNegativeNumber,"paste":_vm.onPastePositiveAndNegativeNumber}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.profile.role != 'User' ||
            _vm.session.decisionParameters.forecast.treasury
          ),expression:"\n            profile.role != 'User' ||\n            session.decisionParameters.forecast.treasury\n          "}],attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"Trésorerie","suffix":"€","value":_vm.formatDecimal(_vm.decision.forecast.treasury),"error":_vm.errorRules.treasury},on:{"change":_vm.onDecisionUpdate,"input":(val) => {
                _vm.decision.forecast.treasury = _vm.parseDecimal(val);
                _vm.onInputChange();
              },"blur":function($event){return _vm.onBlurField($event, _vm.decision.forecast, 'treasury')},"keypress":_vm.onlyPositiveAndNegativeNumber,"paste":_vm.onPastePositiveAndNegativeNumber}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.profile.role != 'User' ||
            _vm.session.decisionParameters.forecast.turnover
          ),expression:"\n            profile.role != 'User' ||\n            session.decisionParameters.forecast.turnover\n          "}],attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"Chiffre d'affaires","suffix":"€","value":_vm.formatDecimal(_vm.decision.forecast.turnover),"error":_vm.errorRules.turnover},on:{"change":_vm.onDecisionUpdate,"input":(val) => {
                _vm.decision.forecast.turnover = _vm.parseDecimal(val);
                _vm.onInputChange();
              },"blur":function($event){return _vm.onBlurField($event, _vm.decision.forecast, 'turnover')},"keypress":_vm.onlyPositiveNumber,"paste":_vm.onPastePositiveNumber}})],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }