<template>
  <span v-if="profile.role != 'User' && state && session && decision">
    <v-alert
      border="left"
      colored-border
      color="error"
      elevation="2"
      v-if="errorMessages.length > 0"
    >
      <h3 class="ml-5 mb-3 error--text">
        Cette page comporte des données manquantes ou éronées
      </h3>
      <ul class="ml-5">
        <li
          class="py-2 error--text"
          v-for="message in errorMessages"
          v-text="message"
        ></li>
      </ul>
    </v-alert>
    <v-card class="mb-12" outlined>
      <v-card-title primary-title> Multiplicateurs : </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Communication"
              suffix="%"
              v-model="decision.coeff.communication"
              :error="errorRules.communication"
              @change="onDecisionUpdate"
              @keypress="onlyWholeNumber"
              @paste="onPasteWholeNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Marketing"
              suffix="%"
              v-model="decision.coeff.marketing"
              :error="errorRules.marketing"
              @change="onDecisionUpdate"
              @keypress="onlyWholeNumber"
              @paste="onPasteWholeNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Force de vente"
              suffix="%"
              v-model="decision.coeff.salesForce"
              :error="errorRules.salesForce"
              @change="onDecisionUpdate"
              @keypress="onlyWholeNumber"
              @paste="onPasteWholeNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Productivité"
              suffix="%"
              v-model="decision.coeff.productivity"
              :error="errorRules.productivity"
              @change="onDecisionUpdate"
              @keypress="onlyWholeNumber"
              @paste="onPasteWholeNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Coût de distribution"
              suffix="%"
              v-model="decision.coeff.distributionCost"
              :error="errorRules.distributionCost"
              @change="onDecisionUpdate"
              @keypress="onlyWholeNumber"
              @paste="onPasteWholeNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Prix des matières premières"
              suffix="%"
              v-model="decision.coeff.rawMaterialCost"
              :error="errorRules.rawMaterialCost"
              @change="onDecisionUpdate"
              @keypress="onlyWholeNumber"
              @paste="onPasteWholeNumber"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
export default {
  name: "DecisionCoeffs",
  props: ["profile", "user", "session", "state", "decision", "periods"],
  data() {
    return {
      errorMessages: [],
      errorRules: {
        communication: false,
        marketing: false,
        salesForce: false,
        productivity: false,
        distributionCost: false,
        rawMaterialCost: false,
      },
    };
  },
  mounted() {
    this.checkErrors();
  },
  methods: {
    onDecisionUpdate() {
      this.$emit("decisionUpdate");
    },
    onlyWholeNumber(event) {
      const char = event.key;
      // Allow only whole numbers
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    },
    onPasteWholeNumber(event) {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      let [integerPart] = paste.split(",");
      let cleanedVal = integerPart.replace(/\D/g, "");

      event.preventDefault();

      let input = event.target;

      // Get cursor position
      let start = input.selectionStart;
      let end = input.selectionEnd;

      // Get the current value
      let value = input.value;

      input.value =
        value.substring(0, start) + cleanedVal + value.substring(end);

      let newPos = start + cleanedVal.length;
      input.setSelectionRange(newPos, newPos);

      input.dispatchEvent(new Event("input"));
    },
    checkErrors() {
      let tmp_errorMessages = [];
      let tmp_rules = {
        communication: false,
        marketing: false,
        salesForce: false,
        productivity: false,
        distributionCost: false,
        rawMaterialCost: false,
      };
      let errorStatus = false;

      if (isNaN(parseFloat(this.decision.coeff.communication))) {
        tmp_errorMessages.push("Le coeff de communication doit être un nombre");
        errorStatus = true;
        tmp_rules.communication = true;
      } else if (parseFloat(this.decision.coeff.communication) < 0) {
        tmp_errorMessages.push(
          "Le coeff de communication doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.communication = true;
      }

      if (isNaN(parseFloat(this.decision.coeff.marketing))) {
        tmp_errorMessages.push("Le coeff de marketing doit être un nombre");
        errorStatus = true;
        tmp_rules.marketing = true;
      } else if (parseFloat(this.decision.coeff.marketing) < 0) {
        tmp_errorMessages.push(
          "Le coeff de marketing doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.marketing = true;
      }

      if (isNaN(parseFloat(this.decision.coeff.salesForce))) {
        tmp_errorMessages.push(
          "Le coeff de force de vente doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.salesForce = true;
      } else if (parseFloat(this.decision.coeff.salesForce) < 0) {
        tmp_errorMessages.push(
          "Le coeff de force de vente doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.salesForce = true;
      }

      if (isNaN(parseFloat(this.decision.coeff.productivity))) {
        tmp_errorMessages.push("Le coeff de productivité doit être un nombre");
        errorStatus = true;
        tmp_rules.productivity = true;
      } else if (parseFloat(this.decision.coeff.productivity) < 0) {
        tmp_errorMessages.push(
          "Le coeff de productivité doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.productivity = true;
      }

      if (isNaN(parseFloat(this.decision.coeff.distributionCost))) {
        tmp_errorMessages.push("Le coeff de distribution doit être un nombre");
        errorStatus = true;
        tmp_rules.distributionCost = true;
      } else if (parseFloat(this.decision.coeff.distributionCost) < 0) {
        tmp_errorMessages.push(
          "Le coeff de distribution doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.distributionCost = true;
      }

      if (isNaN(parseFloat(this.decision.coeff.rawMaterialCost))) {
        tmp_errorMessages.push(
          "Le coeff de prix des matières premières doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.rawMaterialCost = true;
      } else if (parseFloat(this.decision.coeff.rawMaterialCost) < 0) {
        tmp_errorMessages.push(
          "Le coeff de prix des matières premières doit être supérieur ou egal à 0"
        );
        errorStatus = true;
        tmp_rules.rawMaterialCost = true;
      }

      this.errorMessages = tmp_errorMessages;
      this.errorRules = tmp_rules;
      this.$forceUpdate();
      return errorStatus;
    },
    getErrorMessages() {
      return this.errorMessages;
    },
  },
};
</script>
